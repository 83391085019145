import {
	list as categoryList,
} from "@/api/productCategory.js";
import {
	list as productList,
} from "@/api/product.js";
import {
	add,
	detail,
	edit,
} from "@/api/productCoupon.js";
import formRule from "./formRule.js";
export default {
	name: 'save',
	data() {
		return {
			dialogShow: false, // 显示弹框
			productList: [], // 专区列表
			categoryList: [], // 分类列表
			height: '', // 弹框内容高度
			// 提交参数
			params: {
				explain: '',
				receiving_method: '1',
				use_range: '1',
				category_ids: '',
				product_ids: '',
				discount_method: '1',
				full: '',
				full_reduce: '',
				direct_reduce: '',
				discount: '',
				is_time_limit: '0',
				effective_time: '',
				is_number_limit: '0',
				limit_number: '',
				use_day: '',
				sort: '',
				status: '1',
			},
			categoryProps: {
				multiple: true,
				checkStrictly: true,
				emitPath: false,
				value: 'id',
				label: 'name',
				expandTrigger: 'hover'
			},
			category_ids: [],
			product_ids: [],
			surplus_number: 0,
			dialogType: 0, // 类型 0-添加 1-编辑
			formRules: {}, // 数据验证
			// 弹框信息
			dialog: [{
				title: '添加', // 标题
			}, {
				title: '编辑', // 标题
			}],
			is_submit: false,
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {
		this.formRules = formRule;
		this.height = window.innerHeight - 240;
	},
	methods: {
		/**
		 * @desc 开启弹框
		 * @param Number type 类型 0-添加 1-编辑
		 * @param Number id 当前分类id
		 */
		open(type, id = 0) {
			this.dialogType = type;
			// 获取专区列表
			this.getCategoryList();
			this.getProductList();
			if (type == 0) { // 添加
				// 显示弹框
				this.dialogShow = true;
			} else { // 编辑
				// 获取当前分类信息
				this.getDetail(id);
			}
		},
		/**
		 * @desc 获取分类列表
		 */
		getCategoryList() {
			categoryList().then(res => {
				this.categoryList = res.data.list;
			})
		},
		/**
		 * @desc 获取专区列表
		 */
		getProductList() {
			productList({
				product_type: 1
			}).then(res => {
				this.productList = res.data.list;
			})
		},
		/**
		 * @desc 获取详情数据
		 */
		getDetail(id) {
			this.$showLoading();
			// 查询详情
			detail({
				id: id,
			}).then((res) => {
				this.$hideLoading();
				this.params = res.data.row
				this.surplus_number = this.params.surplus_number
				delete this.params.surplus_number;
				this.category_ids = this.params.category_ids.split(',');
				this.category_ids = this.$trimSpace(this.category_ids);
				this.product_ids = this.params.product_ids.split(',');
				this.product_ids = this.$trimSpace(this.product_ids);
				this.category_ids.forEach((item, index) => {
					this.category_ids[index] = parseInt(item);
				})
				this.product_ids.forEach((item, index) => {
					this.product_ids[index] = parseInt(item);
				})
				// 显示弹框
				this.dialogShow = true;
			});
		},
		// 分类选择
		categoryChange(e) {
			console.log(e)
			// 获取分类
			this.params.category_ids = this.category_ids.toString(',');
			console.log(this.params.category_ids)
			// var category_ids = this.category_ids.toString(',');
			// // 转数组
			// var category_ids_arr = category_ids.split(',');
			// // 去除分类重复id
			// var category_ids_arr = this.$arrayUnique(category_ids_arr);
			// // 获取分类
			// this.params.category_ids = category_ids_arr.toString(',');
		},
		// 产品选择
		productChange(e) {
			// 获取产品选择
			this.params.product_ids = this.product_ids.toString(',');
		},
		/**
		 * @desc 关闭弹框
		 */
		close() {
			// 提交信息清空
			this.params = {
				explain: '',
				receiving_method: '1',
				use_range: '1',
				category_ids: '',
				product_ids: '',
				discount_method: '1',
				full: '',
				full_reduce: '',
				direct_reduce: '',
				discount: '',
				is_time_limit: '0',
				effective_time: '',
				is_number_limit: '0',
				limit_number: '',
				use_day: '',
				sort: '',
				status: '1',
			}
			this.category_ids = [];
			this.product_ids = [];
			this.surplus_number = 0;
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false;
		},
		// 文件选择
		fileConfirm(e) {
			this.params[e.name] = e.fileList.toString(',');
		},
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			console.log(this.params.effective_time)
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				this.is_submit = true;
				if (this.dialogType == 0) {
					var form = add(this.params);
				} else {
					var form = edit(this.params);
				}
				form.then((res) => {
					this.$successMsg(res.msg);
					this.dialogShow = false;
					this.is_submit = false;
					// 触发父组件列表方法
					this.$emit('saveComplete')
				}).catch(() => {
					this.is_submit = false;
				});
			})
		},
	}
}
