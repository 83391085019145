<template>
	<el-row :gutter="24">
		<el-form ref="formRef" :model="params" :inline="true">
			<el-form-item label="标题">
				<el-input placeholder="请输入标题" size="small" v-model="params.title" clearable>
				</el-input>
			</el-form-item>
			<el-form-item label="创建时间" class="hidden-xs-only">
				<el-date-picker v-model="params.create_time" size="small" type="datetimerange"
					:picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					value-format="yyyy-MM-dd hh:mm:ss">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="状态">
				<el-select v-model="params.status" size="small" filterable clearable placeholder="请选择状态"
					style="width:58%">
					<el-option v-for="item in statusList" :key="item.value" :value="item.value" :label="item.label">
					</el-option>
				</el-select>
				<el-button type="primary" size="small" icon="el-icon-search" @click="search" class="search-btn">
				</el-button>
			</el-form-item>
		</el-form>
	</el-row>
</template>

<script>
	export default {
		data() {
			return {
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				// 状态选择
				statusList: [{
					label: '启用',
					value: '1'
				}, {
					label: '禁用',
					value: '0'
				}],
				params: { // 列表请求数据
					name: '',
					create_time: '',
					status: '',
				},
			}
		},
		created() {},
		methods: {
			// 搜索
			search() {
				this.$emit('search', this.params)
			},
		}
	}
</script>

<style lang="scss">
</style>
