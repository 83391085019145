import service from "@/utils/service.js";

// 分类列表
export function categoryList(params = {}) {
	return service({
		method: 'get',
		url: 'attachmentCategory/index',
		params: params
	})
}

// 附件列表
export function attachmentList(params) {
	return service({
		method: 'get',
		url: 'attachment/index',
		params: params
	})
}

// 附件删除
export function upload(data, onUploadProgress) {
	return service({
		method: 'post',
		url: 'attachment/upload',
		data,
		onUploadProgress
	})
}


// 附件删除
export function del(data) {
	return service({
		method: 'post',
		url: 'attachment/del',
		data
	})
}


// 附件归类
export function classify(data) {
	return service({
		method: 'post',
		url: 'attachment/classify',
		data
	})
}
