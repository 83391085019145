import service from "@/utils/service.js";

// 产品分类列表
export function list(params) {
	return service({
		method: 'get',
		url: 'productCategory/index',
		params: params
	})
}

// 产品分类新增
export function add(data) {
	return service({
		method: 'post',
		url: 'productCategory/add',
		data
	})
}

// 产品分类详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'productCategory/detail',
		params: params
	})
}

// 产品分类修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'productCategory/edit',
		data
	})
}

// 产品分类状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'productCategory/multiChange',
		data
	})
}

// 产品分类删除
export function del(data) {
	return service({
		method: 'post',
		url: 'productCategory/del',
		data
	})
}
