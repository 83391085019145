<template>
	<div class="table-list">
		<!-- 列表信息 -->
		<el-table :data="list" stripe class="el-table-elem" v-loading="loading" element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)"
			tooltip-effect="dark">
			<el-table-column prop="id" label="ID" min-width="80" align="center" sortable>
			</el-table-column>
			<el-table-column prop="orderorder.order_number" label="订单号" align="center" min-width="200">
			</el-table-column>
			<el-table-column prop="user.nickname" label="下单用户" align="center" min-width="120">
				<template slot-scope="scope">
					<div class="list-main">
						<div class="list-li">{{scope.row.user.nickname}}</div>
						<div class="list-li label-red" v-if="scope.row.enterprise">
							{{scope.row.enterprise.enterprise_name}}
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="product_name" label="产品名称" align="center" min-width="200">
			</el-table-column>
			<el-table-column prop="cover_image" label="产品封面图" min-width="100" align="center">
				<template slot-scope="scope">
					<el-image v-show="scope.row.product_image" :src="scope.row.product_image" fit="contain"
						class="table-image">
					</el-image>
				</template>
			</el-table-column>
			<el-table-column prop="product_spec_name" label="规格名称" align="center" min-width="120">
			</el-table-column>
			<el-table-column prop="product_number" label="数量" align="center" min-width="80">
			</el-table-column>
			<el-table-column prop="product_unit" label="单位" align="center" min-width="80">
			</el-table-column>
			<el-table-column prop="pay_money" label="单价金额/积分" align="center" min-width="120">
				<template slot-scope="scope">
					<div v-if="scope.row.product_type != 4">￥{{scope.row.product_price}}</div>
					<div v-else>积分{{scope.row.product_integral}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="pay_money" label="总价金额/积分" align="center" min-width="120">
				<template slot-scope="scope">
					<div v-if="scope.row.product_type != 4">￥{{scope.row.total_money}}</div>
					<div v-else>积分{{scope.row.total_integral}}</div>
				</template>
			</el-table-column>
<!-- 			<el-table-column prop="coupon_reduce" label="优惠券优惠金额" align="center" min-width="120">
			</el-table-column>
			<el-table-column prop="number_reduce" label="达量优惠金额" align="center" min-width="120">
			</el-table-column> -->
			<el-table-column prop="status_text" label="状态" align="center" min-width="120">
				<template slot-scope="scope">
					<el-tag type="success" size="mini" v-if="scope.row.orderorder">{{scope.row.orderorder.status_text}}</el-tag>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<pagination ref="pagination" :page="page.page" :pageSize="page.pageSize" :total="total"
			@pagination="pagination" />
	</div>
</template>

<script>
	import {
		multiChange,
	} from "@/api/order.js";
	export default {
		name: "table-list",
		data() {
			return {
				selectIds: [], // 已选择的id
				page: {
					page: 1,
					pageSize: 10,
				},
			}
		},
		// 定义传入的参数
		props: {
			loading: {
				required: true,
				type: Boolean,
				default: false,
			},
			list: {
				required: true,
				type: Array,
			},
			total: {
				required: true,
				type: Number,
			},
		},
		mounted() {},
		methods: {
			/**
			 * @desc 状态切换
			 */
			multiChange(name, rows) {
				var params = {
					id: rows.id,
					[name]: rows[name]
				}
				multiChange(params).then((res) => {
					this.$successMsg(res.msg);
				}).catch((ex) => {
					// 切换失败，重置信息
					rows.status = rows.status == 1 ? "0" : "1";
				});
			},
			/**
			 * @desc 分类
			 */
			pagination(e) {
				this.$emit('pagination', e)
			},
			/**
			 * @desc 重置刷新列表分页数据
			 */
			refreshPagination() {
				this.$refs.pagination.currentPage = 1;
			},
		}
	}
</script>

<style lang="scss">
	.table-tag {
		margin: 5px 5px 0 0;
	}
</style>