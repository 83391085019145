export default {
	name: [{
		required: true,
		message: '请输入权限名称',
		trigger: 'blur'
	}, ],
	controller: [{
		required: true,
		message: '请输入控制器名称',
		trigger: 'blur'
	}, ],
	action: [{
		required: true,
		message: '请输入方法名称',
		trigger: 'blur'
	}, ],
	is_menu: [{
		required: true,
		message: '请选择是否为菜单',
		trigger: 'blur'
	}, ],
	status: [{
		required: true,
		message: '请选择状态',
		trigger: 'blur'
	}, ],
}
