import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
// 引入vue-router --------------------
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import router from './router'

// 全局组件注册
import install from '@/components/install.js'
Vue.use(install);

//  引入Echarts
import * as echarts from 'echarts'
console.log(echarts)
Vue.prototype.$echarts = echarts

// 缓存操作
import {
	setStorage,
	getStorage,
	removeStorage,
} from '@/utils/storage.js'
Vue.prototype.$setStorage = setStorage
Vue.prototype.$getStorage = getStorage
Vue.prototype.$removeStorage = removeStorage

// 气泡提示框
import {
	successMsg,
	errorMsg,
	massageConfirm,
} from '@/utils/message.js'
Vue.prototype.$successMsg = successMsg
Vue.prototype.$errorMsg = errorMsg
Vue.prototype.$massageConfirm = massageConfirm

// 加载提示框
import {
	showLoading,
	hideLoading,
} from '@/utils/loading.js'
Vue.prototype.$showLoading = showLoading
Vue.prototype.$hideLoading = hideLoading

// 引入store
import store from '@/store'
Vue.prototype.$store = store

// 引用公共文件
import {
	deepClone,
	isMobilePhone,
	trimSpace,
	arrayUnique,
	wangEditorConfig,
	getListHeight,
	getSystemConfig,
	recurrenceDisabled,
} from '@/utils/common.js'
Vue.prototype.$deepClone = deepClone
Vue.prototype.$isMobilePhone = isMobilePhone
Vue.prototype.$trimSpace = trimSpace
Vue.prototype.$arrayUnique = arrayUnique
Vue.prototype.$wangEditorConfig = wangEditorConfig
Vue.prototype.$getListHeight = getListHeight
Vue.prototype.$getSystemConfig = getSystemConfig
Vue.prototype.$recurrenceDisabled = recurrenceDisabled


Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
