<template>
	<div class="save-data">
		<el-dialog :title="dialog[dialogType].title" :visible.sync="dialogShow"
			:fullscreen="$isMobilePhone()?true:false" :width="$isMobilePhone()?'100%':'70%'"
			:top="$isMobilePhone()?'':'2vh'" @close="close" :close-on-click-modal="false" class="data-dialog">
			<el-form ref="formRef" :model="params" :rules="formRules" :label-width="$isMobilePhone()?'':'120px'">
				<div class="form-main" :style="'height:'+height+'px'">
					<div class="form-content">
						<el-form-item label="专区">
							<el-select v-model="params.region_id" size="small" filterable clearable
								placeholder="请选择所属专区" style="width:100%">
								<el-option v-for="item in regionList" :key="item.id" :value="item.id"
									:label="item.name">
								</el-option>
							</el-select>
							<div class="form-tip">提示：所属专区，将在不同专区展示</div>
						</el-form-item>
						<el-form-item label="标题" prop="title">
							<el-input v-model="params.title" size="small" maxlength="30" placeholder="请输入标题">
							</el-input>
							<div class="form-tip">提示：请勿超过30位</div>
						</el-form-item>
						<el-form-item label="封面图" prop="cover_image">
							<!-- 选择文件 -->
							<selectAttachmentBtn ref="coverImageSelectAttachmentBtn" fileType="image"
								paramName="cover_image" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过300KB</div>
						</el-form-item>
						<el-form-item label="活动时间" prop="start_time">
							<el-date-picker v-model="activity_time" size="small" type="datetimerange"
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
								format="yyyy-MM-dd hh:mm" value-format="yyyy-MM-dd hh:mm" @change="timeChange"
								:style="{width:'50%'}">
							</el-date-picker>
							<div class="form-tip">提示：请勿超过30位</div>
						</el-form-item>
						<el-form-item label="活动产品" prop="product">
							<productSelect ref="productSelect" @productSelectOperation="productSelectOperation">
							</productSelect>
						</el-form-item>
						<el-form-item label="排序">
							<el-input v-model="params.sort" type="number" min="0" size="small" placeholder="请输入排序">
							</el-input>
							<div class="form-tip">提示：从大到小排序</div>
						</el-form-item>
						<el-form-item label="状态" prop="status">
							<el-radio v-model="params.status" label="1">上架</el-radio>
							<el-radio v-model="params.status" label="0">下架</el-radio>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="close">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">保 存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		selectPage as regionSelectPage,
	} from "@/api/productRegion.js";
	import {
		add,
		detail,
		edit,
	} from "@/api/activity.js";
	import rule from "./rule.js";
	import productSelect from '../productSelect/productSelect.vue';
	export default {
		name: 'save',
		components: {
			productSelect
		},
		data() {
			return {
				activeName: 'basics',
				dialogShow: false, // 显示弹框
				regionList: [], // 专区列表
				height: '',
				// 弹框标题
				activity_time: [],
				// 提交参数
				params: {
					region_id: '',
					title: '',
					cover_image: '',
					start_time: '',
					end_time: '',
					product: [],
					sort: '0',
					status: '1',
				},
				// 类型 0-添加 1-编辑
				dialogType: 0,
				// 数据验证
				formRules: {},
				// 弹框信息
				dialog: [{
					title: '添加', // 标题
				}, {
					title: '编辑', // 标题
				}],
				is_submit: false,
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {
			this.formRules = rule;
			this.height = window.innerHeight - 240;
		},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param Number type 类型 0-添加 1-编辑
			 * @param Number id 当前活动id
			 */
			open(type, id = 0) {
				this.dialogType = type;
				this.getRegionList();
				if (type == 0) { // 添加
					// 显示弹框
					this.dialogShow = true;
					setTimeout(() => {
						this.$refs.productSelect.getData(this.params.product);
					}, 0)
				} else { // 编辑
					// 获取当前活动信息
					this.getDetail(id);
				}
			},
			/**
			 * @desc 获取专区列表
			 */
			getRegionList(e) {
				regionSelectPage().then(res => {
					this.regionList = res.data.list;
				})
			},
			// 规格操作
			productSelectOperation(e) {
				this.params.product = e;
			},
			/**
			 * @desc 获取详情数据
			 */
			getDetail(id) {
				this.$showLoading();
				// 查询详情
				detail({
					id: id,
				}).then((res) => {
					this.$hideLoading();
					this.params = res.data.row
					this.activity_time = [this.params.start_time, this.params.end_time]
					// 显示弹框
					this.dialogShow = true;
					setTimeout(() => {
						this.$refs.coverImageSelectAttachmentBtn.initData({
							fileList: [this.params.cover_image],
							baseFileList: [this.params.base_cover_image],
						})
						this.$refs.productSelect.getData(this.params.product);
					}, 0)
				}).catch(() => {
					this.$hideLoading();
				});;
			},
			/**
			 * @desc 关闭弹框
			 */
			close() {
				// 提交信息清空
				this.params = {
					region_id: '',
					title: '',
					cover_image: '',
					start_time: '',
					end_time: '',
					product: [],
					sort: '0',
					status: '1',
				}
				this.$refs.coverImageSelectAttachmentBtn.initData({
					fileList: [],
					baseFileList: [],
				})
				this.activity_time = []
				this.$refs.productSelect.clear();
				// 去除表单验证、重置数据
				this.$refs.formRef.resetFields()
				// 关闭弹框
				this.dialogShow = false;
			},
			// 时间选择
			timeChange(e) {
				this.params.start_time = e[0]
				this.params.end_time = e[1]
			},
			// 文件选择
			fileConfirm(e) {
				this.params[e.name] = e.fileList.toString(',');
			},
			/**
			 * @desc 提交信息
			 */
			submit() {
				var that = this;
				// 验证表单
				that.$refs.formRef.validate(valid => {
					if (!valid) {
						return false;
					}
					if (this.dialogType == 0) {
						var form = add(this.params);
					} else {
						var form = edit(this.params);
					}
					this.is_submit = true
					form.then((res) => {
						this.$successMsg(res.msg);
						this.is_submit = false
						this.dialogShow = false;
						// 触发父组件列表方法
						this.$emit('saveComplete')
					}).catch(() => {
						this.is_submit = false
					});
				})
			},
		}
	}
</script>
<style lang="scss">
	.form-main {
		position: relative;
		overflow: hidden;

		.form-content {
			position: absolute;
			left: 0;
			top: 0;
			right: -17px;
			bottom: 0;
			overflow-x: hidden;
			overflow-y: scroll;
		}
	}
</style>
