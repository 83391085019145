import service from "@/utils/service.js";

// 订单欠款列表
export function list(params) {
	return service({
		method: 'get',
		url: 'orderDebt/index',
		params: params
	})
}


// 订单欠款详情
export function getExamine(params) {
	return service({
		method: 'get',
		url: 'orderDebt/examine',
		params: params
	})
}


// 订单欠款删除
export function examine(data) {
	return service({
		method: 'post',
		url: 'orderDebt/examine',
		data
	})
}


// 订单欠款详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'orderDebt/detail',
		params: params
	})
}


// 订单欠款删除
export function del(data) {
	return service({
		method: 'post',
		url: 'orderDebt/del',
		data
	})
}
