import service from "@/utils/service.js";

// 近效区列表
export function list(params) {
	return service({
		method: 'get',
		url: 'nearEffectArea/index',
		params: params
	})
}

// 近效区列表
export function selectPage(params) {
	return service({
		method: 'get',
		url: 'nearEffectArea/selectPage',
		params: params
	})
}

// 近效区新增
export function add(data) {
	return service({
		method: 'post',
		url: 'nearEffectArea/add',
		data
	})
}

// 近效区详情
export function detail(params) {
	return service({
		method: 'get',
		url: 'nearEffectArea/edit',
		params: params
	})
}

// 近效区修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'nearEffectArea/edit',
		data
	})
}

// 近效区状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'nearEffectArea/multiChange',
		data
	})
}

// 近效区删除
export function del(data) {
	return service({
		method: 'post',
		url: 'nearEffectArea/del',
		data
	})
}
