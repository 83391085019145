import service from "@/utils/service.js";

// 积分产品列表
export function list(params) {
	return service({
		method: 'get',
		url: 'integralProduct/index',
		params: params
	})
}

// 积分产品新增
export function add(data) {
	return service({
		method: 'post',
		url: 'integralProduct/add',
		data
	})
}

// 积分产品详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'integralProduct/detail',
		params: params
	})
}

// 积分产品修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'integralProduct/edit',
		data
	})
}

// 积分产品状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'integralProduct/multiChange',
		data
	})
}

// 积分产品删除
export function del(data) {
	return service({
		method: 'post',
		url: 'integralProduct/del',
		data
	})
}
