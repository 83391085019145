import dateTimeProps from "@/components/list/search/date-time-props.js";
export default {
	search: true, // 搜索按钮显示
	reset: true, // 重置按钮显示
	formList: [{
		label: '名称',
		type: 'input', // 表单类型
		inputType: 'text', // input类型
		paramsName: "name", // 参数名
		placeholder: '请输入名称', // 框背景字
	}, {
		label: '创建时间',
		type: 'date-picker', // 表单类型
		inputType: 'text', // input类型
		paramsName: "create_time", // 参数名
		timeProps: dateTimeProps, // 时间选择器配置信息
	}, {
		label: '状态',
		type: 'select', // 表单类型
		paramsName: "status", // 参数名
		placeholder: '请选择状态', // 背景字
		selectList: [{ // 选择列表
			label: '启用',
			value: '1'
		}, {
			label: '禁用',
			value: '0'
		}],
	}],
}
