<template>
	<div class="save-data">
		<el-dialog :title="dialog[dialogType].title" :visible.sync="dialogShow"
			:fullscreen="$isMobilePhone()?true:false" :width="$isMobilePhone()?'100%':'50%'"
			:top="$isMobilePhone()?'':'2vh'" @close="close" class="data-dialog">
			<el-form ref="formRef" :model="params" :rules="formRules" :label-width="$isMobilePhone()?'':'120px'">
				<el-form-item label="上级">
					<el-cascader placeholder="请选择上级" size="small" :options="parentList" v-model="params.path"
						:props="{checkStrictly: true,value: 'id',label: 'name',expandTrigger: 'hover'}" filterable
						:style="{width: '100%'}"></el-cascader>
					<div class="form-tip">提示：当前所属上级，可多选</div>
				</el-form-item>
				<el-form-item label="名称" prop="name">
					<el-input v-model="params.name" size="small" placeholder="请输入名称"></el-input>
					<div class="form-tip">提示：请勿超过10位</div>
				</el-form-item>
				<el-form-item label="排序" :prop="dialogType==0?'sort':''">
					<el-input type="number" v-model="params.sort" size="small" min="0" placeholder="请输入排序"></el-input>
					<div class="form-tip">提示：长度6~16位</div>
				</el-form-item>
				<el-form-item label="状态" prop="status">
					<el-radio v-model="params.status" label="1">启用</el-radio>
					<el-radio v-model="params.status" label="0">禁用</el-radio>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="close">取 消</el-button>
				<el-button type="primary" size="small" :loading="!isSubmit" @click="submit">保 存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list as parentList,
		add,
		edit,
	} from "@/api/attachmentCategory.js";
	import formRules from './formRules.js';
	export default {
		name: 'save-data',
		data() {
			return {
				// 显示弹框
				dialogShow: false,
				// 提交参数
				params: {
					path: [],
					name: '',
					sort: '',
					status: '1',
				},
				// 上级分类
				parentList: [],
				// 类型 0-添加 1-编辑
				dialogType: 0,
				// 数据验证
				formRules: {},
				// 弹框信息
				dialog: [{
					title: '添加分类', // 标题
				}, {
					title: '编辑分类', // 标题
				}],
				isSubmit: true, // 是否提交 true-未提交 false-已提交
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {
			this.formRules = formRules;
		},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param Number type 类型 0-添加 1-编辑
			 * @param Number id 当前分类id
			 */
			open(type, e) {
				this.dialogType = type;
				// 获取分类列表
				this.getParentList();
				if (type == 1) { // 编辑
					this.params = e.row
					this.params.path.forEach((item, index) => {
						this.params.path[index] = Number(item);
					})
				}
				// 显示弹框
				this.dialogShow = true;
			},
			/**
			 * @desc 获取分类列表
			 */
			getParentList() {
				parentList().then(res => {
					this.parentList = res.data.list
					this.recurrence_disabled(this.parentList, this.params.id);
				})
			},

			/**
			 * 递归禁用
			 * @param data 数据列表
			 * @param current_id 当前id
			 * @param parent_disabled 父级是否禁用
			 */
			recurrence_disabled(data, current_id = 0, parent_disabled = false) {
				let count = 0;
				for (let i in data) {
					data[i].index = i;
					data[i].disabled = false;
					if (parent_disabled || data[i].id == current_id) {
						data[i].disabled = true;
					}
					if (data[i].children) {
						count++;
						this.recurrence_disabled(data[i].children, current_id, data[i].disabled);
					}
				}
			},
			/**
			 * @desc 关闭弹框
			 */
			close() {
				// 提交信息清空
				this.params = {
					path: [],
					name: '',
					sort: '',
					status: '1',
				}
				// 去除表单验证、重置数据
				this.$refs.formRef.resetFields()
				// 关闭弹框
				this.dialogShow = false;
			},
			/**
			 * @desc 提交信息
			 */
			submit() {
				var that = this;
				// 验证表单
				that.$refs.formRef.validate(valid => {
					if (!valid) {
						return false;
					}
					if (!that.isSubmit) {
						return false;
					}
					that.isSubmit = false;
					if (that.dialogType == 0) {
						var form = add(that.params);
					} else {
						var form = edit(that.params);
					}
					form.then((res) => {
						that.isSubmit = true;
						that.$successMsg(res.msg);
						that.dialogShow = false;
						that.$emit('submit')
					}).catch(() => {
						that.isSubmit = true;
					});
				})
			},
		}
	}
</script>
