<template>
	<div class="">
		<el-dialog title="用户赠送金额详情" :visible.sync="dialogShow" :fullscreen="$isMobilePhone()?true:false"
			:width="$isMobilePhone()?'100%':'50%'" :top="$isMobilePhone()?'':'2vh'" @close="dialogShow = false">
			<div class="detail-main">
				<div class="detail-content">
					<div class="detail-title">赠送金额信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">用户昵称：</div>
							<div class="li-data">{{row.user.nickname}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">类型：</div>
							<div class="li-data">
								<el-tag size="mini" :type="row.type==1?'':'success'">{{row.type_text}}
								</el-tag>
							</div>
						</div>
						<div class="li-item u-flex" v-if="row.type == 1">
							<div class="li-label">收入类型：</div>
							<div class="li-data">
								<el-tag size="mini">{{row.get_type_text}}</el-tag>
							</div>
						</div>
						<div class="li-item u-flex" v-if="(row.type == 1 && row.get_type == 5) || row.type == 2">
							<div class="li-label">订单号：</div>
							<div class="li-data">{{row.order_number}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">金额：</div>
							<div class="li-data">￥{{row.money}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">最终金额：</div>
							<div class="li-data">￥{{row.final_money}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">创建时间：</div>
							<div class="li-data">{{row.create_time}}</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'detail',
		data() {
			return {
				// 显示弹框
				dialogShow: false,
				row: {
					user: {
						nickname: '',
					},
				},
				srcList: [],
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param e 订单信息
			 */
			open(e) {
				this.row = e.row
				this.srcList = [this.row.idcard_pt, this.row.idcard_ne]
				// 显示弹框
				this.dialogShow = true;
			},
		}
	}
</script>

<style lang="scss">
	.detail-main {
		width: 96%;
		margin: 0 auto;
		font-size: 12px;

		.detail-content {
			padding-top: 20px;
			width: 100%;

			.detail-title {
				font-size: 13px;
				font-weight: bold;
				padding-bottom: 10px;
			}

			.detail-li {

				.li-item {
					width: 50%;
					padding: 10px 0;

					.li-label {
						width: 100px;
						text-align: right;
					}

					.li-data {
						padding-left: 10px;
					}
				}
			}
		}
	}
</style>
