<template>
	<div class="save-data">
		<el-dialog title="附件归类" :visible.sync="show" :fullscreen="$isMobilePhone() ? true : false"
			:width="$isMobilePhone() ? '100%' : '50%'" :top="$isMobilePhone() ? '' : '2vh'" @close="close"
			class="data-dialog">
			<el-form ref="formRef" :model="params" :rules="formRules" :label-width="$isMobilePhone() ? '' : '120px'">
				<el-form-item label="分类" prop="category_id">
					<el-cascader placeholder="请选择分类" size="mini" :options="categoryList" v-model="params.category_id"
						:props="{checkStrictly: true,value: 'id',label: 'name',emitPath:false,expandTrigger: 'hover'}"
						filterable :style="{width: '100%'}"></el-cascader>
					<div class="form-tip">提示：当前所属分类，可多选</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="close">取 消</el-button>
				<el-button type="primary" size="small" :loading="!isSubmit" @click="submit">保 存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list as categoryList,
	} from "@/api/attachmentCategory.js";
	import {
		classify,
	} from "@/api/attachment.js";
	export default {
		name: 'save-data',
		data() {
			return {
				// 提交参数
				params: {
					ids: [],
					category_id: '',
				},
				// 分类列表
				categoryList: [],
				// 类型 0-添加 1-编辑
				dialogType: 0,
				// 数据验证
				formRules: {
					category_id: [{
						required: true,
						message: '请选择分类',
						trigger: 'blur'
					}, ],
				},
				isSubmit: true, // 是否提交 true-未提交 false-已提交
				show: false,
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param Number ids 当前附件ids
			 */
			open(e) {
				this.params.ids = e;
				this.getCategoryList();
				// 显示弹框
				this.show = true;
			},
			/**
			 * @desc 获取分类列表
			 */
			getCategoryList() {
				categoryList().then(res => {
					this.categoryList = res.data.list
				})
			},
			close() {
				// 提交信息清空
				this.params = {
					ids: [],
					category_id: '',
				}
				// 去除表单验证、重置数据
				this.$refs.formRef.resetFields()
				// 关闭弹框
				this.show = false;
			},
			// 文件选择
			fileConfirm(e) {
				this.params[e.name] = e.fileList.toString(',');
			},
			/**
			 * @desc 关闭弹框
			 */
			/**
			 * @desc 提交信息
			 */
			submit() {
				var that = this;
				// 验证表单
				that.$refs.formRef.validate(valid => {
					if (!valid) {
						return false;
					}
					if (!that.isSubmit) {
						return false;
					}
					that.isSubmit = false;
					classify(that.params).then((res) => {
						that.isSubmit = true;
						that.$successMsg(res.msg);
						that.show = false;
						that.$emit('submit')
					}).catch(() => {
						that.isSubmit = true;
					});
				})
			},
		}
	}
</script>

<style lang="scss">
	.el-textarea .el-input__count {
		height: 12px;
		line-height: 12px;
	}
</style>
