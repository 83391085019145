import service from "@/utils/service.js";

// 账户列表
export function getMenuList(params = {}) {
  return service({
    method: 'get',
    url: 'index/menuList',
    params: params,
  })
}

// 账户列表
export function getMonthUserStatistics(params = {}) {
  return service({
    method: 'get',
    url: 'index/getMonthUserStatistics',
    params: params,
  })
}
