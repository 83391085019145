<template>
	<div class="table-list">
		<!-- 列表信息 -->
		<el-table :data="list" stripe class="el-table-elem" v-loading="loading" element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)"
			tooltip-effect="dark" @selection-change="selectionChange">
			<el-table-column type="selection" min-width="180"></el-table-column>
			<el-table-column prop="id" label="ID" min-width="80" align="center" sortable>
			</el-table-column>
			<el-table-column prop="refund_number" label="退款号" align="center" min-width="200">
			</el-table-column>
			<el-table-column prop="user.nickname" label="用户昵称" align="center" min-width="120">
			</el-table-column>
			<el-table-column prop="order_type_text" label="订单类型" align="center" min-width="120">
			</el-table-column>
			<el-table-column prop="order_pay_type_text" label="支付类型" align="center" min-width="80">
			</el-table-column>
			<el-table-column prop="pay_money" label="已支付金额/积分" align="center" min-width="200">
				<template slot-scope="scope">
					<div v-if="scope.row.order_type != 4">￥{{scope.row.order_pay_money}}</div>
					<div v-else>积分{{scope.row.order_pay_integral}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="status_text" label="状态" align="center" min-width="120">
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间" align="center" min-width="180">
			</el-table-column>
			<el-table-column label="操作" min-width="220" fixed="right">
				<template slot-scope="scope">
					<el-button type="success" size="mini" @click="$emit('examine',scope.row)"
						v-if="scope.row.status == 0">审核
					</el-button>
					<el-button type="success" size="mini" @click="$emit('receipt',scope.row)"
						v-if="scope.row.status == 3">确认收货
					</el-button>
					<el-button type="success" size="mini" @click="$emit('refund',scope.row)"
						v-if="(scope.row.is_return_goods == 0 && scope.row.status == 1) || scope.row.status == 4">确认退款
					</el-button>
					<el-button type="primary" size="mini" @click="$emit('detail',scope.row)">详情</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="$emit('delete',scope.row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<pagination ref="pagination" :page="page.page" :pageSize="page.pageSize" :total="total"
			@pagination="pagination" />
	</div>
</template>

<script>
	import {
		multiChange,
	} from "@/api/admin.js";
	export default {
		name: "table-list",
		data() {
			return {
				selectIds: [], // 已选择的id
				page: {
					page: 1,
					pageSize: 10,
				},
			}
		},
		// 定义传入的参数
		props: {
			loading: {
				required: true,
				type: Boolean,
				default: false,
			},
			list: {
				required: true,
				type: Array,
			},
			total: {
				required: true,
				type: Number,
			},
		},
		mounted() {},
		methods: {
			/**
			 * @desc 列表选择
			 */
			selectionChange(e) {
				this.selectIds = [];
				if (e.length != 0) {
					// 获取选中的id
					e.forEach((item, index) => {
						this.selectIds.push(item.id)
					})
				}
				this.$emit('selectionChange', this.selectIds)
			},
			/**
			 * @desc 编辑获取数据
			 */
			examine(e) {
				this.$emit('examine', e)
			},
			/**
			 * @desc 详情获取数据
			 */
			detail(e) {
				this.$emit('detail', e)
			},
			/**
			 * @desc 确定收货数据
			 */
			receipt(e) {
				this.$emit('receipt', e)
			},
			refund(e) {
				this.$emit('refund', e)
			},
			/**
			 * @desc 删除数据
			 */
			del(e) {
				this.$emit('delete', e)
			},
			/**
			 * @desc 状态切换
			 */
			multiChange(name, rows) {
				var params = {
					id: rows.id,
					[name]: rows[name]
				}
				multiChange(params).then((res) => {
					this.$successMsg(res.msg);
				}).catch((ex) => {
					// 切换失败，重置信息
					rows.status = rows.status == 1 ? "0" : "1";
				});
			},
			/**
			 * @desc 分类
			 */
			pagination(e) {
				this.$emit('pagination', e)
			},
			/**
			 * @desc 重置刷新列表分页数据
			 */
			refreshPagination() {
				this.$refs.pagination.currentPage = 1;
			},
		}
	}
</script>

<style lang="scss">
	.table-tag {
		margin: 5px 5px 0 0;
	}
</style>
