import {
	add,
	detail,
	edit,
} from "@/api/user.js";
export default {
	name: 'save-data',
	data() {
		return {
			// 显示弹框
			dialogShow: false,
			// 提交参数
			params: {
				id: 0,
				nickname: '',
				avatar: '',
				mobile: '',
				password: '',
				regPassword: '',
				money: '',
				is_debt_payment: '0',
				status: '1',
			},
			// 类型 0-编辑
			dialogType: 0,
			height: '',
			// 数据验证
			formRules: {
				nickname: [{
						required: true,
						message: '请输入用户名称',
						trigger: 'blur'
					},
					{
						max: 11,
						message: "长度请勿超过11个字符",
						trigger: "blur",
					},
				],
				is_debt_payment: [{
					required: true,
					message: '请选择欠款支付权限',
					trigger: 'blur'
				}, ],
				status: [{
					required: true,
					message: '请选择状态',
					trigger: 'blur'
				}, ],
			},
			// 弹框信息
			dialog: [{
				title: '编辑用户', // 标题
			}, ],
			userInfo: {},
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {
		this.height = window.innerHeight - 240;
	},
	methods: {
		/**
		 * @desc 开启弹框
		 * @param Number id 当前厂商id
		 */
		open(e) {
			console.log(e)
			this.userInfo = e.row
			this.params.nickname = e.row.nickname
			this.params.mobile = e.row.mobile
			this.params.avatar = e.row.avatar
			this.params.status = e.row.status
			this.params.is_debt_payment = e.row.is_debt_payment
			this.params.id = e.row.id
			if (this.userInfo.avatar) {
				setTimeout(() => {
					this.$refs.coverImageSelectAttachmentBtn.initData({
						fileList: [this.userInfo.avatar],
						baseFileList: [this.userInfo.base_avatar],
					})
				}, 0)
			}
			// 显示弹框
			this.dialogShow = true;
		},
		close() {
			// 提交信息清空
			this.params = {
				id: 0,
				nickname: '',
				avatar: '',
				mobile: '',
				password: '',
				regPassword: '',
				money: '',
				is_debt_payment: '1',
				status: '1',
			}
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false
		},
		// 文件选择
		fileConfirm(e) {
			this.params[e.name] = e.fileList.toString(',');
		},
		// 规格文件选择
		specFileConfirm(e) {
			this.params.spec[e.name].spec_image = e.fileList.toString(',');
			console.log(this.params.spec)
		},
		/**
		 * @desc 关闭弹框
		 */
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				var form = edit(this.params);
				form.then((res) => {
					if (res.code == 200) {
						this.$successMsg(res.msg);
						this.dialogShow = false;
						// 触发父组件列表方法
						this.$emit('saveComplete')
					} else {
						this.$errorMsg(res.msg);
					}
				});
			})
		},
		onCreated(editor) {
			this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
		},
	}
}