export default {
	name: [{
			required: true,
			message: '请输入角色名称',
			trigger: 'blur'
		},
		{
			min: 2,
			message: "长度请勿小于2个字符",
			trigger: "blur",
		},
		{
			max: 10,
			message: "长度请勿超过10个字符",
			trigger: "blur",
		},
	],
	power_ids: [{
		required: true,
		message: '请选择权限',
		trigger: 'change'
	}, ],
	status: [{
		required: true,
		message: '请选择状态',
		trigger: 'blur'
	}, ],
}
