import dateTimeProps from "@/components/list/search/date-time-props.js";
export default {
	search: true, // 搜索按钮显示
	reset: true, // 重置按钮显示
	formList: [{
		label: '订单号',
		type: 'input', // 表单类型
		inputType: 'text', // input类型
		paramsName: "order_number", // 参数名
		placeholder: '请输入订单号', // 框背景字
	}, {
		label: '产品名称',
		type: 'input', // 表单类型
		inputType: 'text', // input类型
		paramsName: "product_name", // 参数名
		placeholder: '请输入产品名称', // 框背景字
	}, {
		label: '企业',
		type: 'input', // 表单类型
		inputType: 'text', // input类型
		paramsName: "enterprise_name", // 参数名
		placeholder: '请输入企业名称', // 框背景字
	}, {
		label: '用户',
		type: 'input', // 表单类型
		inputType: 'text', // input类型
		paramsName: "nickname", // 参数名
		placeholder: '请输入用户昵称', // 框背景字
	}, {
		label: '状态',
		type: 'select', // 表单类型
		paramsName: "status", // 参数名
		placeholder: '请选择状态', // 背景字
		selectList: [{ // 选择列表
			label: '待支付',
			value: '0'
		}, {
			label: '待发货',
			value: '1'
		}, {
			label: '待收货',
			value: '2'
		}, {
			label: '待完成',
			value: '3'
		}, {
			label: '已完成',
			value: '4'
		}, {
			label: '已退款',
			value: '5'
		}, {
			label: '已取消',
			value: '6'
		}, ],
	}],
}