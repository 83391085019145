<template>
	<div class="table-list">
		<!-- 列表信息 -->
		<el-table :data="list" stripe class="el-table-elem" v-loading="loading" element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)"
			tooltip-effect="dark">
			<el-table-column prop="id" label="ID" min-width="80" sortable align="center">
			</el-table-column>
			<el-table-column prop="type.name" label="企业类型" min-width="150" align="center">
			</el-table-column>
			<el-table-column prop="enterprise_name" label="企业名称" min-width="200">
			</el-table-column>
			<el-table-column prop="user.nickname" label="用户昵称" min-width="120" align="center">
			</el-table-column>
			<el-table-column prop="province" label="省份" min-width="120" align="center">
			</el-table-column>
			<el-table-column prop="create_time" label="认证时间" min-width="120" align="center">
			</el-table-column>
			<el-table-column label="操作" min-width="210" fixed="right" align="center">
				<template slot-scope="scope">
					<el-button type="primary" size="mini" @click="$emit('detail', scope.row)">详情
					</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="$emit('delete', scope.row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<pagination ref="pagination" :page="page.page" :pageSize="page.pageSize" :total="total"
			@pagination="pagination" />
	</div>
</template>

<script>
	export default {
		name: "table-list",
		data() {
			return {
				selectIds: [], // 已选择的id
				page: {
					page: 1,
					pageSize: 10,
				},
				status: ''
			};
		},
		// 定义传入的参数
		props: {
			loading: {
				required: true,
				type: Boolean,
				default: false,
			},
			list: {
				required: true,
				type: Array,
			},
			total: {
				required: true,
				type: Number,
			},
		},
		methods: {
			/**
			 * @desc 分页
			 */
			pagination(e) {
				this.$emit("pagination", e);
			},
			/**
			 * @desc 重置刷新列表分页数据
			 */
			refreshPagination() {
				this.$refs.pagination.currentPage = 1;
			},
		},
	};
</script>

<style lang="scss">
	.table-tag {
		margin: 5px 5px 0 0;
	}
</style>
