import service from "@/utils/service.js";

// 账户列表
export function list(params) {
	return service({
		method: 'get',
		url: 'admin/index',
		params: params
	})
}

// 角色列表
export function roleList(params) {
	return service({
		method: 'get',
		url: 'admin/roleList',
		params: params
	})
}

// 账户新增
export function add(data) {
	return service({
		method: 'post',
		url: 'admin/add',
		data
	})
}

// 账户详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'admin/detail',
		params: params
	})
}

// 账户修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'admin/edit',
		data
	})
}

// 账户状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'admin/multiChange',
		data
	})
}

// 账户删除
export function del(data) {
	return service({
		method: 'post',
		url: 'admin/del',
		data
	})
}
