export default {
	explain: [{
			required: true,
			message: "请输入说明",
			trigger: "blur",
		},
		{
			max: 10,
			message: "请勿超过10位",
			trigger: "blur",
		},
	],
	receiving_method: [{
		required: true,
		message: "请选择领取方式",
		trigger: "blur",
	}, ],
	use_range: [{
		required: true,
		message: "请选择使用范围",
		trigger: "blur",
	}, ],
	category_ids: [{
		required: true,
		message: "请选择指定分类",
		trigger: "blur",
	}, ],
	product_ids: [{
		required: true,
		message: "请选择指定产品",
		trigger: "blur",
	}, ],
	discount_method: [{
		required: true,
		message: "请选择优惠方式",
		trigger: "blur",
	}, ],
	full: [{
		required: true,
		message: "请输入满金额",
		trigger: "blur",
	}, ],
	full_reduce: [{
		required: true,
		message: "请输入减金额",
		trigger: "blur",
	}, ],
	direct_reduce: [{
		required: true,
		message: "请输入直减金额",
		trigger: "blur",
	}, ],
	discount: [{
		required: true,
		message: "请输入折扣",
		trigger: "blur",
	}, ],
	is_time_limit: [{
		required: true,
		message: "请选择是否现时",
		trigger: "blur",
	}, ],
	effective_time: [{
		required: true,
		message: "请选择时间",
		trigger: "blur",
	}, ],
	is_number_limit: [{
		required: true,
		message: "请选择是否限量",
		trigger: "blur",
	}, ],
	limit_number: [{
		required: true,
		message: "请输入限量数量",
		trigger: "blur",
	}, ],
	use_day: [{
		required: true,
		message: "请输入使用有效天数",
		trigger: "blur",
	}, ],
	status: [{
		required: true,
		message: "请选择状态",
		trigger: "blur",
	}, ],
}
