export default {
	category_path: [{
		required: true,
		message: "请选择分类",
		trigger: "blur",
	}, ],
	name: [{
			required: true,
			message: "请输入产品名称",
			trigger: "blur",
		},
		{
			max: 30,
			message: "长度请勿超过30个字符",
			trigger: "blur",
		},
	],
	cover_image: [{
		required: true,
		message: "请上传封面图",
		trigger: "blur",
	}, ],
	is_enterprise: [{
		required: true,
		message: "请选择是否企业可购",
		trigger: "blur",
	}, ],
	integral_rate: [{
		required: true,
		message: "请输入获得积分利率",
		trigger: "blur",
	}, ],
	brand_id: [{
		required: true,
		message: "请选择品牌",
		trigger: "blur",
	}, ],
	manufacturer_id: [{
		required: true,
		message: "请选择生产厂商",
		trigger: "blur",
	}, ],
	fictitious_score: [{
		required: true,
		message: "请输入虚拟评分",
		trigger: "blur",
	}, ],
	spec: [{
		required: true,
		message: "请添加产品规格",
		trigger: "blur",
	}, ],
	qualifications_file: [{
		required: true,
		message: "请上传资质文件",
		trigger: "blur",
	}, ],
	test_report_images: [{
		required: true,
		message: "请上传检测报告图",
		trigger: "blur",
	}, ],
	detail_images: [{
		required: true,
		message: "请上传详情图片",
		trigger: "blur",
	}, ],
	detail_content: [{
		required: true,
		message: "请输入详情内容",
		trigger: "blur",
	}, ],
	is_recommend: [{
		required: true,
		message: "请选择是否推荐",
		trigger: "blur",
	}, ],
	status: [{
		required: true,
		message: "请选择状态",
		trigger: "blur",
	}, ],
}
