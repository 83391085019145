<template>
	<div class="detail-save">
		<el-dialog title="详情" :visible.sync="dialogShow" :fullscreen="$isMobilePhone() ? true : false"
			:width="$isMobilePhone() ? '100%' : '50%'" :top="$isMobilePhone() ? '' : '2vh'" @close="dialogShow = false"
			class="data-dialog">
			<div class="detail-main">
				<div class="detail-content">
					<div class="detail-title">企业信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">企业类型：</div>
							<div class="li-data">{{row.type.name}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">企业名称：</div>
							<div class="li-data">{{row.enterprise_name}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">手机号：</div>
							<div class="li-data">{{row.mobile}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">经营地址：</div>
							<div class="li-data">{{row.province}}{{row.city}}{{row.area}}{{row.address_detail}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">法人身份证图片：</div>
							<div class="li-data u-flex">
								<el-image class="detail-image" v-if="row.legal_person_idcard_pt"
									:src="row.legal_person_idcard_pt" :preview-src-list="srcList">
								</el-image>
								<el-image class="detail-image" v-if="row.legal_person_idcard_ne"
									:src="row.legal_person_idcard_ne" :preview-src-list="srcList">
								</el-image>
								{{!row.legal_person_idcard_pt && !row.legal_person_idcard_pt?'无':''}}
							</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">营业执照：</div>
							<div class="li-data">
								<el-image class="detail-image" :src="row.business_license"
									:preview-src-list="[row.business_license]"></el-image>
							</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">医疗资质：</div>
							<div class="li-data">
								<el-image class="detail-image" :src="row.medical_qualification"
									:preview-src-list="[row.medical_qualification]"></el-image>
							</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">认证时间：</div>
							<div class="li-data">{{row.create_time}}</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogShow: false,
				srcList: [],
				row: {
					type: {
						name: ''
					}
				},
			};
		},
		props: {},
		methods: {
			open(e) {
				this.row = e.row
				this.srcList = [this.row.legal_person_idcard_pt, this.row.legal_person_idcard_ne]
				this.dialogShow = true;
			},
		},
	};
</script>

<style lang="scss">
</style>
