import {
	selectPage,
} from "@/api/power.js";
import {
	list as roleList,
	add,
	edit,
} from "@/api/role.js";
import formRules from './formRules.js';
export default {
	name: 'save-data',
	data() {
		return {
			// 显示弹框
			dialogShow: false,
			// 提交参数
			params: {
				path: [],
				name: '',
				power_ids: [],
				sort: '',
				status: '1',
			},
			// 上级角色
			parentList: [],
			// 权限列表
			powerList: [],
			// 权限树型配置
			treeProps: {
				children: 'children',
				label: 'name'
			},
			// 类型 0-添加 1-编辑
			dialogType: 0,
			// 数据验证
			formRules: {},
			// 弹框信息
			dialog: [{
				title: '添加角色', // 标题
			}, {
				title: '编辑角色', // 标题
			}],
			isSubmit: true, // 是否提交 true-未提交 false-已提交
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {
		this.formRules = formRules;
	},
	methods: {
		/**
		 * @desc 开启弹框
		 * @param Number type 类型 0-添加 1-编辑
		 * @param Number id 当前角色id
		 */
		open(type, e) {
			this.dialogType = type;
			// 获取角色列表
			this.getRoleList();
			// 获取权限列表
			this.getPowerList();
			if (type == 1) { // 编辑
				this.params = e.row
				this.params.path.forEach((item, index) => {
					this.params.path[index] = Number(item);
				})
			}
			// 显示弹框
			this.dialogShow = true;
		},
		/**
		 * @desc 获取角色列表
		 */
		getRoleList() {
			roleList().then(res => {
				this.parentList = res.data.list
			})
		},
		/**
		 * @desc 获取权限列表
		 */
		getPowerList() {
			selectPage().then(res => {
				this.powerList = res.data.list
			})
		},
		/**
		 * @desc 关闭弹框
		 */
		close() {
			// 提交信息清空
			this.params = {
				path: [],
				name: '',
				power_ids: [],
				sort: '',
				status: '1',
			}
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false;
		},
		// 文件选择
		fileConfirm(e) {
			this.params[e.name] = e.fileList.toString(',');
		},
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			var power_ids = [];
			this.params.power_ids = this.$refs.tree.getCheckedKeys()
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				if (!that.isSubmit) {
					return false;
				}
				that.isSubmit = false;
				if (that.dialogType == 0) {
					var form = add(that.params);
				} else {
					var form = edit(that.params);
				}
				form.then((res) => {
					that.isSubmit = true;
					that.$successMsg(res.msg);
					that.dialogShow = false;
					that.$emit('submit')
				}).catch(() => {
					that.isSubmit = true;
				});
			})
		},
	}
}