export default {
	name: [{
			required: true,
			message: "请输入产品名称",
			trigger: "blur",
		},
		{
			max: 30,
			message: "长度请勿超过30个字符",
			trigger: "blur",
		},
	],
	cover_image: [{
		required: true,
		message: "请上传封面图",
		trigger: "blur",
	}, ],
	spec: [{
		required: true,
		message: "请添加产品规格",
		trigger: "blur",
	}, ],
	detail_images: [{
		required: true,
		message: "请上传详情图片",
		trigger: "blur",
	}, ],
	detail_content: [{
		required: true,
		message: "请输入详情内容",
		trigger: "blur",
	}, ],
	status: [{
		required: true,
		message: "请选择状态",
		trigger: "blur",
	}, ],
}
