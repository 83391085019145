import service from "@/utils/service.js";


// 获取系统配置
export function getSystemConfig(params) {
	return service({
		method: 'get',
		url: 'systemConfig/getSystemConfig',
		params: params
	})
}

// 获取系统配置
export function getData(params) {
	return service({
		method: 'get',
		url: 'systemConfig/index',
		params: params
	})
}

// 售后订单删除
export function submit(data) {
	return service({
		method: 'post',
		url: 'systemConfig/index',
		data
	})
}
