export default {
	title: [{
			required: true,
			message: "请输入标题",
			trigger: "blur",
		},
		{
			max: 30,
			message: "标题请勿超过30个字符",
			trigger: "blur",
		},
	],
	cover_image: [{
		required: true,
		message: "请上传封面图",
		trigger: "blur",
	}, ],
	start_time: [{
		required: true,
		message: "请选择活动时间",
		trigger: "blur",
	}, ],
	product: [{
		required: true,
		message: "请添加产品",
		trigger: "blur",
	}, ],
	sort: [{
		required: true,
		message: "请输入排序",
		trigger: "blur",
	}, ],
	status: [{
		required: true,
		message: "请选择状态",
		trigger: "blur",
	}, ],
}
