<template>
	<div>
		<el-dialog title="文件选择" :visible.sync="dialogShow" top="4vh" width="80%" :before-close="handleClose"
			:append-to-body="true">
			<div class="attachment-main">
				<div class="category-main">
					<el-tree :data="categorylist" :props="treeProps" @node-click="categoryChange"
						:highlight-current="true"></el-tree>
				</div>
				<div class="attachment-content">
					<div class="tool">
						<div class="tool-left">
							<el-button type="info" size="small" icon="el-icon-refresh" @click="resetData()">
							</el-button>
							<el-button type="primary" size="small" :disabled="changeFileList.length == 0"
								@click="confirm">
								使用选中图片（{{changeFileList.length}}/{{number}}）
							</el-button>
							<el-button class="el-icon-upload" type="success" size="small"
								@click="$refs.uploadFile.show()">
								上传文件
							</el-button>
							<!-- <div class="form-tip-red form-tip-2">提示：左侧选择指定分类后，上传图片会绑定该分类</div> -->
						</div>
					</div>
					<div class="attachment-data" v-loading="attachmentIsLoading" element-loading-text="拼命加载中"
						element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)">
						<div class="attachment-list">
							<div :class="['attachment-li',changeFileList.indexOf(item.file_url) > -1?'attachment-li-active':'']"
								v-for="(item,index) in attachmentList" :key="index">
								<div :class="['attachment-number',changeFileList.indexOf(item.file_url) > -1?'attachment-number-active':'attachment-number-in']"
									@click="attachmentChange(index)">
									{{changeFileList.indexOf(item.file_url) > -1?changeFileList.indexOf(item.file_url)+1:''}}
								</div>
								<el-image fit="cover" style="width: 140px; height: 140px;display: block;"
									:src="item.base_file_url" :lazy="true" v-if="item.file_type == 'image'"
									:preview-src-list="imageUrlList">
								</el-image>
								<el-image :src="$getSystemConfig('upload_doc_img')"
									style="width: 140px; height: 140px;display: block;" alt=""
									v-if="item.file_type == 'application'">
								</el-image>
								<div class="info">
									<div class="info-li">文件名：{{item.file_name}}</div>
									<div class="info-li">所属分类：{{item.category?item.category.name:'无'}}</div>
									<div class="info-li" v-if="item.file_type == 'image'">尺寸：{{item.image_width}} x
										{{item.image_height}}
									</div>
									<div class="info-li">大小：{{item.file_size_text}}</div>
								</div>
							</div>
							<el-empty v-if="!attachmentIsLoading && attachmentList.length == 0" description="暂无图片">
							</el-empty>
						</div>
					</div>
					<pagination :page="page" :pageSize="pageSize" :total="attachmentTotal" :pageSizeList="[20]"
						@pagination="pagination" />
				</div>
			</div>
		</el-dialog>
		<uploadFile ref="uploadFile" @uploadSuccess="uploadSuccess" @close="resetData"></uploadFile>
	</div>
</template>
<script>
	import {
		attachmentList,
		categoryList,
		del,
	} from "@/api/attachment.js";
	export default {
		data() {
			return {
				dialogShow: false, // 弹框显示隐藏
				attachmentIsLoading: true, // 附件部分是否加载中
				// 图片路径数组
				imageUrlList: [],
				changeFileList: [], // 已确定的图片路径
				changeBaseFileList: [], // 已确定的图片完整路径
				category_id: 0, // 已选择的分类id
				categorylist: [{ // 分类列表数据
					id: '',
					name: '全部图片'
				}],
				page: 1, // 分页
				pageSize: 20, // 每页数量
				attachmentTotal: 0, // 附件总数
				attachmentList: [], // 附件列表
				treeProps: { // 树形分类配置
					children: 'children',
					label: 'name'
				},
				cascaderProps: { // 级联选择器配置
					checkStrictly: true,
					label: 'name',
					expandTrigger: 'hover',
					value: 'id'
				}
			};
		},
		props: {
			// 可选数量
			number: {
				type: Number,
				default: 1,
			},
			// 文件类型
			fileType: {
				type: String,
				default: 'image',
			},
			// 确认的文件路径
			confirmFileList: {
				type: Object,
				default: {},
			},
		},
		created() {},
		methods: {
			// 显示弹框
			show() {
				this.$showLoading();
				this.categorylist = [{ // 分类列表数据
					id: '',
					name: '全部图片'
				}];
				// 回显已选择的图片
				this.changeFileList = this.$deepClone(this.confirmFileList.fileList)
				this.changeBaseFileList = this.$deepClone(this.confirmFileList.baseFileList)
				this.getCategory();
			},
			// 获取分类
			getCategory() {
				categoryList().then((res) => {
					this.categorylist = this.categorylist.concat(res.data.list);
					// 重置，并加载附件数据
					this.resetData();
				});
			},
			// 获取附件
			getAttachment() {
				var params = {
					file_type: this.fileType,
					page: this.page,
					pageSize: this.pageSize,
				};
				if (this.category_id) {
					params.category_id = this.category_id
				}
				attachmentList(params).then((res) => {
					this.$hideLoading();
					this.dialogShow = true;
					this.attachmentIsLoading = false;
					this.attachmentList = this.attachmentList.concat(res.data.list);
					this.attachmentTotal = res.data.total
					if (this.fileType == 'image') {
						this.attachmentList.forEach((item, index) => {
							this.imageUrlList.push(item.base_file_url);
						})
					}
				}).catch((err) => {
					this.$hideLoading();
				});
			},
			// 重置数据
			resetData(page = 1) {
				this.attachmentIsLoading = true;
				this.page = page;
				this.attachmentList = [];
				this.getAttachment();
			},
			// 分类选择
			categoryChange(e) {
				this.category_id = e.id
				// 追加数据
				this.changeFileList = [];
				this.changeBaseFileList = [];
				this.resetData();
			},
			// 文件选择
			attachmentChange(index) {
				// 当前选择值
				var item = this.attachmentList[index]
				var key = this.changeFileList.indexOf(item.file_url);
				if (key > -1) { // 已存在
					// 删除当前值
					this.changeFileList.splice(key, 1)
					this.changeBaseFileList.splice(key, 1)
				} else { // 不存在
					if (this.number == 1 && this.changeFileList.length > 0) { // 只能选一张 且 已选择一张
						// 清空选择
						this.changeFileList = [];
						this.changeBaseFileList = [];
					}
					if (this.changeFileList.length == this.number) { // 选择多张，并限制选择张数
						this.$errorMsg('当前限制只能选' + this.number + '张');
						return;
					}
					// 追加数据
					this.changeFileList.push(item.file_url);
					this.changeBaseFileList.push(item.base_file_url);
				}
			},
			// 文件上传完成
			uploadSuccess(e) {
				this.resetData();
			},
			// 确定选择
			confirm() {
				this.dialogShow = false;
				this.$emit('confirm', {
					fileList: this.changeFileList,
					baseFileList: this.changeBaseFileList,
				});
			},
			// 关闭当前弹框
			handleClose() {
				this.dialogShow = false;
				this.$emit('close');
			},
			// 分页
			pagination(e) {
				this.pageSize = e.pageSize
				this.resetData(e.page);
			}
		},
	};
</script>
<style lang="scss" scoped>
	.attachment-main {
		width: 100%;
		display: flex;

		.category-main {
			width: 20%;
		}

		.attachment-content {
			flex: 1;

			.tool {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.form-tip-2 {
					padding: 8px 0 10px;
				}
			}

			.attachment-data {
				width: 100%;

				.attachment-list {
					overflow-y: scroll;
					height: 640px;

					.attachment-li {
						display: inline-block;
						margin: 10px 10px 0 0;
						cursor: pointer;
						position: relative;
						border: 2px solid #FFFFFF;

						.attachment-number {
							position: absolute;
							top: 3px;
							right: 3px;
							z-index: 10;
							width: 18px;
							height: 18px;
							line-height: 18px;
							text-align: center;
							font-size: 10px;
							border-radius: 18px;
							color: #FFFFFF;
							border: 1px solid #cdcdcd;
						}

						.attachment-number-active {
							color: #FFFFFF;
							background-color: #409eff;
						}

						.info {
							width: 100%;
							display: none;
							position: absolute;
							bottom: 0;
							left: 0;
							z-index: 10;
							color: #FFFFFF;
							background-color: rgba(0, 0, 0, 0.6);
							font-size: 10px;
							line-height: 14px;
							padding: 5px;
							box-sizing: border-box;
						}
					}

					.attachment-li-active {
						border: 2px solid #409eff;
					}

					.attachment-li:hover .info {
						display: block;
					}
				}
			}
		}
	}
</style>
