<template>
	<div class="detail-table">
		<div class="table">
			<div class="tb-header u-flex">
				<div class="li li-20">产品名称</div>
				<div class="li li-20">产品封面图</div>
				<div class="li li-10">规格</div>
				<div class="li li-10">数量</div>
				<div class="li li-10">单位</div>
				<div class="li li-10">单价</div>
				<div class="li li-10">总价</div>
				<div class="li li-10">优惠券金额</div>
				<div class="li li-10">达量优惠</div>
				<div class="li li-10">获得积分</div>
			</div>
			<div class="td" v-if="productList.length > 0" v-for="(item,index) in productList">
				<div class="detail-data u-flex">
					<div class="li li-20">{{item.product_name}}</div>
					<div class="li li-20 u-flex-center">
						<el-image class="detail-image" :src="item.product_image"></el-image>
					</div>
					<div class="li li-10">{{item.product_spec_name}}</div>
					<div class="li li-10">{{item.product_number}}</div>
					<div class="li li-10">{{item.product_unit}}</div>
					<div class="li li-10">￥{{item.product_price}}</div>
					<div class="li li-10">￥{{item.total_money}}</div>
					<div class="li li-10">￥{{item.coupon_reduce}}</div>
					<div class="li li-10">￥{{item.number_reduce}}</div>
					<div class="li li-10">{{item.get_integral}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'product',
		data() {
			return {}
		},
		// 定义传入的参数
		props: {
			productList: {
				type: Array,
				default: []
			}
		},
		created() {},
		methods: {}
	}
</script>
<style lang="scss">
	.detail-table {
		.table {
			width: 100%;
			border: 1px solid #f0f0f0;

			.tb-header {
				width: 100%;
				height: 40px;
				font-weight: bold;
				font-size: 12px;

				.li {
					text-align: center;
					line-height: 40px;
					height: 100%;
				}
			}

			.td {
				width: 100%;
				font-size: 12px;
				padding: 10px 0;
				border-bottom: 1px solid #f6f6f6;

				.li {
					padding: 0 10px;
					box-sizing: border-box;
					text-align: center;
					line-height: 16px;
				}
			}

			.td:last-child {
				border: none;
			}

			.li-10 {
				width: 10%;
			}

			.li-15 {
				width: 15%;
			}

			.li-20 {
				width: 20%;
			}
		}
	}
</style>
