<template>
	<div>
		<!-- 卡片区域 -->
		<el-card class="box-card">
			<div ref="tableTool">
				<list-tools @refresh="getList" @add="$refs.save.open(0)" :batchDel="false" :search="false">
				</list-tools>
			</div>
			<!-- 列表信息 -->
			<tableList ref="tableList" :loading="loading" :list="mainList" :total="total"
				@selectionChange="selectIds = $event" @edit="getDetail" @delete="delData(1,$event.id)">
			</tableList>
		</el-card>
		<!-- 提交表单 -->
		<save ref="save" @submit="getList" />
	</div>
</template>

<script>
	// 主接口信息
	import {
		list,
		getDetail,
		del,
	} from "@/api/attachmentCategory.js";
	// 列表组件
	import tableList from './components/table/list/list.vue';
	// 弹框添加、修改数据组件
	import save from './components/dialog/save/save.vue';
	export default {
		components: {
			tableList,
			save,
		},
		data() {
			return {
				// 加载状态 true-加载中 false-加载完成
				loading: true,
				// 已选择的id
				selectIds: [],
				// 主体列表数据
				mainList: [],
				// 总条目数
				total: 0,
			}
		},
		created() {
			this.getList();
		},
		methods: {
			/**
			 * @desc 获取账户列表
			 */
			getList() {
				this.loading = true;
				list(this.params).then((res) => {
					this.loading = false;
					this.mainList = res.data.list;
					this.total = res.data.total;
				});
			},
			/**
			 * @desc 获取详情
			 */
			getDetail(e) {
				this.$showLoading();
				// 查询详情
				getDetail({
					id: e.id,
				}).then((res) => {
					this.$hideLoading();
					// 显示弹框
					this.$refs.save.open(1, res.data);
				}).catch(() => {
					this.$hideLoading();
				});
			},
			/**
			 * @desc 删除
			 */
			delData(type = 1, id = 0) {
				let that = this;
				if (type == 1) { // 单删除
					var ids = [id];
					var msg = '是否删除当前项？';
				} else { // 批量删除
					if (that.selectIds.length == 0) {
						that.$errorMsg('请选择您要删除的数据');
						return false;
					}
					var ids = that.selectIds;
					var msg = "确认删除已选中的" + that.selectIds.length + "项？";
				}
				that.$massageConfirm(msg, function() {
					del({
						ids: ids
					}).then((res) => {
						that.$successMsg(res.msg);
						that.getList();
					});
				});
			},
		}
	}
</script>

<style lang="scss">
</style>
