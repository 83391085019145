export default {
	name: 'spec',
	data() {
		return {
			specList: [],
		}
	},
	// 定义传入的参数
	props: {},
	created() {},
	methods: {
		getData(e = []) {
			this.specList = e
			setTimeout(() => {
				this.specList.forEach((item, index) => {
					let ref = eval('this.$refs.specImageSelectAttachmentBtn' + index)[0]
					ref.initData({
						fileList: [item.spec_image],
						baseFileList: [item.base_spec_image],
					});
				})
			}, 0)
		},
		// 添加规格
		addSpec() {
			this.specList.push({
				spec_name: '',
				spec_image: '',
				original_price: '',
				price: '',
				stock: 1,
				fictitious_sold: '',
				sold: '0',
				discountList: [],
			})
			this.peration();
		},
		// 删除规格
		delSpec(index) {
			// 从提交的数据中删除
			this.specList.splice(index, 1)
			this.peration();
		},
		// 添加抵扣
		addDiscount(index) {
			this.specList[index].discountList.push({
				number: '',
				price: '',
			})
			this.peration();
		},
		// 删除规格折扣
		delSpecDiscount(index, index2) {
			// 从提交的数据中删除
			this.specList[index].discountList.splice(index2, 1)
			this.peration();
		},
		// 规格文件选择
		specFileConfirm(e) {
			this.specList[e.name].spec_image = e.fileList.toString(',');
			this.peration();
		},
		// 清除数据
		clear() {
			this.specList = [];
			this.peration();
		},
		// 操作
		peration() {
			this.$emit('specOperation', this.specList);
		},
	}
}
