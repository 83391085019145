import service from "@/utils/service.js";

// 生产厂商列表
export function list(params) {
	return service({
		method: 'get',
		url: 'productManufacturer/index',
		params: params
	})
}

// 选择列表
export function selectPage(params) {
	return service({
		method: 'get',
		url: 'productManufacturer/selectPage',
		params: params
	})
}

// 角色列表
export function roleList(params) {
	return service({
		method: 'get',
		url: 'productManufacturer/roleList',
		params: params
	})
}

// 生产厂商新增
export function add(data) {
	return service({
		method: 'post',
		url: 'productManufacturer/add',
		data
	})
}

// 生产厂商详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'productManufacturer/detail',
		params: params
	})
}

// 生产厂商修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'productManufacturer/edit',
		data
	})
}

// 生产厂商状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'productManufacturer/multiChange',
		data
	})
}

// 生产厂商删除
export function del(data) {
	return service({
		method: 'post',
		url: 'productManufacturer/del',
		data
	})
}
