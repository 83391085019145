import service from "@/utils/service.js";

// 新闻列表
export function list(params) {
	return service({
		method: 'get',
		url: 'news/index',
		params: params
	})
}

// 新闻新增
export function add(data) {
	return service({
		method: 'post',
		url: 'news/add',
		data
	})
}

// 新闻详情
export function detail(params) {
	return service({
		method: 'get',
		url: 'news/edit',
		params: params
	})
}

// 新闻修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'news/edit',
		data
	})
}

// 新闻状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'news/multiChange',
		data
	})
}

// 新闻删除
export function del(data) {
	return service({
		method: 'post',
		url: 'news/del',
		data
	})
}
