<template>
	<div class="">
		<el-dialog title="售后订单详情" :visible.sync="dialogShow" :fullscreen="$isMobilePhone()?true:false"
			:width="$isMobilePhone()?'100%':'80%'" :top="$isMobilePhone()?'':'2vh'" @close="dialogShow = false">
			<div class="detail-main">
				<div class="detail-content"
					v-if="row.is_return_goods == 1 && row.status > 0  && row.status != 2 && row.status < 6">
					<div class="detail-title">收货信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex" v-if="row.status > 2">
							<div class="li-label">物流公司：</div>
							<div class="li-data">{{row.expressCompany.name}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.status > 2">
							<div class="li-label">物流单号：</div>
							<div class="li-data">{{row.express_no}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">收货人姓名：</div>
							<div class="li-data">{{row.receiver_name}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">收货人手机号：</div>
							<div class="li-data">{{row.receiver_mobile}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">收货地址：</div>
							<div class="li-data">
								{{row.receiver_province}}{{row.receiver_city}}{{row.receiver_area}}{{row.receiver_address}}
							</div>
						</div>
					</div>
				</div>
				<div class="detail-content" v-if="row.express_no">
					<div class="detail-title">物流信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">物流公司：</div>
							<div class="li-data">{{row.expresscompany.name}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">运单号：</div>
							<div class="li-data">{{row.express_no}}</div>
						</div>
					</div>
				</div>
				<div class="detail-content">
					<div class="detail-title">订单信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">退款号：</div>
							<div class="li-data">{{row.refund_number}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">订单号：</div>
							<div class="li-data">{{row.order_number}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">订单类型：</div>
							<div class="li-data">
								<el-tag size="mini">{{row.order_type_text}}</el-tag>
							</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">支付方式：</div>
							<div class="li-data">
								<el-tag size="mini">{{row.order_pay_type_text}}</el-tag>
							</div>
						</div>
						<div class="li-item u-flex" v-if="row.order_type < 6">
							<div class="li-label">订单总金额：</div>
							<div class="li-data">￥{{row.order_total_money}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.order_type < 6">
							<div class="li-label">订单支付金额：</div>
							<div class="li-data">￥{{row.order_pay_money}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.order_type == 6">
							<div class="li-label">订单总积分：</div>
							<div class="li-data">￥{{row.order_total_integral}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.order_type == 6">
							<div class="li-label">订单支付积分：</div>
							<div class="li-data">{{row.order_pay_integral}}</div>
						</div>
					</div>
				</div>
				<div class="detail-content">
					<div class="detail-title">商品信息</div>
					<product ref="productList" :productList="row.product"></product>
				</div>
				<div class="detail-content">
					<div class="detail-title">售后订单信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">退款原因：</div>
							<div class="li-data">{{row.reason}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">用户补充：</div>
							<div class="li-data">{{row.user_supplement?row.user_supplement:'无'}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">附加图片：</div>
							<div class="li-data">
								<el-image class="detail-image" v-if="row.images.length > 0" :src="row.images">
								</el-image>
								{{row.images.length==0?'无':''}}
							</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">退款状态：</div>
							<div class="li-data">
								<el-tag size="mini">{{row.status_text}}</el-tag>
							</div>
						</div>
						<div class="li-item u-flex" v-if="row.examine_time">
							<div class="li-label">审核反馈：</div>
							<div class="li-data">{{row.examine_result?row.examine_result:'无'}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.status == 5 && row.order_pay_type < 6">
							<div class="li-label">已退金额：</div>
							<div class="li-data">￥{{row.return_money}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.status == 5 && row.order_pay_type == 6">
							<div class="li-label">已退积分：</div>
							<div class="li-data">{{row.return_integral}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">提交时间：</div>
							<div class="li-data">{{row.create_time}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.examine_time">
							<div class="li-label">审核时间：</div>
							<div class="li-data">{{row.examine_time_text}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.send_time">
							<div class="li-label">发货时间：</div>
							<div class="li-data">{{row.send_time_text}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.cancel_time">
							<div class="li-label">取消时间：</div>
							<div class="li-data">{{row.cancel_time_text}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.receipt_time">
							<div class="li-label">确认收货时间：</div>
							<div class="li-data">{{row.receipt_time_text}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.refund_time">
							<div class="li-label">退款时间：</div>
							<div class="li-data">{{row.refund_time_text}}</div>
						</div>
					</div>
				</div>

			</div>
		</el-dialog>
	</div>
</template>

<script>
	import product from './product/product.vue';
	export default {
		components: {
			product,
		},
		name: 'detail',
		data() {
			return {
				// 显示弹框
				dialogShow: false,
				row: {
					user: {
						nickname: '',
					},
					product: [],
					parentuser: {
						nickname: ''
					},
					images: [],
				},
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param e 订单信息
			 */
			open(e) {
				this.row = e.row
				// 显示弹框
				this.dialogShow = true;
			},
		}
	}
</script>

<style lang="scss">
	.detail-main {
		width: 96%;
		margin: 0 auto;
		font-size: 12px;

		.detail-content {
			padding-top: 20px;
			width: 100%;

			.detail-title {
				font-size: 13px;
				font-weight: bold;
				padding-bottom: 10px;
			}

			.detail-li {

				.li-item {
					width: 50%;
					padding: 10px 0;

					.li-label {
						width: 100px;
						text-align: right;
					}

					.li-data {
						padding-left: 10px;
					}
				}
			}
		}
	}
</style>
