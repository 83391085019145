<template>
	<div>
		<!-- 卡片区域 -->
		<el-card class="box-card">
			<div ref="tableTool">
				<list-search v-show="searchShow" :searchProps="searchProps" @change="searchChange"
					@search="search">
				</list-search>
				<list-tools :add="false" @refresh="refreshList" @batchDel="delData(2)"
					@openSearch="searchShow = !searchShow">
				</list-tools>
			</div>
			<!-- 列表信息 -->
			<tableList ref="tableList" :loading="loading" :list="mainList" :total="total"
				@selectionChange="selectIds = $event" @pagination="pagination" @examine="getExamine($event.id)"
				@receipt="receipt($event.id)" @refund="refund($event.id)" @detail="getDetail($event.id)"
				@delete="delData(1,$event.id)">
			</tableList>
		</el-card>
		<!-- 提交表单 -->
		<detail ref="detail" />
		<examine ref="examine" @complete="refreshList" />
	</div>
</template>

<script>
	import {
		list,
		getExamine,
		getDetail,
		receipt,
		refund,
		del,
	} from "@/api/orderRefund.js";
	// 搜索配置信息
	import searchProps from "./components/table/search/searchProps.js";
	// 列表组件
	import tableList from './components/table/list/list.vue';
	// 详情弹框
	import detail from './components/dialog/detail/detail.vue';
	// 审核弹框
	import examine from './components/dialog/examine/examine.vue';
	export default {
		components: {
			tableList,
			detail,
			examine,
		},
		data() {
			return {
				// 列表加载中
				loading: true,
				// 搜索模块显示隐藏
				searchShow: false,
				// 已选择的id
				selectIds: [],
				// 主体列表数据
				mainList: [],
				// 列表请求数据
				params: {
					order_type: '',
					refund_number: '',
					order_number: '',
					nickname: '',
					create_time: '',
					status: '',
					page: 1, // 默认起始页
					pageSize: 10, // 默认每页数量
				},
				// 总条目数
				total: 0,
				// 搜索配置信息
				searchProps: {},
			}
		},
		created() {
			this.searchProps = searchProps;
			this.getList();
		},
		methods: {
			// 搜索开启关闭
			openSearch() {
				this.searchShow = !this.searchShow
			},
			/**
			 * @desc 获取订单列表
			 */
			getList() {
				this.loading = true;
				list(this.params).then((res) => {
					this.loading = false;
					this.mainList = res.data.list;
					this.total = res.data.total;
				});
			},
			/**
			 * @desc 搜索输入
			 */
			searchChange(e) {
				this.params.order_type = e.order_type;
				this.params.refund_number = e.refund_number;
				this.params.order_number = e.order_number;
				this.params.nickname = e.nickname;
				this.params.create_time = e.create_time;
				this.params.status = e.status;
			},
			/**
			 * @desc 搜索
			 */
			search(e) {
				this.params.page = 1;
				// 重置刷新列表分页数据
				this.$refs.tableList.refreshPagination();
				this.refreshList();
			},
			/**
			 * @desc 刷新数据
			 */
			refreshList() {
				// this.params.page = 1;
				// 重置刷新列表分页数据
				// this.$refs.tableList.refreshPagination();
				this.getList();
			},
			/**
			 * @desc 获取详情数据
			 */
			getExamine(id) {
				this.$showLoading();
				// 查询详情
				getExamine({
					id: id,
				}).then((res) => {
					this.$hideLoading();
					// 显示弹框
					this.$refs.examine.open(res.data);
				});
			},
			/**
			 * @desc 获取详情数据
			 */
			getDetail(id) {
				this.$showLoading();
				// 查询详情
				getDetail({
					id: id,
				}).then((res) => {
					this.$hideLoading();
					// 显示弹框
					this.$refs.detail.open(res.data);
				});
			},
			receipt(id) {
				let that = this;
				that.$massageConfirm('是否确认收货？', function() {
					receipt({
						id: id
					}).then((res) => {
						that.$successMsg(res.msg);
						that.refreshList();
					});
				});
			},
			refund(id) {
				let that = this;
				that.$massageConfirm('是否确认退款？', function() {
					refund({
						id: id
					}).then((res) => {
						that.$successMsg(res.msg);
						that.refreshList();
					});
				});
			},
			/**
			 * @desc 删除
			 */
			delData(type = 1, id = 0) {
				let that = this;
				if (type == 1) { // 单删除
					var ids = [id];
					var msg = '是否删除当前项？';
				} else { // 批量删除
					if (that.selectIds.length == 0) {
						that.$errorMsg('请选择您要删除的数据');
						return false;
					}
					var ids = that.selectIds;
					var msg = "确认删除已选中的" + that.selectIds.length + "项？";
				}
				that.$massageConfirm(msg, function() {
					del({
						ids: ids
					}).then((res) => {
						that.$successMsg(res.msg);
						that.refreshList();
					});
				});
			},
			/**
			 * @desc 分页查询
			 */
			pagination(e) {
				this.params.page = e.page;
				this.params.pageSize = e.pageSize;
				this.getList();
			},
		}
	}
</script>

<style lang="scss">
</style>
