<template>
	<div class="login-container">
		<el-col class="login-box" :xs="20" :lg="8">
			<div class="title-name">欢迎登录 · 医大药械网</div>
			<div class="title-brief">后台管理系统</div>
			<!-- 表单登录 -->
			<el-form ref="loginFormRef" :model="params" :rules="loginFormRules" label-width="0px" class="login-form">
				<el-form-item prop="account">
					<el-input prefix-icon="iconfont icon-iconzh1" v-model="params.account"></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input prefix-icon="iconfont icon-mima" type="password" v-model="params.password">
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" :loading="loginLoading" @click="onLogin">{{loginLoading?'登陆中':'登录'}}
					</el-button>
				</el-form-item>
			</el-form>
		</el-col>
	</div>
</template>

<script>
	import {
		login,
	} from "@/api/login.js";
	export default {
		data() {
			return {
				// 表单提交参数
				params: {
					account: 'admin',
					password: '123123',
				},
				// 表单验证
				loginFormRules: {
					account: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}, ],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, ],
				},
				loginLoading: false,
			}
		},
		created() {},
		methods: {
			// 提交登录
			onLogin() {
				this.$refs.loginFormRef.validate(valid => {
					if (!valid) {
						return false;
					}
					this.loginLoading = true;
					login(this.params).then((res) => {
						this.$successMsg(res.msg);
						// 设置缓存
						this.$setStorage("token", res.data.token);
						setTimeout(() => {
							this.$router.push("/");
						}, 1500);
					}).catch((err) => {
						this.loginLoading = false;
					});
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login-container {
		background-color: #2b4b6b;
		height: 100vh;
		// height: calc(100vh - 50px);

		.login-box {
			border-radius: 3px;
			background-color: #FFFFFF;
			position: absolute;
			left: 50%;
			top: 40%;
			transform: translate(-50%, -50%);
		}

		.title-name {
			width: 100%;
			padding: 40px 0 10px;
			font-size: 18px;
			text-align: center;
		}

		.title-brief {
			width: 100%;
			padding-bottom: 30px;
			font-size: 13px;
			color: #8C939D;
			text-align: center;
		}

		.login-form {
			width: 80%;
			margin: 0 auto 40px;
			box-sizing: border-box;
		}

		.el-button {
			width: 100%;
		}

	}
</style>
