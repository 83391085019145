export default {
	name: [{
			required: true,
			message: "请输入分类名称",
			trigger: "blur",
		},
		{
			min: 1,
			message: "长度请勿小于1个字符",
			trigger: "blur",
		},
		{
			max: 6,
			message: "长度请勿超过6个字符",
			trigger: "blur",
		},
	],
	image: [{
		required: true,
		message: "请上传分类图",
		trigger: "blur",
	}, ],
	is_hot: [{
		required: true,
		message: "请选择是否热门",
		trigger: "blur",
	}, ],
	is_index: [{
		required: true,
		message: "请选择是否首页显示",
		trigger: "blur",
	}, ],
	status: [{
		required: true,
		message: "请选择状态",
		trigger: "blur",
	}, ],
}
