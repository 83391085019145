<template>
	<div class="spec-table">
		<div class="table">
			<div class="tb-header u-flex">
				<div class="li li-15">产品名称</div>
				<div class="li li-15">产品封面图</div>
				<div class="li li-10">原价</div>
				<div class="li li-10">售价</div>
				<div class="li li-10">库存</div>
				<div class="li li-10">虚拟销量</div>
				<div class="li li-10">实际销量</div>
				<div class="li li-20">新价格</div>
			</div>
			<div class="td" v-if="specList.length > 0" v-for="(item,index) in specList">
				<div class="spec-data u-flex">
					<div class="li li-15">{{item.spec_name}}</div>
					<div class="li li-15 u-flex-center">
						<el-image class="detail-image" :src="item.base_spec_image"></el-image>
					</div>
					<div class="li li-10">￥{{item.original_price}}</div>
					<div class="li li-10">￥{{item.price}}</div>
					<div class="li li-10">￥{{item.stock}}</div>
					<div class="li li-10">{{item.fictitious_sold}}</div>
					<div class="li li-10">{{item.sold}}</div>
					<div class="li li-20">
						<el-input v-model="item.new_price" type="number" step="0.01" min="0.01"
							oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
							:max="(item.price - 0.01)" size="small" placeholder="新价格" @input="input($event,index)">
						</el-input>
					</div>
				</div>
			</div>
			<div class="form-tip">提示：新价格请勿超过当前售价，无需修改的规格请为空</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		props: {
			// 可选数量
			specList: {
				type: Array,
				default: [],
			},
		},
		created() {},
		methods: {
			input(e, index) {
				var curr = this.specList[index];
				if (parseFloat(e) > curr.price) { // 新价格大于就价格
					this.specList[index].new_price = (curr.price - 0.01);
					this.$errorMsg('新价格不能大于或等于旧价格');
				}
				this.$emit('input', {
					specList: this.specList,
					value: e,
					index: index
				})
			}
		},
	};
</script>
<style lang="scss">
	.spec-table {
		.table {
			width: 100%;
			border: 1px solid #f0f0f0;

			.tb-header {
				width: 100%;
				height: 40px;
				font-weight: bold;
				font-size: 12px;

				.li {
					text-align: center;
					height: 100%;
				}
			}

			.td {
				width: 100%;
				font-size: 12px;
				padding: 20px 0;
				border-bottom: 1px solid #f6f6f6;

				.li {
					box-sizing: border-box;
					line-height: 60px;
					text-align: center;
					height: 100%;
				}
			}

			.td:last-child {
				border: none;
			}

			.li-10 {
				padding: 0 10px;
				width: 10%;
			}

			.li-15 {
				padding: 0 10px;
				width: 15%;
			}

			.li-20 {
				padding: 0 20px;
				width: 20%;
			}
		}
	}
</style>
