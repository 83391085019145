<template>
	<div class="table-list">
		<!-- 列表信息 -->
		<el-table :data="list" stripe class="el-table-elem" v-loading="loading" element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)"
			tooltip-effect="dark" :height="tableHeight" @selection-change="selectionChange">
			<el-table-column type="selection" min-width="180"></el-table-column>
			<el-table-column prop="id" label="ID" min-width="80" align="center" sortable>
			</el-table-column>
			<el-table-column prop="order_number" label="订单号" align="center" min-width="200">
			</el-table-column>
			<el-table-column prop="user.nickname" label="用户昵称" align="center" min-width="120">
			</el-table-column>
			<el-table-column prop="money" label="订单金额" align="center" min-width="120">
				<template slot-scope="scope">￥{{scope.row.money}}</template>
			</el-table-column>
			<el-table-column prop="status_text" label="状态" align="center" min-width="120">
				<template slot-scope="scope">
					<el-tag size="mini"
						:type="scope.row.status == 0?'info':(scope.row.status == 2?'success':'danger')">
						{{scope.row.status_text}}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间" align="center" min-width="180">
			</el-table-column>
			<el-table-column label="操作" align="center" min-width="250">
				<template slot-scope="scope">
					<el-button type="success" size="mini" @click="$emit('examine', scope.row)" v-if="scope.row.status == 1">审核
					</el-button>
					<el-button type="primary" size="mini" @click="$emit('detail', scope.row)">详情</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="$emit('delete',scope.row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<pagination ref="pagination" :page="page.page" :pageSize="page.pageSize" :total="total"
			@pagination="pagination" />
	</div>
</template>

<script>
	import {
		multiChange,
	} from "@/api/orderDebt.js";
	export default {
		name: "table-list",
		data() {
			return {
				selectIds: [], // 已选择的id
				page: {
					page: 1,
					pageSize: 10,
				},
			}
		},
		// 定义传入的参数
		props: {
			loading: {
				required: true,
				type: Boolean,
				default: false,
			},
			list: {
				required: true,
				type: Array,
			},
			total: {
				required: true,
				type: Number,
			},
			tableHeight: {
				required: true,
				type: Number,
			}
		},
		mounted() {},
		methods: {
			/**
			 * @desc 列表选择
			 */
			selectionChange(e) {
				this.selectIds = [];
				if (e.length != 0) {
					// 获取选中的id
					e.forEach((item, index) => {
						this.selectIds.push(item.id)
					})
				}
				this.$emit('selectionChange', this.selectIds)
			},
			/**
			 * @desc 分类
			 */
			pagination(e) {
				this.$emit('pagination', e)
			},
			/**
			 * @desc 重置刷新列表分页数据
			 */
			refreshPagination() {
				this.$refs.pagination.currentPage = 1;
			},
		}
	}
</script>

<style lang="scss">
	.table-tag {
		margin: 5px 5px 0 0;
	}
</style>
