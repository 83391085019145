import {
	list as userList,
} from "@/api/user.js";
import {
	send
} from "@/api/productCoupon.js";
import formRule from "./formRule.js";
export default {
	name: 'send',
	data() {
		return {
			dialogShow: false, // 显示弹框
			userList: [], // 用户列表
			height: '', // 弹框内容高度
			// 提交参数
			params: {
				coupon_id: '',
				user_ids: '',
			},
			user_ids: [],
			formRules: {
				user_ids: [{
					required: true,
					message: "请选择用户",
					trigger: "blur",
				}],
			}, // 数据验证
			is_submit: false,
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {
		this.height = window.innerHeight - 240;
	},
	methods: {
		/**
		 * @desc 开启弹框
		 * @param Number type 类型 0-添加 1-编辑
		 * @param Number id 当前分类id
		 */
		open(id = 0) {
			this.params.coupon_id = id;
			this.getUserList();
			this.dialogShow = true;
		},
		/**
		 * @desc 获取专区列表
		 */
		getUserList() {
			userList().then(res => {
				this.userList = res.data.list;
			})
		},
		// 产品选择
		userChange(e) {
			// 获取产品选择
			this.params.user_ids = this.user_ids.toString(',');
		},
		/**
		 * @desc 关闭弹框
		 */
		close() {
			// 提交信息清空
			this.params = {
				coupon_id: '',
				user_ids: '',
			}
			this.user_ids = [];
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false;
		},
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				this.is_submit = true;
				send(this.params).then((res) => {
					this.$successMsg(res.msg);
					this.dialogShow = false;
					this.is_submit = false;
					// 触发父组件列表方法
					this.$emit('sendComplete')
				}).catch(() => {
					this.is_submit = false;
				});
			})
		},
	}
}
