<template>
	<el-dialog title="文件上传" :visible.sync="dialogImportVisible" top="4vh" :append-to-body="true"
		:before-close="dialogClose">
		<div class="category">
			<el-form ref="formRef" :model="formParams" :label-width="$isMobilePhone()?'':'80px'">
				<el-form-item label="附件分类">
					<el-cascader placeholder="请选择附件分类" size="small" :options="categoryList"
						v-model="formParams.category_id"
						:props="{checkStrictly: true,value: 'id',label: 'name',emitPath:false,expandTrigger: 'hover'}"
						filterable clearable :style="{width: '60%'}"></el-cascader>
					<div class="form-tip">提示：上传至当前分类</div>
				</el-form-item>
				<el-form-item label="附件名称">
					<el-input v-model="formParams.file_name" size="small" placeholder="请输入附件名称" :style="{width: '60%'}">
					</el-input>
					<div class="form-tip">提示：请勿超过20位</div>
				</el-form-item>
				<el-form-item label="文件">
					<div class="upload-content">
						<el-upload ref="upload" :action="uploadAction" list-type="picture-card" :headers="headers"
							multiple :data="formParams" :before-upload="beforeUpload" :on-success="onSuccess"
							:on-error="onError">
							<i slot="default" class="el-icon-plus"></i>
							<div slot="file" slot-scope="{file}">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt=""
									v-if="file.file_type == 'image'">
								<img class="el-upload-list__item-thumbnail" :src="$getSystemConfig('upload_doc_img')"
									alt="" v-if="file.file_type == 'application'">
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview"
										@click="$refs.imagePreview.show(0,[file.response.data.base_file_url])"
										v-if="file.file_type == 'image'">
										<i class="el-icon-zoom-in"></i>
									</span>
									<a class="a-label" :href="file.response.data.base_file_url"
										v-if="file.file_type == 'application'">在新标签页下载</a>
								</span>
								<div class="percentage">
									<el-progress :text-inside="true" :stroke-width="15"
										:percentage="parseFloat(file.percentage.toFixed(2))"
										:color="progressCustomColor">
									</el-progress>
								</div>
							</div>
						</el-upload>
					</div>
					<div class="form-tip">图片：只能上传jepg/jpg/bmp/gif/png文件，且不超过1M</div>
					<div class="form-tip">文档：只能上传docx/doc/pdf文件，且不超过20M</div>
				</el-form-item>
			</el-form>
		</div>
		<!-- 		<div class="footer">
			<div slot="tip" class="el-upload__tip">图片：只能上传jepg/jpg/bmp/gif/png文件，且不超过1M</div>
			<div slot="tip" class="el-upload__tip">文档：只能上传docx/doc/pdf文件，且不超过20M</div>
		</div> -->
		<imagePreview ref="imagePreview"></imagePreview>
	</el-dialog>
</template>
<script>
	import {
		list as categoryList,
	} from "@/api/attachmentCategory.js";
	import {
		upload,
	} from "@/api/attachment.js";
	export default {
		data() {
			return {
				uploadAction: 'https://api.ydyxw.cdydsy.com/admin/attachment/upload',
				dialogImportVisible: false,
				fileList: [],
				isUpload: false,
				dialogVisible: false,
				disabled: false,
				dialogImageUrl: '',
				progressCustomColor: [{
						color: '#f56c6c',
						percentage: 25
					},
					{
						color: '#e6a23c',
						percentage: 50
					},
					{
						color: '#1989fa',
						percentage: 75
					},
					{
						color: '#5cb87a',
						percentage: 100
					},
				],
				formParams: {
					category_id: '',
					file_name: '',
				},
				categoryList: [],
			};
		},
		props: {
			number: {
				type: Number,
				default: 10,
			},
		},
		created() {
			this.headers = {
				Authorization: this.$getStorage("token"),
			};
		},
		mounted() {},
		methods: {
			show() {
				this.getCategoryList();
				this.isUpload = false;
				this.dialogImportVisible = true;
			},
			/**
			 * @desc 获取分类列表
			 */
			getCategoryList() {
				categoryList().then(res => {
					this.categoryList = res.data.list;
				})
			},
			beforeUpload(file) {},
			onSuccess(file, e, fileArr) {
				fileArr.forEach((item, index) => {
					item.file_type = item.raw.type.split('/')[0]
				})
				this.isUpload = true;
			},
			onError(e, a, j) {
				this.$errorMsg('文件格式或大小不正确，请重试');
				// console.log(JSON.stringify(e))
			},
			// 弹框关闭
			dialogClose(e) {
				this.$refs.upload.clearFiles();
				this.dialogImportVisible = false;
				if (this.isUpload) {
					this.$emit('close')
				}
			},
			imagePreviewClose() {
				this.dialogVisible = false;
			}
		},
	};
</script>
<style lang="scss" scoped>
	.el-upload-dragger {}

	.el-progress {
		top: 92%;
		bottom: auto;
		width: 126px;
	}

	.upload-content {
		overflow: hidden;
		height: 500px;
		overflow-y: scroll;
	}

	.footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.progress1 {
		display: none;
	}

	.progress {
		display: flex;
		width: 80px;
		height: 80px;
		position: absolute;
		top: 40px;
		left: 50%;
		transform: translate(-50%, 0);
		background-color: transparent;
	}

	.a-label {
		font-size: 13px;
		color: #FFFFFF;
	}
</style>
