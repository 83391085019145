<template>
	<div class="save-data">
		<el-dialog title="审核" :visible.sync="dialogShow" :fullscreen="$isMobilePhone()?true:false"
			:width="$isMobilePhone()?'100%':'50%'" :top="$isMobilePhone()?'':'2vh'" @close="close" class="data-dialog">
			<el-form ref="formRef" :model="params" :rules="formRules" :label-width="$isMobilePhone()?'':'120px'">
				<el-form-item label="审核结果" prop="status">
					<el-radio v-model="params.status" label="1">通过</el-radio>
					<el-radio v-model="params.status" label="2">不通过</el-radio>
				</el-form-item>
				<el-form-item label="反馈信息" :prop="params.status == 2?'examine_result':''">
					<el-input v-model="params.examine_result" type="textarea" size="small" maxlength="200"
						placeholder="请输入反馈信息">
					</el-input>
					<!-- <div class="form-tip">提示：长度5~16位</div> -->
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="close">取 消</el-button>
				<el-button type="primary" size="small" :loading="is_submit" @click="submit">提 交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		examine,
	} from "@/api/userRealAuthApply.js";
	export default {
		name: 'save-data',
		data() {
			return {
				// 显示弹框
				dialogShow: false,
				// 提交参数
				params: {
					id: 0,
					status: '1',
					examine_result: '',
				},
				is_submit: false,
				// 数据验证
				formRules: {
					status: [{
						required: true,
						message: '请选择审核结果',
						trigger: 'blur'
					}, ],
					examine_result: [{
						required: true,
						message: '请输入反馈信息',
						trigger: 'blur'
					}, ],
				},
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param Number id 当前申请id
			 */
			open(id) {
				console.log(id)
				this.params.id = id;
				// 显示弹框
				this.dialogShow = true;
			},
			/**
			 * @desc 关闭弹框
			 */
			close() {
				// 提交信息清空
				this.params = {
					id: 0,
					status: '1',
					examine_result: '',
				}
				// 去除表单验证、重置数据
				this.$refs.formRef.resetFields()
				// 关闭弹框
				this.dialogShow = false;
			},
			/**
			 * @desc 提交信息
			 */
			submit() {
				var that = this;
				// 验证表单
				that.$refs.formRef.validate(valid => {
					if (!valid) {
						return false;
					}
					this.is_submit = true;
					examine(this.params).then((res) => {
						this.$successMsg(res.msg);
						this.is_submit = false;
						this.dialogShow = false;
						// 触发父组件列表方法
						this.$emit('complete')
					}).catch(() => {
						this.is_submit = false;
					});
				})
			},
		}
	}
</script>
