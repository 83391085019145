import {
	multiChange,
} from "@/api/activityProduct.js";
export default {
	name: "list",
	data() {
		return {
			selectIds: [], // 已选择的id
			page: {
				page: 1,
				pageSize: 10,
			},
		}
	},
	// 定义传入的参数
	props: {
		loading: {
			required: true,
			type: Boolean,
			default: false,
		},
		list: {
			required: true,
			type: Array,
		},
		total: {
			required: true,
			type: Number,
		},
		tableHeight: {
			required: true,
			type: Number,
		}
	},
	mounted() {},
	methods: {
		/**
		 * @desc 列表选择
		 */
		selectionChange(e) {
			this.selectIds = [];
			if (e.length != 0) {
				// 获取选中的id
				e.forEach((item, index) => {
					this.selectIds.push(item.id)
				})
			}
			this.$emit('selectionChange', this.selectIds)
		},
		/**
		 * @desc 编辑获取数据
		 */
		edit(e) {
			this.$emit('edit', e)
		},
		/**
		 * @desc 删除数据
		 */
		del(e) {
			this.$emit('delete', e)
		},
		/**
		 * @desc 状态切换
		 */
		multiChange(name, rows) {
			var params = {
				id: rows.id,
				[name]: rows[name]
			}
			console.log(params)
			multiChange(params).then((res) => {
				this.$successMsg(res.msg);
			}).catch((ex) => {
				// 切换失败，重置信息
				rows.status = rows.status == 1 ? "0" : "1";
			});
		},
		/**
		 * @desc 分类
		 */
		pagination(e) {
			this.$emit('pagination', e)
		},
		/**
		 * @desc 重置刷新列表分页数据
		 */
		refreshPagination() {
			this.$refs.pagination.currentPage = 1;
		},
	}
}
