import axios from 'axios'
// import {
//   Message
// } from 'element-ui'
// import loading from "@/utils/loading.js";
import {
	getStorage,
	removeStorage
} from '@/utils/storage.js'
import {
	errorMsg,
} from '@/utils/message.js'

const service = axios.create({
	baseURL: 'https://api.ydyxw.cdydsy.com/admin/',
	timeout: 1000000
})

// 添加请求拦截器
service.interceptors.request.use(config => {
	// 获取并设置token
	config.headers['Authorization'] = getStorage('token')
	// config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
	// config.headers['Accept-Language'] = 'zh-CN'
	return config
}, (error) => {
	return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
	return response.data
}, (errorRes) => {
	var res = errorRes.response.data
	errorMsg(res.msg || '系统异常，请稍后重试');
	if (errorRes.response.data.code == 503) {
		removeStorage('token');
		setTimeout(() => {
			window.location.replace("/login")
		}, 1500);
	} else {
		return Promise.reject(errorRes)
	}
})

export default service
