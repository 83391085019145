<template>
	<div class="table-list">
		<!-- 列表信息 -->
		<el-table :data="list" stripe class="el-table-elem" v-loading="loading" element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)"
			tooltip-effect="dark" :height="tableHeight">
			<el-table-column prop="id" label="ID" min-width="80" sortable>
			</el-table-column>
			<el-table-column prop="explain" label="描述" min-width="120" align="center">
			</el-table-column>
			<el-table-column prop="receiving_method_text" label="领取方式" max-width="120" align="center">
			</el-table-column>
			<el-table-column prop="use_range_text" label="使用范围" max-width="140" align="center">
			</el-table-column>
			<el-table-column prop="discount_method_text" label="优惠方式" max-width="140" align="center">
			</el-table-column>
			<el-table-column prop="is_time_limit_text" label="是否现时" max-width="80" align="center">
				<template slot-scope="scope">
					<el-tag :type="scope.row.is_time_limit==0?'danger':''" effect="light">
						{{scope.row.is_time_limit_text}}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="is_number_limit" label="是否限量" max-width="80" align="center">
				<template slot-scope="scope">
					<el-tag :type="scope.row.is_number_limit==0?'danger':''" effect="light">
						{{scope.row.is_number_limit_text}}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="sort" label="排序" min-width="80" align="center" sortable>
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间" min-width="160" align="center">
			</el-table-column>
			<el-table-column label="状态" min-width="80">
				<template slot-scope="scope">
					<el-switch :active-value="'1'" :inactive-value="'0'" v-model="scope.row.status"
						@change="multiChange('status', scope.row)">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column label="操作" min-width="150" fixed="right">
				<template slot-scope="scope">
					<el-button type="success" icon="el-icon-position" size="mini" v-if="scope.row.receiving_method == 3"
						@click="send(scope.row)">
					</el-button>
					<el-button type="primary" icon="el-icon-edit" size="mini" @click="edit(scope.row)">
					</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="del(scope.row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<pagination ref="pagination" :page="page.page" :pageSize="page.pageSize" :total="total"
			@pagination="pagination" />
	</div>
</template>

<script>
	import {
		multiChange
	} from "@/api/productCoupon.js";
	export default {
		name: "table-list",
		data() {
			return {
				selectIds: [], // 已选择的id
				page: {
					page: 1,
					pageSize: 10,
				},
			};
		},
		// 定义传入的参数
		props: {
			loading: {
				required: true,
				type: Boolean,
				default: false,
			},
			list: {
				required: true,
				type: Array,
			},
			total: {
				required: true,
				type: Number,
			},
			tableHeight: {
				required: true,
				type: Number,
			},
		},
		mounted() {},
		methods: {
			/**
			 * @desc 列表选择
			 */
			selectionChange(e) {
				this.selectIds = [];
				if (e.length != 0) {
					// 获取选中的id
					e.forEach((item, index) => {
						this.selectIds.push(item.id);
					});
				}
				this.$emit("selectionChange", this.selectIds);
			},
			/**
			 * @desc 编辑获取数据
			 */
			edit(e) {
				this.$emit("edit", e);
			},
			send(e) {
				this.$emit("send", e);
			},
			/**
			 * @desc 删除数据
			 */
			del(e) {
				this.$emit("delete", e);
			},
			/**
			 * @desc 状态切换
			 */
			multiChange(name, rows) {
				var params = {
					id: rows.id,
					[name]: rows[name],
				};
				multiChange(params)
					.then((res) => {
						this.$successMsg(res.msg);
					})
					.catch((ex) => {
						// 切换失败，重置信息
						rows.status = rows.status == 1 ? "0" : "1";
					});
			},
			/**
			 * @desc 分页
			 */
			pagination(e) {
				this.$emit("pagination", e);
			},
			/**
			 * @desc 重置刷新列表分页数据
			 */
			refreshPagination() {
				this.$refs.pagination.currentPage = 1;
			},
		},
	};
</script>

<style lang="scss">
	.table-tag {
		margin: 5px 5px 0 0;
	}
</style>
