import service from "@/utils/service.js";

// 订单列表
export function list(params) {
	return service({
		method: 'get',
		url: 'order/index',
		params: params
	})
}

// 角色列表
export function logisticsCompanyList(params) {
	return service({
		method: 'get',
		url: 'order/logisticsCompanyList',
		params: params
	})
}


// 订单详情
export function getSend(params) {
	return service({
		method: 'get',
		url: 'order/send',
		params: params
	})
}


// 订单删除
export function send(data) {
	return service({
		method: 'post',
		url: 'order/send',
		data
	})
}


// 订单删除
export function examine(data) {
	return service({
		method: 'post',
		url: 'order/examine',
		data
	})
}

// 订单详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'order/detail',
		params: params
	})
}


// 订单删除
export function del(data) {
	return service({
		method: 'post',
		url: 'order/del',
		data
	})
}
