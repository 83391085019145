<template>
	<div class="">
		<el-dialog title="订单详情" :visible.sync="dialogShow" :fullscreen="$isMobilePhone()?true:false"
			:width="$isMobilePhone()?'100%':'80%'" :top="$isMobilePhone()?'':'2vh'" @close="dialogShow = false">
			<div class="detail-main">
				<div class="detail-content">
					<div class="detail-title">收货信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">下单用户：</div>
							<div class="li-data">{{row.user.nickname}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">下单用户企业：</div>
							<div class="li-data">{{row.enterprise.enterprise_name}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">收货人：</div>
							<div class="li-data">{{row.receiver_name}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">收货人手机号：</div>
							<div class="li-data">{{row.receiver_mobile}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">收货地址：</div>
							<div class="li-data">
								{{row.receiver_province}}{{row.receiver_city}}{{row.receiver_area}}{{row.receiver_address}}
							</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">用户备注：</div>
							<div class="li-data">
								{{row.user_remarks?row.user_remarks:'无'}}
							</div>
						</div>
					</div>
				</div>
				<div class="detail-content" v-if="row.express_no">
					<div class="detail-title">物流信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">物流公司：</div>
							<div class="li-data">{{row.expresscompany.name}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">运单号：</div>
							<div class="li-data">{{row.express_no}}</div>
						</div>
					</div>
				</div>
				<div class="detail-content">
					<div class="detail-title">订单信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">订单号：</div>
							<div class="li-data">{{row.order_number}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">订单类型：</div>
							<div class="li-data">
								<el-tag size="mini">{{row.order_type_text}}</el-tag>
							</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">支付方式：</div>
							<div class="li-data">
								<el-tag size="mini">{{row.pay_type_text}}</el-tag>
							</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">订单总金额：</div>
							<div class="li-data">￥{{row.total_money}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">优惠券金额：</div>
							<div class="li-data">￥{{row.coupon_reduce}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">达量优惠金额：</div>
							<div class="li-data">￥{{row.number_reduce}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">需支付金额：</div>
							<div class="li-data">￥{{row.need_money}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">已支付金额：</div>
							<div class="li-data">￥{{row.pay_money}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">获得积分：</div>
							<div class="li-data">{{row.get_integral}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">订单状态：</div>
							<div class="li-data">
								<el-tag size="mini">{{row.status_text}}</el-tag>
							</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">创建时间：</div>
							<div class="li-data">{{row.create_time}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.pay_time">
							<div class="li-label">支付时间：</div>
							<div class="li-data">{{row.pay_time_text}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.send_time">
							<div class="li-label">发货时间：</div>
							<div class="li-data">{{row.send_time_text}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.cancel_time">
							<div class="li-label">取消时间：</div>
							<div class="li-data">{{row.cancel_time_text}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.receipt_time">
							<div class="li-label">收货时间：</div>
							<div class="li-data">{{row.receipt_time_text}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.receipt_time">
							<div class="li-label">收货方式：</div>
							<div class="li-data">
								<el-tag size="mini">{{row.receipt_type_text}}</el-tag>
							</div>
						</div>
						<div class="li-item u-flex" v-if="row.complete_time">
							<div class="li-label">完成时间：</div>
							<div class="li-data">{{row.complete_time_text}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.comment_time">
							<div class="li-label">评论时间：</div>
							<div class="li-data">{{row.comment_time_text}}</div>
						</div>
					</div>
				</div>
				<div class="detail-content" v-if="row.pay_type == 6">
					<div class="detail-title">对公支付信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">状态：</div>
							<div class="li-data">{{row.corporate_status_text}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">提交时间：</div>
							<div class="li-data">{{row.corporate_submit_time_text}}</div>
						</div>
						<div class="li-item u-flex">
							<div class="li-label">支付凭证：</div>
							<div class="li-data u-flex">
								<el-image class="detail-image" :preview-src-list="row.base_corporate_images"
									v-for="(item,index) in row.base_corporate_images" :src="item">
								</el-image>
							</div>
						</div>
						<div class="li-item u-flex" v-if="row.corporate_status > 1">
							<div class="li-label">审核时间：</div>
							<div class="li-data">{{row.corporate_examine_time_text}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.corporate_status > 1">
							<div class="li-label">审核反馈：</div>
							<div class="li-data">{{row.corporate_examine_result?row.corporate_examine_result:'无'}}</div>
						</div>
					</div>
				</div>
				<div class="detail-content" v-if="row.pay_type == 7">
					<div class="detail-title">欠款支付信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">状态：</div>
							<div class="li-data">{{row.debt_status_text}}</div>
						</div>
					</div>
				</div>
				<div class="detail-content">
					<div class="detail-title">商品信息</div>
					<product ref="productList" :productList="row.product"></product>
				</div>
				<!-- 				<div class="detail-content" v-if="row.parentuser">
					<div class="detail-title">佣金信息</div>
					<div class="detail-li u-flex u-flex-wrap">
						<div class="li-item u-flex">
							<div class="li-label">推荐人：</div>
							<div class="li-data">{{row.parentuser.nickname}}</div>
						</div>
						<div class="li-item u-flex" v-if="row.comment_time">
							<div class="li-label">获得佣金：</div>
							<div class="li-data">{{row.commission_money}}</div>
						</div>
					</div>
				</div> -->
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import product from './product/product.vue';
	export default {
		components: {
			product,
		},
		name: 'detail',
		data() {
			return {
				// 显示弹框
				dialogShow: false,
				row: {
					user: {
						nickname: '',
					},
					product: [],
					parentuser: {
						nickname: ''
					},
					expresscompany: {
						name: ''
					},
					enterprise: {},
				},
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param e 订单信息
			 */
			open(e) {
				this.row = e.row
				// 显示弹框
				this.dialogShow = true;
			},
		}
	}
</script>

<style lang="scss">
	.detail-main {
		width: 96%;
		margin: 0 auto;
		font-size: 12px;

		.detail-content {
			padding-top: 20px;
			width: 100%;

			.detail-title {
				font-size: 13px;
				font-weight: bold;
				padding-bottom: 10px;
			}

			.detail-li {

				.li-item {
					width: 50%;
					padding: 10px 0;

					.li-label {
						width: 100px;
						text-align: right;
					}

					.li-data {
						padding-left: 10px;
					}
				}
			}
		}
	}
</style>
