import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/index/Login.vue'
import Index from '../views/index/Index.vue'
import Welcome from '../views/index/Welcome.vue'
import Admin from '../views/admin/Index.vue'
import Role from '../views/role/Index.vue'
import Power from '../views/power/Index.vue'
import ProductRegion from '../views/productRegion/Index.vue'
import ProductCategory from '../views/productCategory/Index.vue'
import ProductBrand from '../views/productBrand/Index.vue'
import Product from '../views/product/Index.vue'
import ProductManufacturer from '../views/productManufacturer/Index.vue'
import integralProduct from '@/views/integralProduct/Index.vue'
import news from '@/views/news/Index.vue'
import newsComment from '@/views/newsComment/Index.vue'
import enterpriseType from '@/views/enterpriseType/Index.vue'
import enterpriseAuthApply from '@/views/enterpriseAuthApply/Index.vue'
import enterpriseAuth from '@/views/enterpriseAuth/Index.vue'
import Activity from '@/views/activity/Index.vue'
import ActivityProduct from '@/views/activityProduct/Index.vue'
import NearEffectArea from '@/views/nearEffectArea/Index.vue'
import NearEffectAreaProduct from '@/views/nearEffectAreaProduct/Index.vue'
import user from '@/views/user/Index.vue'
import Banner from '@/views/banner/Index.vue'
import productCoupon from '@/views/productCoupon/Index.vue'
import order from '@/views/order/Index.vue'
import orderRefund from '@/views/orderRefund/Index.vue'
import systemConfig from '@/views/systemConfig/Index.vue'
import userRealAuthApply from '@/views/userRealAuthApply/Index.vue'
import userBalanceRecharge from '@/views/userBalanceRecharge/Index.vue'
import userCommissionBill from '@/views/userCommissionBill/Index.vue'
import userIntegralBill from '@/views/userIntegralBill/Index.vue'
import userGiveBalanceBill from '@/views/userGiveBalanceBill/Index.vue'
import userWithdrawal from '@/views/userWithdrawal/Index.vue'
import orderInvoice from '@/views/orderInvoice/Index.vue'
import orderDebt from '@/views/orderDebt/Index.vue'
import orderProduct from '@/views/orderProduct/Index.vue'
import attachmentCategory from '@/views/attachmentCategory/Index.vue'
import attachment from '@/views/attachment/Index.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/login',
		component: Login,
		meta: {
			title: '登录 - 医大医大药械网后台管理系统',
		}
	},
	{
		path: '/',
		component: Index,
		redirect: '/welcome',
		children: [{
				path: '/welcome',
				component: Welcome,
				meta: {
					title: '医大医大药械网后台管理系统',
				}
			},
			{
				path: '/admin',
				component: Admin,
				meta: {
					title: '账户列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/role',
				component: Role,
				meta: {
					title: '角色列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/power',
				component: Power,
				meta: {
					title: '角色列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/productRegion',
				component: ProductRegion,
				meta: {
					title: '专区列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/productCategory',
				component: ProductCategory,
				meta: {
					title: '分类列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/productBrand',
				component: ProductBrand,
				meta: {
					title: '品牌列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/product',
				component: Product,
				meta: {
					title: '产品列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/productManufacturer',
				component: ProductManufacturer,
				meta: {
					title: '产品厂商列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/order',
				component: order,
				meta: {
					title: '订单列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/orderRefund',
				component: orderRefund,
				meta: {
					title: '订单列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/integralProduct',
				component: integralProduct,
				meta: {
					title: '积分商品列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/news',
				component: news,
				meta: {
					title: '新闻列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/newsComment',
				component: newsComment,
				meta: {
					title: '评论列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/enterpriseType',
				component: enterpriseType,
				meta: {
					title: '企业类型 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/enterpriseAuthApply',
				component: enterpriseAuthApply,
				meta: {
					title: '认证列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/enterpriseAuth',
				component: enterpriseAuth,
				meta: {
					title: '企业认证申请 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/user',
				component: user,
				meta: {
					title: '用户列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/productCoupon',
				component: productCoupon,
				meta: {
					title: '优惠卷列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/activity',
				component: Activity,
				meta: {
					title: '活动列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/activityProduct',
				component: ActivityProduct,
				meta: {
					title: '活动产品列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/nearEffectArea',
				component: NearEffectArea,
				meta: {
					title: '近效区列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/nearEffectAreaProduct',
				component: NearEffectAreaProduct,
				meta: {
					title: '近效区产品列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/banner',
				component: Banner,
				meta: {
					title: '轮播图列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/systemConfig',
				component: systemConfig,
				meta: {
					title: '系统配置 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/userRealAuthApply',
				component: userRealAuthApply,
				meta: {
					title: '实名认证 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/userBalanceRecharge',
				component: userBalanceRecharge,
				meta: {
					title: '充值记录 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/userCommissionBill',
				component: userCommissionBill,
				meta: {
					title: '佣金记录 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/userIntegralBill',
				component: userIntegralBill,
				meta: {
					title: '积分记录 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/userGiveBalanceBill',
				component: userGiveBalanceBill,
				meta: {
					title: '赠送余额记录 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/userWithdrawal',
				component: userWithdrawal,
				meta: {
					title: '提现列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/orderInvoice',
				component: orderInvoice,
				meta: {
					title: '发票列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/orderDebt',
				component: orderDebt,
				meta: {
					title: '欠款列表 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/orderProduct',
				component: orderProduct,
				meta: {
					title: '订单产品 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/attachmentCategory',
				component: attachmentCategory,
				meta: {
					title: '附件分类 - 医大药械网后台管理系统',
				}
			},
			{
				path: '/attachment',
				component: attachment,
				meta: {
					title: '附件管理 - 医大药械网后台管理系统',
				}
			},
		],
	}
]

const router = new VueRouter({
	routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
	// to将要访问的路径
	// from 代表从哪个路径跳转而来
	// next s是一个函数，表示放行
	//      next() 放行
	// 获取token
	const tokenStr = window.localStorage.getItem('token')
	if (tokenStr) { // 已登陆
		// 如果是跳转到登录页
		if (to.path === '/login') {
			// 进入首页
			return next('/');
		}
		return next();
	} else { // 未登录
		// 如果是进入登录页，不这样判断会报错
		if (to.path === '/login') {
			return next();
		}
		return next('/login')
	}
	// if (to.path === '/login') return next();
	// // 获取token
	// const tokenStr = window.localStorage.getItem('token')
	// if (!tokenStr) return next('/login')
	// next();
})

export default router
