import service from "@/utils/service.js";

// 企业类型列表
export function list(params) {
	return service({
		method: 'get',
		url: 'enterpriseAuth/index',
		params: params
	})
}

// 企业类型详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'enterpriseAuth/detail',
		params: params
	})
}


// 企业类型删除
export function del(data) {
	return service({
		method: 'post',
		url: 'enterpriseAuth/del',
		data
	})
}
