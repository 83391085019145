<template>
	<div>
		<!-- 面包屑导航区 -->
		<tableBreadcrumb></tableBreadcrumb>
		<!-- 卡片区域 -->
		<el-card class="box-card" v-loading="loading" element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)">
			<div class="u-flex-end">
				<el-button type="primary" size="small" :loading="!isSubmit" @click="submit">保 存</el-button>
			</div>
			<el-form ref="formRef" :model="params" :rules="formRules" :label-width="$isMobilePhone() ? '' : '120px'">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="基础配置" name="basics">
						<el-form-item label="平台名称" prop="platform_name">
							<el-input v-model="params.platform_name" size="small" placeholder="请输入平台名称"></el-input>
							<div class="form-tip">提示：请勿超过10位</div>
						</el-form-item>
						<el-form-item label="平台LOGO" prop="platform_logo_img">
							<selectAttachmentBtn ref="platform_logo_imgSelectAttachmentBtn" fileType="image"
								paramName="platform_logo_img" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过50KB</div>
						</el-form-item>
						<el-form-item label="主页优惠券图片" prop="index_coupon_img">
							<selectAttachmentBtn ref="index_coupon_imgSelectAttachmentBtn" fileType="image"
								paramName="index_coupon_img" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过50KB</div>
						</el-form-item>
						<el-form-item label="主页积分商城图片" prop="index_integral_img">
							<selectAttachmentBtn ref="index_integral_imgSelectAttachmentBtn" fileType="image"
								paramName="index_integral_img" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过50KB</div>
						</el-form-item>
						<el-form-item label="主页电子发票图" prop="index_invoice_img">
							<selectAttachmentBtn ref="index_invoice_imgSelectAttachmentBtn" fileType="image"
								paramName="index_invoice_img" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过50KB</div>
						</el-form-item>
						<el-form-item label="主页质检中心图片" prop="index_quality_testing_img">
							<selectAttachmentBtn ref="index_quality_testing_imgSelectAttachmentBtn" fileType="image"
								paramName="index_quality_testing_img" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过50KB</div>
						</el-form-item>
						<el-form-item label="主页活动图片" prop="index_activity_img">
							<selectAttachmentBtn ref="index_activity_imgSelectAttachmentBtn" fileType="image"
								paramName="index_activity_img" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过50KB</div>
						</el-form-item>
						<el-form-item label="全部分类图片" prop="index_all_category_img">
							<selectAttachmentBtn ref="index_all_category_imgSelectAttachmentBtn" fileType="image"
								paramName="index_all_category_img" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过300KB</div>
						</el-form-item>
						<el-form-item label="默认头像图" prop="default_user_avatar_img">
							<selectAttachmentBtn ref="default_user_avatar_imgSelectAttachmentBtn" fileType="image"
								paramName="default_user_avatar_img" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过50KB</div>
						</el-form-item>
						<el-form-item label="分享H5二维码域名" prop="recommend_poster_img">
							<selectAttachmentBtn ref="recommend_poster_imgSelectAttachmentBtn" fileType="image"
								paramName="recommend_poster_img" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过50KB</div>
						</el-form-item>
						<el-form-item label="上传文档默认图标" prop="upload_doc_img">
							<selectAttachmentBtn ref="upload_doc_imgSelectAttachmentBtn" fileType="image"
								paramName="upload_doc_img" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过50KB</div>
						</el-form-item>
						<el-form-item label="热门搜索" prop="hot_search_tagsinput">
							<el-tag v-for="(item,index) in params.hot_search_tagsinput" :key="index" closable
								:disable-transitions="false" @close="tagClose('hot_search_tagsinput',index)">
								{{item}}
							</el-tag>
							<el-input class="input-new-tag" v-show="hot_search_tagsinput_visible"
								v-model="hot_search_tagsinput_value" ref="hot_search_tagsinput'" size="small" clearable
								@keyup.enter.native="tagsInputConfirm('hot_search_tagsinput')"
								@blur="tagsInputConfirm('hot_search_tagsinput')">
							</el-input>
							<el-button v-show="!hot_search_tagsinput_visible" class="button-new-tag" size="small"
								@click="showTagsInput('hot_search_tagsinput')">+ 添加
							</el-button>
						</el-form-item>
						<el-form-item label="产品标签" prop="product_label_tagsinput">
							<el-tag v-for="(item,index) in params.product_label_tagsinput" :key="index" closable
								:disable-transitions="false" @close="tagClose('product_label_tagsinput',index)">
								{{item}}
							</el-tag>
							<el-input class="input-new-tag" v-show="product_label_tagsinput_visible"
								v-model="product_label_tagsinput_value" ref="product_label_tagsinput'" size="small"
								clearable @keyup.enter.native="tagsInputConfirm('product_label_tagsinput')"
								@blur="tagsInputConfirm('product_label_tagsinput')">
							</el-input>
							<el-button v-show="!product_label_tagsinput_visible" class="button-new-tag" size="small"
								@click="showTagsInput('product_label_tagsinput')">+ 添加
							</el-button>
						</el-form-item>
						<el-form-item label="产品单位" prop="product_unit_tagsinput">
							<el-tag v-for="(item,index) in params.product_unit_tagsinput" :key="index" closable
								:disable-transitions="false" @close="tagClose('product_unit_tagsinput',index)">
								{{item}}
							</el-tag>
							<el-input class="input-new-tag" v-show="product_unit_tagsinput_visible"
								v-model="product_unit_tagsinput_value" ref="product_unit_tagsinput'" size="small"
								clearable @keyup.enter.native="tagsInputConfirm('product_unit_tagsinput')"
								@blur="tagsInputConfirm('product_unit_tagsinput')">
							</el-input>
							<el-button v-show="!product_unit_tagsinput_visible" class="button-new-tag" size="small"
								@click="showTagsInput('product_unit_tagsinput')">+ 添加
							</el-button>
						</el-form-item>
						<el-form-item label="热门榜单产品" prop="hot_product_array">
							<productSelect ref="hot_product_array" @operation="productSelectOperation">
							</productSelect>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="订单配置" name="order">
						<el-form-item label="支付截止时间" prop="order_pay_by_time">
							<el-input v-model="params.order_pay_by_time" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入支付截止时间">
							</el-input>
							<div class="form-tip">提示：单位：小时，可保留两位小数</div>
						</el-form-item>
						<el-form-item label="自动收货时间" prop="order_auto_receipt_day">
							<el-input v-model="params.order_auto_receipt_day" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入自动收货时间">
							</el-input>
							<div class="form-tip">提示：单位：天，可保留两位小数</div>
						</el-form-item>
						<el-form-item label="订单完成时间" prop="order_auto_complete_day">
							<el-input v-model="params.order_auto_complete_day" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入订单完成时间">
							</el-input>
							<div class="form-tip">提示：单位：天，可保留两位小数</div>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="支付配置" name="payment">
						<el-form-item label="赠送余额支付" prop="order_give_balance_payment">
							<el-radio v-model="params.order_give_balance_payment" label="1">开启</el-radio>
							<el-radio v-model="params.order_give_balance_payment" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可用</div>
						</el-form-item>
						<el-form-item label="余额支付" prop="order_balance_payment">
							<el-radio v-model="params.order_balance_payment" label="1">开启</el-radio>
							<el-radio v-model="params.order_balance_payment" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可用</div>
						</el-form-item>
						<el-form-item label="微信支付" prop="order_wechat_payment">
							<el-radio v-model="params.order_wechat_payment" label="1">开启</el-radio>
							<el-radio v-model="params.order_wechat_payment" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可用</div>
						</el-form-item>
						<el-form-item label="支付宝支付" prop="order_alipay_payment">
							<el-radio v-model="params.order_alipay_payment" label="1">开启</el-radio>
							<el-radio v-model="params.order_alipay_payment" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可用</div>
						</el-form-item>
						<el-form-item label="银行卡支付" prop="order_bankcard_payment">
							<el-radio v-model="params.order_bankcard_payment" label="1">开启</el-radio>
							<el-radio v-model="params.order_bankcard_payment" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可用</div>
						</el-form-item>
						<el-form-item label="积分支付" prop="order_integral_payment">
							<el-radio v-model="params.order_integral_payment" label="1">开启</el-radio>
							<el-radio v-model="params.order_integral_payment" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可用</div>
						</el-form-item>
						<el-form-item label="对公账户支付" prop="order_corporate_payment">
							<el-radio v-model="params.order_corporate_payment" label="1">开启</el-radio>
							<el-radio v-model="params.order_corporate_payment" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可用</div>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="充值配置" name="recharge">
						<el-form-item label="状态" prop="recharge_status">
							<el-radio v-model="params.recharge_status" label="1">开启</el-radio>
							<el-radio v-model="params.recharge_status" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可充值</div>
						</el-form-item>
						<el-form-item label="微信支付" prop="recharge_wechat_payment">
							<el-radio v-model="params.recharge_wechat_payment" label="1">开启</el-radio>
							<el-radio v-model="params.recharge_wechat_payment" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可用</div>
						</el-form-item>
						<el-form-item label="支付宝支付" prop="recharge_alipay_payment">
							<el-radio v-model="params.recharge_alipay_payment" label="1">开启</el-radio>
							<el-radio v-model="params.recharge_alipay_payment" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可用</div>
						</el-form-item>
						<el-form-item label="银行卡支付" prop="recharge_bankcard_payment">
							<el-radio v-model="params.recharge_bankcard_payment" label="1">开启</el-radio>
							<el-radio v-model="params.recharge_bankcard_payment" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，用户不可用</div>
						</el-form-item>
						<el-form-item label="最小金额" prop="recharge_min_money">
							<el-input v-model="params.recharge_min_money" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入最小金额">
							</el-input>
							<div class="form-tip">提示：用户单次最小充值金额，可保留两位小数</div>
						</el-form-item>
						<el-form-item label="最大金额" prop="recharge_max_money">
							<el-input v-model="params.recharge_max_money" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入最大金额">
							</el-input>
							<div class="form-tip">提示：用户单次最大充值金额，可保留两位小数</div>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="赠送金额配置" name="give_balance">
						<el-form-item label="注册赠送金额" prop="give_balance_user_register">
							<el-input v-model="params.give_balance_user_register" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入用户注册赠送金额">
							</el-input>
							<div class="form-tip">提示：用户注册赠送金额，可保留两位小数</div>
						</el-form-item>
						<el-form-item label="企业认证赠送金额" prop="give_balance_enterprise_auth">
							<el-input v-model="params.give_balance_enterprise_auth" type="number" min="0.01"
								max="100.00" step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入企业认证赠送金额">
							</el-input>
							<div class="form-tip">提示：企业认证赠送金额，可保留两位小数</div>
						</el-form-item>
						<el-form-item label="首充赠送金额" prop="give_balance_first_recharge">
							<el-input v-model="params.give_balance_first_recharge" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入首充余额赠送金额">
							</el-input>
							<div class="form-tip">提示：首充赠送金额，可保留两位小数</div>
						</el-form-item>
						<el-form-item label="充值赠送金额" prop="give_balance_end_recharge">
							<el-input v-model="params.give_balance_end_recharge" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入余额充值赠送金额">
							</el-input>
							<div class="form-tip">提示：第二次之后余额充值赠送金额，可保留两位小数</div>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="对公账户配置" name="corporate">
						<el-form-item label="银行名称" prop="corporate_bank_name">
							<el-input v-model="params.corporate_bank_name" size="small" placeholder="请输入银行名称">
							</el-input>
						</el-form-item>
						<el-form-item label="开户行名称" prop="corporate_bank_deposit">
							<el-input v-model="params.corporate_bank_deposit" size="small" placeholder="请输入开户行名称">
							</el-input>
						</el-form-item>
						<el-form-item label="户主名称" prop="corporate_account_name">
							<el-input v-model="params.corporate_account_name" size="small" placeholder="请输入户主名称">
							</el-input>
						</el-form-item>
						<el-form-item label="银行卡号" prop="corporate_account_no">
							<el-input v-model="params.corporate_account_no" size="small" type="number"
								placeholder="请输入银行卡号">
							</el-input>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="分销配置" name="distribution">
						<el-form-item label="一级分佣比率" prop="first_commission_rate">
							<el-input v-model="params.first_commission_rate" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入一级分佣比率">
							</el-input>
							<div class="form-tip">提示：上级用户获得佣金比率，单位：%</div>
						</el-form-item>
						<el-form-item label="二级分佣比率" prop="two_commission_rate">
							<el-input v-model="params.two_commission_rate" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入二级分佣比率">
							</el-input>
							<div class="form-tip">提示：上上级用户获得佣金比率，单位：%</div>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="提现配置" name="withdrawal">
						<el-form-item label="提现服务费率" prop="is_withdrawal">
							<el-radio v-model="params.is_withdrawal" label="1">开启</el-radio>
							<el-radio v-model="params.is_withdrawal" label="0">关闭</el-radio>
							<div class="form-tip">提示：关闭后，提现不可用</div>
						</el-form-item>
						<el-form-item label="提现服务费率" prop="withdrawal_service_rate">
							<el-input v-model="params.withdrawal_service_rate" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入提现服务费率">
							</el-input>
							<div class="form-tip">提示：单位：%，可保留两位小数</div>
						</el-form-item>
						<el-form-item label="最小金额" prop="withdrawal_min_money">
							<el-input v-model="params.withdrawal_min_money" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入最小金额">
							</el-input>
							<div class="form-tip">提示：用户单次最小充值金额，可保留两位小数</div>
						</el-form-item>
						<el-form-item label="最大金额" prop="withdrawal_max_money">
							<el-input v-model="params.withdrawal_max_money" type="number" min="0.01" max="100.00"
								step="0.01"
								oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
								size="small" placeholder="请输入最大金额">
							</el-input>
							<div class="form-tip">提示：用户单次最大充值金额，可保留两位小数</div>
						</el-form-item>
						<el-form-item label="提现说明" prop="withdrawal_tips_msg">
							<el-input v-model="params.withdrawal_tips_msg" size="small" placeholder="请输入提现说明">
							</el-input>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="第三方配置" name="third_party">
						<el-form-item label="七牛云accessKey" prop="qn_accessKey">
							<el-input v-model="params.qn_accessKey" size="small" placeholder="请输入七牛云accessKey">
							</el-input>
						</el-form-item>
						<el-form-item label="七牛云七牛云secretKey" prop="qn_secretKey">
							<el-input v-model="params.qn_secretKey" size="small" placeholder="请输入七牛云secretKey">
							</el-input>
						</el-form-item>
						<el-form-item label="七牛云存储空间名称" prop="qn_bucket">
							<el-input v-model="params.qn_bucket" size="small" placeholder="请输入七牛云存储空间名称">
							</el-input>
						</el-form-item>
						<el-form-item label="七牛云域名" prop="qn_domain_name">
							<el-input v-model="params.qn_domain_name" size="small" placeholder="请输入七牛云域名">
							</el-input>
						</el-form-item>
						<el-form-item label="微信开放平台appId" prop="wxopen_appid">
							<el-input v-model="params.wxopen_appid" size="small" placeholder="请输入微信开放平台AppId">
							</el-input>
						</el-form-item>
						<el-form-item label="微信开放平台appSecret" prop="wxopen_appsecret">
							<el-input v-model="params.wxopen_appsecret" size="small" placeholder="请输入微信开放平台appSecret">
							</el-input>
						</el-form-item>
						<el-form-item label="微信小程序appId" prop="wxapp_appid">
							<el-input v-model="params.wxapp_appid" size="small" placeholder="请输入微信小程序appId">
							</el-input>
						</el-form-item>
						<el-form-item label="微信小程序appSecret" prop="wxapp_secret">
							<el-input v-model="params.wxapp_appsecret" size="small" placeholder="请输入微信小程序appSecret">
							</el-input>
						</el-form-item>
						<el-form-item label="微信支付平台商户号" prop="wxpay_mch_id">
							<el-input v-model="params.wxpay_mch_id" size="small" placeholder="请输入微信支付平台商户号">
							</el-input>
						</el-form-item>
						<el-form-item label="微信支付平台v3密钥" prop="wxpay_v3key">
							<el-input v-model="params.wxpay_v3key" size="small" placeholder="请输入微信支付平台v3密钥">
							</el-input>
						</el-form-item>
						<el-form-item label="微信支付平台证书序列号" prop="wxpay_serial_no">
							<el-input v-model="params.wxpay_serial_no" size="small" placeholder="请输入微信支付平台证书序列号">
							</el-input>
						</el-form-item>
						<el-form-item label="支付宝开放平台appid" prop="aliopen_appid">
							<el-input v-model="params.aliopen_appid" size="small" placeholder="请输入支付宝开放平台appid">
							</el-input>
						</el-form-item>
						<el-form-item label="支付宝开放平台应用公钥" prop="aliopen_app_public_key">
							<el-input v-model="params.aliopen_app_public_key" size="small" placeholder="请输入支付宝开放平台应用公钥">
							</el-input>
						</el-form-item>
						<el-form-item label="支付宝开放平台应用私钥" prop="aliopen_app_private_key">
							<el-input v-model="params.aliopen_app_private_key" size="small"
								placeholder="请输入支付宝开放平台应用私钥">
							</el-input>
						</el-form-item>
						<el-form-item label="支付宝开放平台支付宝公钥" prop="aliopen_public_key_rsa2">
							<el-input v-model="params.aliopen_public_key_rsa2" size="small"
								placeholder="请输入支付宝开放平台支付宝公钥">
							</el-input>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="协议配置" name="agreement">
						<el-form-item label="用户协议" prop="user_agreement">
							<div style="border: 1px solid #ccc;">
								<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
									:defaultConfig="toolbarConfig" :mode="editorMode" />
								<Editor :style="'height:'+editorHeight+'px; overflow-y: hidden;'"
									v-model="params.user_agreement" :defaultConfig="editorConfig" :mode="editorMode"
									@onCreated="onCreated" />
							</div>
						</el-form-item>
						<el-form-item label="用户隐私政策" prop="privacy_terms">
							<div style="border: 1px solid #ccc;">
								<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
									:defaultConfig="toolbarConfig" :mode="editorMode" />
								<Editor :style="'height:'+editorHeight+'px; overflow-y: hidden;'"
									v-model="params.privacy_terms" :defaultConfig="editorConfig" :mode="editorMode"
									@onCreated="onCreated" />
							</div>
						</el-form-item>
						<el-form-item label="关于我们" prop="about_us">
							<div style="border: 1px solid #ccc;">
								<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
									:defaultConfig="toolbarConfig" :mode="editorMode" />
								<Editor :style="'height:'+editorHeight+'px; overflow-y: hidden;'"
									v-model="params.about_us" :defaultConfig="editorConfig" :mode="editorMode"
									@onCreated="onCreated" />
							</div>
						</el-form-item>
						<el-form-item label="优惠券说明" prop="coupon_explain">
							<div style="border: 1px solid #ccc;">
								<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
									:defaultConfig="toolbarConfig" :mode="editorMode" />
								<Editor :style="'height:'+editorHeight+'px; overflow-y: hidden;'"
									v-model="params.coupon_explain" :defaultConfig="editorConfig" :mode="editorMode"
									@onCreated="onCreated" />
							</div>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="版本更新" name="updated_version">
						<el-form-item label="版本号" prop="updated_version_no">
							<el-input v-model="params.updated_version_no" size="small" placeholder="请输入版本号,请勿小于当前版本号">
							</el-input>
						</el-form-item>
						<el-form-item label="版本说明" prop="updated_version_explain">
							<el-input v-model="params.updated_version_explain" size="small" placeholder="请输入版本说明">
							</el-input>
						</el-form-item>
						<el-form-item label="下载地址" prop="updated_version_download_url">
							<el-input v-model="params.updated_version_download_url" size="small" placeholder="请输入下载地址">
							</el-input>
						</el-form-item>
					</el-tab-pane>
				</el-tabs>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import {
		getData,
		submit,
	} from "@/api/systemConfig.js";
	// 保存数据组件
	import tableBreadcrumb from './components/table/breadcrumb/breadcrumb.vue';
	// 保存数据组件
	import productSelect from './components/table/productSelect/productSelect.vue';
	// 富文本编辑器
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default {
		components: {
			tableBreadcrumb,
			productSelect,
			Editor,
			Toolbar,
		},
		data() {
			return {
				loading: true,
				activeName: 'basics',
				hot_search_tagsinput_visible: false,
				hot_search_tagsinput_value: '',
				hot_search_tagsinput: [],
				product_label_tagsinput_visible: false,
				product_label_tagsinput_value: '',
				product_label_tagsinput: [],
				product_unit_tagsinput_visible: false,
				product_unit_tagsinput_value: '',
				product_unit_tagsinput: [],
				params: {},
				formRules: {},
				editor: null,
				editorMode: '',
				editorConfig: {},
				toolbarConfig: {},
				editorHeight: 0,
				isSubmit: true, // 是否提交 true-未提交 false-已提交
			}
		},
		created() {
			this.getList();
			this.editorHeight = window.innerHeight - 240;
			this.editorMode = this.$wangEditorConfig().mode;
			this.editorConfig = this.$wangEditorConfig().editorConfig;
			this.toolbarConfig = this.$wangEditorConfig().toolbarConfig;
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		},
		methods: {
			onCreated(editor) {
				// 一定要用 Object.seal() ，否则会报错
				this.editor = Object.seal(editor)
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			/**
			 * @desc 获取账户列表
			 */
			getList() {
				this.loading = true;
				getData().then((res) => {
					this.loading = false;
					this.params = res.data.data;
					this.initData();
				});
			},
			initData() {
				let that = this;
				let base_url = 'https://qn.ydyxw.ydyxw.cn/'
				setTimeout(() => {
					for (let key in that.params) {
						// console.log(key)
						var name = key.split('_')
						var ex = name[name.length - 1];
						if (ex == 'img' && that.$refs[key + 'SelectAttachmentBtn']) {
							let base_url = 'https://qn.ydyxw.ydyxw.cn/'
							// console.log(key + 'SelectAttachmentBtn',that.$refs[key + 'SelectAttachmentBtn'])
							that.$refs[key + 'SelectAttachmentBtn'].initData({
								fileList: [that.params[key]],
								baseFileList: [base_url + that.params[key]],
							});
						}
						// console.log(key)
						if (ex == 'tagsinput') {
							that.params[key] = that.params[key].split(',')
						}
						if (ex == 'array') {
							that.$refs[key].initData(JSON.parse(that.params[key]));
						}
					}
				}, 50)
			},
			// 文件选择
			fileConfirm(e) {
				this.params[e.name] = e.fileList.toString(',');
			},
			showTagsInput(e) {
				console.log(e)
				let that = this;
				that[e + '_visible'] = true;
				setTimeout(() => {
					console.log(e)
					this.$refs[e].focus();
				}, 0)
			},
			tagsInputConfirm(e) {
				let inputValue = this[e + '_value'];
				if (this[e].indexOf(inputValue) > -1) {
					this.$errorMsg('当前选项已存在');
					return;
				}
				if (inputValue) {
					console.log(e)
					this.params[e].push(inputValue);
				}
				console.log(this.params[e])
				this[e + '_visible'] = false;
				this[e + '_value'] = '';
			},
			tagClose(e, index) {
				this.params[e].splice(index, 1);
			},
			productSelectOperation(e) {
				this.params.hot_product_array = JSON.stringify(e);
			},
			// 提交
			submit() {
				if (!this.isSubmit) {
					return false;
				}
				this.isSubmit = false;
				submit(this.params).then((res) => {
					this.$successMsg(res.msg);
					this.isSubmit = true;
				}).catch(() => {
					this.isSubmit = true;
				});
			},
		}
	}
</script>

<style lang="scss">
	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}
</style>
