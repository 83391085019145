import {
	send,
} from "@/api/order.js";
export default {
	name: 'save-data',
	data() {
		return {
			// 显示弹框
			dialogShow: false,
			// 物流公司列表
			companyList: [],
			// 弹框标题
			title: '',
			// 提交参数
			params: {
				id: 0,
				express_company: '',
				express_no: '',
			},
			orderInfo: {},
			role_ids: [],
			// 类型 0-添加 1-编辑
			dialogType: 0,
			is_submit: false,
			// 数据验证
			formRules: {
				express_company: [{
					required: true,
					message: '请选择物流公司',
					trigger: 'blur'
				}, ],
				express_no: [{
					required: true,
					message: '请输入物流单号',
					trigger: 'blur'
				}, ],
			},
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {},
	methods: {
		/**
		 * @desc 开启弹框
		 * @param Number id 当前订单id
		 */
		open(e) {
			console.log(e)
			this.companyList = e.companyList;
			this.orderInfo = e.row;
			this.params.id = e.row.id;
			// 显示弹框
			this.dialogShow = true;
		},
		/**
		 * @desc 关闭弹框
		 */
		close() {
			// 提交信息清空
			this.params = {
				id: 0,
				express_company: '',
				express_no: '',
			}
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false;
		},
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				this.is_submit = true;
				console.log(this.params)
				send(this.params).then((res) => {
					this.$successMsg(res.msg);
					this.is_submit = false;
					this.dialogShow = false;
					// 触发父组件列表方法
					this.$emit('complete')
				}).catch(() => {
					this.is_submit = false;
				});
			})
		},
	}
}
