import service from "@/utils/service.js";

// 新闻列表
export function list(params) {
	return service({
		method: 'get',
		url: 'newsComment/index',
		params: params
	})
}


// 新闻详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'newsComment/detail',
		params: params
	})
}


// 新闻状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'newsComment/multiChange',
		data
	})
}

// 新闻删除
export function del(data) {
	return service({
		method: 'post',
		url: 'newsComment/del',
		data
	})
}
