<template>
	<div class="save-data">
		<el-dialog :title="dialog[dialogType].title" :visible.sync="dialogShow"
			:fullscreen="$isMobilePhone()?true:false" :width="$isMobilePhone()?'100%':'50%'"
			:top="$isMobilePhone()?'':'2vh'" @close="close" class="data-dialog">
			<el-form ref="formRef" :model="params" :rules="formRules" :label-width="$isMobilePhone()?'':'120px'">
				<el-form-item label="名称" prop="name">
					<el-input v-model="params.name" size="small" placeholder="请输入厂商名称"></el-input>
					<div class="form-tip">提示：请勿超过10位</div>
				</el-form-item>
				<el-form-item label="状态" prop="status">
					<el-radio v-model="params.status" label="1">启用</el-radio>
					<el-radio v-model="params.status" label="0">禁用</el-radio>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="close">取 消</el-button>
				<el-button type="primary" size="small" :loading="!isSubmit" @click="submit">保 存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		add,
		edit,
	} from "@/api/productManufacturer.js";
	export default {
		name: 'save-data',
		data() {
			return {
				// 显示弹框
				dialogShow: false,
				// 角色列表
				roleList: [],
				// 弹框标题
				title: '',
				// 提交参数
				params: {
					name: '',
					status: '1',
				},
				role_ids: [],
				// 类型 0-添加 1-编辑
				dialogType: 0,
				// 数据验证
				formRules: {
					name: [{
							required: true,
							message: '请输入厂商名称',
							trigger: 'blur'
						},
						{
							max: 30,
							message: "长度请勿超过30个字符",
							trigger: "blur",
						},
					],
					status: [{
						required: true,
						message: '请选择状态',
						trigger: 'blur'
					}, ],
				},
				// 弹框信息
				dialog: [{
					title: '添加厂商', // 标题
				}, {
					title: '编辑厂商', // 标题
				}],
				isSubmit: true, // 是否提交 true-未提交 false-已提交
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param Number type 类型 0-添加 1-编辑
			 * @param Number id 当前厂商id
			 */
			open(type, e) {
				this.dialogType = type;
				// 显示弹框
				this.dialogShow = true;
				if (type == 1) { // 编辑
					this.params = e.row
				}
			},
			/**
			 * @desc 关闭弹框
			 */
			close() {
				// 提交信息清空
				this.params = {
					name: '',
					status: '1',
				}
				// 去除表单验证、重置数据
				this.$refs.formRef.resetFields()
				// 关闭弹框
				this.dialogShow = false;
			},
			/**
			 * @desc 提交信息
			 */
			submit() {
				var that = this;
				// 验证表单
				that.$refs.formRef.validate(valid => {
					if (!valid) {
						return false;
					}
					if (!that.isSubmit) {
						return false;
					}
					that.isSubmit = false;
					if (that.dialogType == 0) {
						var form = add(that.params);
					} else {
						var form = edit(that.params);
					}
					form.then((res) => {
						that.isSubmit = true;
						that.$successMsg(res.msg);
						that.dialogShow = false;
						that.$emit('submit')
					}).catch(() => {
						that.isSubmit = true;
					});
				})
			},
		}
	}
</script>
