import service from "@/utils/service.js";

// 企业类型列表
export function list(params) {
	return service({
		method: 'get',
		url: 'productCoupon/index',
		params: params
	})
}


// 企业类型新增
export function add(data) {
	return service({
		method: 'post',
		url: 'productCoupon/add',
		data
	})
}

// 企业类型详情
export function detail(params) {
	return service({
		method: 'get',
		url: 'productCoupon/edit',
		params: params
	})
}

// 企业类型修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'productCoupon/edit',
		data
	})
}

// 企业类型修改
export function send(data) {
	return service({
		method: 'post',
		url: 'productCoupon/send',
		data
	})
}

// 企业类型状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'productCoupon/multiChange',
		data
	})
}

// 企业类型删除
export function del(data) {
	return service({
		method: 'post',
		url: 'productCoupon/del',
		data
	})
}
