import service from "@/utils/service.js";

// 实名认证列表
export function list(params) {
	return service({
		method: 'get',
		url: 'userBalanceRecharge/index',
		params: params
	})
}


// 实名认证详情
export function getExamine(params) {
	return service({
		method: 'get',
		url: 'userBalanceRecharge/examine',
		params: params
	})
}


// 实名认证删除
export function examine(data) {
	return service({
		method: 'post',
		url: 'userBalanceRecharge/examine',
		data
	})
}


// 实名认证详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'userBalanceRecharge/detail',
		params: params
	})
}


// 实名认证删除
export function del(data) {
	return service({
		method: 'post',
		url: 'userBalanceRecharge/del',
		data
	})
}
