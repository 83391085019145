// 产品分类
import {
	list as categoryList,
} from "@/api/productCategory.js";
// 产品生产厂商
import {
	selectPage as manufacturerSelectPage,
} from "@/api/productManufacturer.js";
// 产品品牌
import {
	selectPage as brandSelectPage,
} from "@/api/productBrand.js";
// 产品
import {
	add,
	detail,
	edit,
} from "@/api/product.js";
// 表单验证
import formRules from "./formRules.js";
// 富文本编辑器
import {
	Editor,
	Toolbar
} from '@wangeditor/editor-for-vue'
// 规格
import spec from '../spec/spec.vue';
export default {
	name: 'save',
	components: {
		Editor,
		Toolbar,
		spec
	},
	data() {
		return {
			activeName: 'basics',
			dialogShow: false, // 显示弹框
			categoryList: [], // 分类列表
			manufacturerList: [], // 厂商列表
			brandList: [], // 品牌
			height: '',
			// 弹框标题
			title: '',
			// 提交参数
			params: {
				category_id: '',
				category_path: [],
				name: '',
				cover_image: '',
				label: [],
				is_enterprise: '0',
				integral_rate: '0.00',
				be_careful: '',
				manufacturer_id: '',
				fictitious_score: '5.0',
				qualifications_file: '',
				test_report_images: '',
				detail_images: '',
				detail_content: '',
				is_recommend: '0',
				sort: '0',
				status: '1',
				spec: []
			},
			// 类型 0-添加 1-编辑
			dialogType: 0,
			// 数据验证
			formRules: {},
			// 弹框信息
			dialog: [{
				title: '添加', // 标题
			}, {
				title: '编辑', // 标题
			}],
			productLabelList: [],
			editor: null,
			editorMode: '',
			editorConfig: {},
			toolbarConfig: {},
			isSubmit: true, // 是否提交 true-未提交 false-已提交
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {
		this.formRules = formRules;
		this.height = window.innerHeight - 240;
		this.editorMode = this.$wangEditorConfig().mode;
		this.editorConfig = this.$wangEditorConfig().editorConfig;
		this.toolbarConfig = this.$wangEditorConfig().toolbarConfig;
	},
	beforeDestroy() {
		const editor = this.editor
		if (editor == null) return
		editor.destroy() // 组件销毁时，及时销毁编辑器
	},
	methods: {
		onCreated(editor) {
			// 一定要用 Object.seal() ，否则会报错
			this.editor = Object.seal(editor)
		},
		/**
		 * @desc 开启弹框
		 * @param Number type 类型 0-添加 1-编辑
		 * @param Number e
		 */
		open(type, e) {
			this.getCategoryList();
			this.getManufacturerList();
			this.getBrandList();
			this.productLabelList = this.$getSystemConfig('product_label_tagsinput');
			this.dialogType = type;
			if (type == 1) { // 编辑
				this.params = e.row
				this.params.category_path.forEach((item, index) => {
					this.params.category_path[index] = parseInt(item);
				})
				// 显示弹框
				this.dialogShow = true;
				setTimeout(() => {
					if (this.params.cover_image) {
						this.$refs.coverImageSelectAttachmentBtn.initData({
							fileList: [this.params.cover_image],
							baseFileList: [this.params.base_cover_image],
						})
					}
					if (this.params.qualifications_file) {
						this.$refs.qualificationsFileSelectAttachmentBtn.initData({
							fileList: [this.params.qualifications_file],
							baseFileList: [this.params.base_qualifications_file],
						});
					}
					this.$refs.testReportImagesSelectAttachmentBtn.initData({
						fileList: this.params.test_report_images,
						baseFileList: this.params.base_test_report_images,
					});
					this.$refs.detailImagesSelectAttachmentBtn.initData({
						fileList: this.params.detail_images,
						baseFileList: this.params.base_detail_images,
					});
					this.$refs.spec.getData(this.params.spec);
				}, 0)
			}
			// 显示弹框
			this.dialogShow = true;
		},
		/**
		 * @desc 获取分类列表
		 */
		getCategoryList() {
			categoryList().then(res => {
				this.categoryList = res.data.list;
			})
		},
		/**
		 * @desc 获取分类列表
		 */
		getManufacturerList() {
			manufacturerSelectPage().then(res => {
				this.manufacturerList = res.data.list;
			})
		},
		/**
		 * @desc 获取品牌列表
		 */
		getBrandList() {
			brandSelectPage().then(res => {
				this.brandList = res.data.list;
			})
		},
		// 规格操作
		specOperation(e) {
			this.params.spec = e;
		},
		/**
		 * @desc 关闭弹框
		 */
		close() {
			// 提交信息清空
			this.params = {
				category_id: '',
				category_path: [],
				name: '',
				cover_image: '',
				label: [],
				is_enterprise: '0',
				integral_rate: '0.00',
				be_careful: '',
				manufacturer_id: '',
				fictitious_score: '5.0',
				qualifications_file: '',
				test_report_images: '',
				detail_images: '',
				detail_content: '',
				is_recommend: '0',
				sort: '',
				status: '1',
				spec: []
			}
			this.$refs.coverImageSelectAttachmentBtn.initData({
				fileList: [],
				baseFileList: [],
			})
			this.$refs.qualificationsFileSelectAttachmentBtn.initData({
				fileList: [],
				baseFileList: [],
			});
			this.$refs.testReportImagesSelectAttachmentBtn.initData({
				fileList: [],
				baseFileList: [],
			});
			this.$refs.detailImagesSelectAttachmentBtn.initData({
				fileList: [],
				baseFileList: [],
			});
			this.$refs.spec.clear();
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false;
		},
		// 文件选择
		fileConfirm(e) {
			this.params[e.name] = e.fileList.toString(',');
		},
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				if (!that.isSubmit) {
					return false;
				}
				that.isSubmit = false;
				if (that.dialogType == 0) {
					var form = add(that.params);
				} else {
					var form = edit(that.params);
				}
				form.then((res) => {
					that.isSubmit = true;
					that.$successMsg(res.msg);
					that.dialogShow = false;
					that.$emit('submit')
				}).catch(() => {
					that.isSubmit = true;
				});
			})
		},
		onCreated(editor) {
			this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
		},
	}
}
