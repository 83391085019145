import {
	add,
	detail,
	edit,
} from "@/api/news.js";
import {
	Editor,
	Toolbar
} from '@wangeditor/editor-for-vue'
export default {
	name: 'save-data',
	components: {
		Editor,
		Toolbar
	},
	data() {
		return {
			// 显示弹框
			dialogShow: false,
			// 提交参数
			params: {
				title: '',
				brief: '',
				cover_image: '',
				author: '',
				release_time: '',
				fictitious_look: '',
				detail_content: '',
				sort: '',
				status: '1',
			},
			// 类型 0-添加 1-编辑
			dialogType: 0,
			height: '',
			// 数据验证
			formRules: {
				title: [{
					required: true,
					message: '请输入新闻标题',
					trigger: 'blur'
				},
				{
					max: 16,
					message: "长度请勿超过16个字符",
					trigger: "blur",
				},
				],
				brief: [{
					required: true,
					message: '请输入新闻简介',
					trigger: 'blur'
				},
				{
					max: 500,
					message: "长度请勿超过500个字符",
					trigger: "blur",
				},
				],
				cover_image: [{
					required: true,
					message: "请上传封面图",
					trigger: "blur",
				},],
				author: [{
					required: true,
					message: '请输入新闻标题',
					trigger: 'blur'
				},
				{
					max: 16,
					message: "长度请勿超过16个字符",
					trigger: "blur",
				},
				],
				release_time: [{
					required: true,
					message: '请选择发布时间',
					trigger: 'blur'
				}],
				status: [{
					required: true,
					message: '请选择状态',
					trigger: 'blur'
				},],
			},
			editor: null,
			html: '<p>hello</p>',
			toolbarConfig: {},
			editorConfig: {
				placeholder: '请输入内容...'
			},
			mode: 'default', // or 'simple'
			// 弹框信息
			dialog: [{
				title: '添加新闻', // 标题
			}, {
				title: '编辑新闻', // 标题
			}],
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {
		this.height = window.innerHeight - 240;
	},
	methods: {
		/**
		 * @desc 开启弹框
		 * @param Number type 类型 0-添加 1-编辑
		 * @param Number id 当前厂商id
		 */
		open(type, id = 0) {
			this.dialogType = type;
			if (type == 0) { // 添加
				// 显示弹框
				this.dialogShow = true;
			} else { // 编辑
				// 获取当前专区信息
				this.getDetail(id);
			}
		},
		close() {
			// 提交信息清空
			this.params = {
				title: '',
				brief: '',
				cover_image: '',
				author: '',
				release_time: '',
				fictitious_look: '',
				detail_content: '',
				sort: '',
				status: '1',
			}
			this.$refs.coverImageSelectAttachmentBtn.initData({
				fileList: [],
				baseFileList: [],
			})
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false
		},
		// 富文本
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		},
		/**
		 * @desc 获取详情数据
		 */
		getDetail(id) {
			// 查询详情
			detail({
				id: id,
			}).then((res) => {
				console.log(res)
				this.params = res.data.row
				setTimeout(() => {
					this.$refs.coverImageSelectAttachmentBtn.initData({
						fileList: [this.params.cover_image],
						baseFileList: [this.params.base_cover_image],
					})
				}, 0)
				// 显示弹框
				this.dialogShow = true;
			});
		},
		// 文件选择
		fileConfirm(e) {
			console.log(e.name)
			this.params[e.name] = e.fileList.toString(',');
		},
		// 规格文件选择
		specFileConfirm(e) {
			this.params.spec[e.name].spec_image = e.fileList.toString(',');
			console.log(this.params.spec)
		},
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				if (this.dialogType == 0) {
					var form = add(this.params);
				} else {
					var form = edit(this.params);
				}
				form.then((res) => {
					this.$successMsg(res.msg);
					this.dialogShow = false;
					// 触发父组件列表方法
					this.$emit('saveComplete')
				});
			})
		},
		onCreated(editor) {
			this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
		},
	}
}
