import Vue from 'vue'

// loading框设置局部刷新，且所有请求完成后关闭loading框
let loading;
let needLoadingRequestCount = 0 // 声明一个对象用于存储请求个数
function startLoading(target, msg, background) {
	setTimeout(() => {
		loading = Vue.prototype.$loading({
			lock: true,
			text: msg,
			spinner: 'el-icon-loading',
			background: background,
			target: target ? document.querySelector(target) : 'body' // 设置加载动画区域
		})
	}, 1)
}

function endLoading() {
	loading.close()
}
export function showLoading(target = '', msg = '努力加载中...', background = 'rgba(255,255,255,0.1)') {
	if (needLoadingRequestCount === 0) {
		startLoading(target, msg, background)
	}
	needLoadingRequestCount++
}
export function hideLoading() {
	if (needLoadingRequestCount <= 0) return
	needLoadingRequestCount--
	if (needLoadingRequestCount === 0) {
		endLoading()
	}
}

export default {
	showLoading,
	hideLoading
}
