import service from "@/utils/service.js";

// 角色列表
export function list(params) {
	return service({
		method: 'get',
		url: 'role/index',
		params: params
	})
}

// 角色新增
export function add(data) {
	return service({
		method: 'post',
		url: 'role/add',
		data
	})
}

// 角色详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'role/detail',
		params: params
	})
}

// 角色修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'role/edit',
		data
	})
}

// 角色状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'role/multiChange',
		data
	})
}

// 角色删除
export function del(data) {
	return service({
		method: 'post',
		url: 'role/del',
		data
	})
}
