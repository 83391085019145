<template>
	<div class="preview-mian" v-if="images.length > 0">
		<el-image v-show="false" ref="elImage" :src="images[current]" :preview-src-list="images" fit="contain">
		</el-image>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				current: 0,
				images: [],
			};
		},
		props: {},
		created() {},
		methods: {
			show(current, images) {
				if (images.length > 0) {
					this.current = current;
					this.images = images;
					setTimeout(() => {
						this.$refs.elImage.clickHandler()
					}, 0)
				}
			},
		},
	};
</script>
<style lang="scss" scoped>
</style>
