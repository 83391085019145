<template>
	<el-row>
		<el-button type="info" size="small" icon="el-icon-refresh" @click="$emit('refresh')"></el-button>
		<el-button type="primary" size="small" icon="el-icon-plus" @click="$emit('add')">添加</el-button>
		<el-button type="danger" size="small" icon="el-icon-delete" @click="$emit('batchDel')">删除</el-button>
	</el-row>
</template>

<script>
	export default {
		data() {
			return {}
		},
		// 定义传入的参数
		props: {},
		created() {},
		methods: {}
	}
</script>

<style lang="scss">
</style>
