<template>
	<div class="save-data">
		<el-dialog title="降价" :visible.sync="dialogShow" :fullscreen="$isMobilePhone()?true:false"
			:width="$isMobilePhone()?'100%':'70%'" :top="$isMobilePhone()?'':'2vh'" @close="close"
			:close-on-click-modal="false" class="data-dialog">
			<el-form ref="formRef" :model="params" :rules="formRules" :label-width="$isMobilePhone()?'':'120px'">
				<div class="form-main" :style="'height:'+height+'px'">
					<div class="form-content">
						<el-form-item label="产品规格">
							<specDetail :specList="params.spec" @input="specInput"></specDetail>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="close">取 消</el-button>
				<el-button type="primary" size="small" :loading="!isSubmit" @click="submit">提 交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		reduction,
	} from "@/api/product.js";
	import specDetail from '../spec-detail/spec-detail.vue';
	export default {
		name: 'reduction',
		components: {
			specDetail,
		},
		data() {
			return {
				dialogShow: false, // 显示弹框
				product_id: 0,
				height: '',
				// 规格参数
				params: {
					spec: []
				},
				formRules: {},
				isSubmit: true, // 是否提交 true-未提交 false-已提交
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {
			this.height = window.innerHeight - 240;
		},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param Number 当前数据
			 */
			open(e) {
				this.product_id = e.id;
				this.params = e.data
				// 显示弹框
				this.dialogShow = true;
			},
			/**
			 * @desc 关闭弹框
			 */
			close() {
				this.product_id = 0
				// 提交信息清空
				this.params.spec = []
				// 去除表单验证、重置数据
				this.$refs.formRef.resetFields()
				// 关闭弹框
				this.dialogShow = false;
			},
			/**
			 * @desc 提交信息
			 */
			submit() {
				var that = this;
				var spec = []
				this.params.spec.forEach((item, index) => {
					if (item.new_price !== undefined && item.new_price) {
						// var new_price = parseFloat(item.new_price).toFixed(2)
						// if (new_price >= item.price) {
						// 	console.log(123123123)
						// 	this.$errorMsg('新价格必须小于旧价格');
						// 	return false;
						// }
						spec.push({
							id: item.id,
							new_price: item.new_price
						})
					}
				})
				if (spec.length == 0) {
					this.$errorMsg('最少有一项规格填写新价格');
					return;
				}
				if (!that.isSubmit) {
					return false;
				}
				that.isSubmit = false;
				reduction({
					id: this.product_id,
					spec: spec
				}).then((res) => {
					this.$successMsg(res.msg);
					this.isSubmit = true
					this.dialogShow = false;
					// 触发父组件列表方法
					this.$emit('submit')
				}).catch(() => {
					this.isSubmit = true
				});
			},
			// 规格输入
			specInput(e) {
				this.params.spec = e.specList
			}
		}
	}
</script>
<style lang="scss">
	.form-main {
		position: relative;
		overflow: hidden;

		.form-content {
			position: absolute;
			left: 0;
			top: 0;
			right: -17px;
			bottom: 0;
			overflow-x: hidden;
			overflow-y: scroll;
		}
	}
</style>
