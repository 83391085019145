import {
	MessageBox,
	Message
} from 'element-ui'


/**
 * @desc 确认框
 * @param message   提示内容
 * @param confirmfun 确定回调函数
 * @param cancelfun 取消回调函数
 * @param title 标题
 */
export function massageConfirm(message = "", confirmfun, cancelfun = null, title = "提示", ) {
	MessageBox.confirm(message, title, {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then((e) => {
		if (e == 'confirm') {
			return confirmfun()
		} else {
			return cancelfun();
		}
	}).catch(() => {
		console.log(0)
	})
}

/**
 * @desc 克隆
 * @param source  信息
 */
export function successMsg(msg) {
	return message('success', msg);
}


/**
 * @desc 克隆
 * @param source  信息
 */
export function errorMsg(msg) {
	return message('error', msg);
}


/**
 * @desc 克隆
 * @param source  信息
 */
function message(type, msg, duration = 1500, showClose = true) {
	return Message({
		type: type,
		message: msg,
		duration: duration,
		showClose: showClose,
	})
}
