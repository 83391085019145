import service from "@/utils/service.js";

// 近效区产品列表
export function list(params) {
	return service({
		method: 'get',
		url: 'nearEffectAreaProduct/index',
		params: params
	})
}

// 近效区产品列表
export function selectPage(params) {
	return service({
		method: 'get',
		url: 'nearEffectAreaProduct/selectPage',
		params: params
	})
}

// 角色列表
export function roleList(params) {
	return service({
		method: 'get',
		url: 'nearEffectAreaProduct/roleList',
		params: params
	})
}

// 近效区产品新增
export function add(data) {
	return service({
		method: 'post',
		url: 'nearEffectAreaProduct/add',
		data
	})
}

// 近效区产品详情
export function detail(params) {
	return service({
		method: 'get',
		url: 'nearEffectAreaProduct/edit',
		params: params
	})
}

// 近效区产品修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'nearEffectAreaProduct/edit',
		data
	})
}

// 近效区产品修改
export function reduction(data) {
	return service({
		method: 'post',
		url: 'nearEffectAreaProduct/reduction',
		data
	})
}

// 近效区产品状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'nearEffectAreaProduct/multiChange',
		data
	})
}

// 近效区产品删除
export function del(data) {
	return service({
		method: 'post',
		url: 'nearEffectAreaProduct/del',
		data
	})
}
