<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {};
</script>
<style>
	@import url("./assets/css/reset.css");
	@import "@wangeditor/editor/dist/css/style.css";
	@import "./assets/css/flex.css";
	@import "./assets/css/common.css";
	@import './assets/css/iconfont.css';
	@import './assets/css/init.css';

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}
</style>
