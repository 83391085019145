import dateTimeProps from "@/components/list/search/date-time-props.js";
export default {
	search: true, // 搜索按钮显示
	reset: true, // 重置按钮显示
	formList: [{
		label: '订单类型',
		type: 'select', // 表单类型
		paramsName: "order_type", // 参数名
		placeholder: '请选择订单类型', // 背景字
		selectList: [{ // 选择列表
			label: '产品',
			value: '1'
		}, {
			label: '近效区',
			value: '2'
		}, {
			label: '活动',
			value: '3'
		}, {
			label: '积分商城',
			value: '4'
		}],
	}, {
		label: '售后订单号',
		type: 'input', // 表单类型
		inputType: 'text', // input类型
		paramsName: "refund_number", // 参数名
		placeholder: '请输入售后订单号', // 框背景字
	}, {
		label: '订单号',
		type: 'input', // 表单类型
		inputType: 'text', // input类型
		paramsName: "order_number", // 参数名
		placeholder: '请输入订单号', // 框背景字
	}, {
		label: '用户',
		type: 'input', // 表单类型
		inputType: 'text', // input类型
		paramsName: "nickname", // 参数名
		placeholder: '请输入用户昵称', // 框背景字
	}, {
		label: '创建时间',
		type: 'date-picker', // 表单类型
		inputType: 'text', // input类型
		paramsName: "create_time", // 参数名
		timeProps: dateTimeProps, // 时间选择器配置信息
	}, {
		label: '状态',
		type: 'select', // 表单类型
		paramsName: "status", // 参数名
		placeholder: '请选择状态', // 背景字
		selectList: [{ // 选择列表
			label: '待审核',
			value: '0'
		}, {
			label: '已通过',
			value: '1'
		}, {
			label: '审核未通过',
			value: '2'
		}, {
			label: '已发货',
			value: '3'
		}, {
			label: '已收货',
			value: '4'
		}, {
			label: '已退款',
			value: '5'
		}, {
			label: '已取消',
			value: '6'
		}, ],
	}],
}
