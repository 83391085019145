import service from "@/utils/service.js";

// 附件列表
export function list(params) {
	return service({
		method: 'get',
		url: 'attachmentCategory/index',
		params: params
	})
}

// 附件新增
export function add(data) {
	return service({
		method: 'post',
		url: 'attachmentCategory/add',
		data
	})
}

// 附件详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'attachmentCategory/detail',
		params: params
	})
}

// 附件修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'attachmentCategory/edit',
		data
	})
}

// 附件状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'attachmentCategory/multiChange',
		data
	})
}

// 附件删除
export function del(data) {
	return service({
		method: 'post',
		url: 'attachmentCategory/del',
		data
	})
}
