<template>
	<div class="save-data">
		<el-dialog :title="dialog[dialogType].title" :visible.sync="dialogShow"
			:fullscreen="$isMobilePhone()?true:false" :width="$isMobilePhone()?'100%':'70%'"
			:top="$isMobilePhone()?'':'2vh'" @close="close" class="data-dialog">
			<el-form ref="formRef" :model="params" :rules="formRules" :label-width="$isMobilePhone()?'':'120px'">
				<div class="form-main" :style="'height:'+height+'px'">
					<div class="form-content">
						<el-form-item label="上级分类">
							<el-cascader placeholder="请选择分类" size="small" :options="categoryList" v-model="params.path"
								:props="{checkStrictly: true,value: 'id',label: 'name',expandTrigger: 'hover'}"
								filterable clearable :style="{width: '100%'}"></el-cascader>
							<div class="form-tip">提示：当前分类所属上级分类</div>
						</el-form-item>
						<el-form-item label="名称" prop="name">
							<el-input v-model="params.name" size="small" maxlength="30" placeholder="请输入分类名称">
							</el-input>
							<div class="form-tip">提示：请勿超过30位</div>
						</el-form-item>
						<el-form-item label="封面图" prop="image">
							<!-- 选择文件 -->
							<selectAttachmentBtn ref="selectAttachmentBtn" fileType="image" paramName="image"
								@confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过300KB</div>
						</el-form-item>
						<el-form-item label="热门" prop="is_hot">
							<el-radio v-model="params.is_hot" label="0">否</el-radio>
							<el-radio v-model="params.is_hot" label="1">是</el-radio>
							<div class="form-tip">提示：开启后，当前分类仅限企业认证后的用户可购买</div>
						</el-form-item>
						<el-form-item label="首页显示" prop="is_index">
							<el-radio v-model="params.is_index" label="0">否</el-radio>
							<el-radio v-model="params.is_index" label="1">是</el-radio>
							<div class="form-tip">提示：开启后，当前分类仅限企业认证后的用户可购买</div>
						</el-form-item>
						<el-form-item label="排序" prop="sort">
							<el-input v-model="params.sort" type="number" step="1" min="0" size="small"
								placeholder="请输入排序">
							</el-input>
							<div class="form-tip">提示：限数字</div>
						</el-form-item>
						<el-form-item label="状态" prop="status">
							<el-radio v-model="params.status" label="1">是</el-radio>
							<el-radio v-model="params.status" label="0">否</el-radio>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="close">取 消</el-button>
				<el-button type="primary" size="small" :loading="!isSubmit" @click="submit">保 存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list as categoryList,
	} from "@/api/productCategory.js";
	import {
		add,
		edit,
	} from "@/api/productCategory.js";
	import formRule from "./formRule.js";
	export default {
		name: 'save',
		data() {
			return {
				dialogShow: false, // 显示弹框
				regionList: [], // 专区列表
				categoryList: [], // 分类列表
				height: '', // 弹框内容高度
				// 提交参数
				params: {
					region_id: '',
					parent_id: '',
					path: [],
					name: '',
					image: '',
					is_hot: '0',
					is_index: '0',
					sort: '0',
					status: '1',
				},
				dialogType: 0, // 类型 0-添加 1-编辑
				formRules: {}, // 数据验证
				// 弹框信息
				dialog: [{
					title: '添加', // 标题
				}, {
					title: '编辑', // 标题
				}],
				isSubmit: true, // 是否提交 true-未提交 false-已提交
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {
			this.formRules = formRule;
			this.height = window.innerHeight - 240;
		},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param Number type 类型 0-添加 1-编辑
			 * @param Number e 当前数据
			 */
			open(type, e) {
				this.dialogType = type;
				this.getCategoryList();
				if (type == 1) { // 编辑
					this.params = e.row
					this.params.path.forEach((item, index) => {
						this.params.path[index] = parseInt(item);
					})
					setTimeout(() => {
						this.$refs.selectAttachmentBtn.initData({
							fileList: [this.params.image],
							baseFileList: [this.params.base_image],
						});
					}, 0)
				}
				// 显示弹框
				this.dialogShow = true;
			},
			/**
			 * @desc 获取分类列表
			 */
			getCategoryList() {
				categoryList().then(res => {
					this.categoryList = res.data.list;
				})
			},
			/**
			 * @desc 关闭弹框
			 */
			close() {
				// 提交信息清空
				this.params = {
					parent_id: '',
					path: [],
					name: '',
					image: '',
					is_hot: '0',
					is_index: '0',
					sort: '0',
					status: '1',
				}
				this.$refs.selectAttachmentBtn.initData({
					fileList: [],
					baseFileList: [],
				});
				// 去除表单验证、重置数据
				this.$refs.formRef.resetFields()
				// 关闭弹框
				this.dialogShow = false;
			},
			// 文件选择
			fileConfirm(e) {
				this.params[e.name] = e.fileList.toString(',');
			},
			/**
			 * @desc 提交信息
			 */
			submit() {
				var that = this;
				// 获取上级分类
				this.params.parent_id = this.params.path[this.params.path.length - 1];
				// 验证表单
				that.$refs.formRef.validate(valid => {
					if (!valid) {
						return false;
					}
					if (!that.isSubmit) {
						return false;
					}
					that.isSubmit = false;
					if (that.dialogType == 0) {
						var form = add(that.params);
					} else {
						var form = edit(that.params);
					}
					form.then((res) => {
						that.isSubmit = true;
						that.$successMsg(res.msg);
						that.dialogShow = false;
						that.$emit('submit')
					}).catch(() => {
						that.isSubmit = true;
					});
				})
			},
		}
	}
</script>
<style lang="scss">
	.form-main {
		position: relative;
		overflow: hidden;

		.form-content {
			position: absolute;
			left: 0;
			top: 0;
			right: -17px;
			bottom: 0;
			overflow-x: hidden;
			overflow-y: scroll;
		}
	}
</style>
