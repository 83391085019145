import {
	add,
	detail,
	edit,
} from "@/api/orderInvoice.js";
export default {
	name: 'save-data',
	data() {
		return {
			// 显示弹框
			dialogShow: false,
			// 提交参数
			params: {
				id: 0,
				invoice_file: '',
			},
			// 类型 0-编辑
			dialogType: 0,
			height: '',
			// 数据验证
			formRules: {},
			// 弹框信息
			dialog: [{
				title: '发票详情', // 标题
			}, ],
			invoice: {},
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {
		this.height = window.innerHeight - 240;
	},
	methods: {
		/**
		 * @desc 开启弹框
		 * @param Number id 当前厂商id
		 */
		open(e) {
			this.invoice = e.row
			this.params.nickname = e.row.nickname
			this.params.mobile = e.row.mobile
			this.params.avatar = e.row.avatar
			this.params.status = e.row.status
			this.params.id = e.row.id
			if (this.invoice.avatar) {
				this.$refs.coverImageSelectAttachmentBtn.initData({
					fileList: [this.invoice.avatar],
					baseFileList: [this.invoice.base_avatar],
				})
			}
			// 显示弹框
			this.dialogShow = true;
		},
		close() {
			// 提交信息清空
			this.params = {
				id: 0,
				invoice_file: '',
			}
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false
		},
		// 文件选择
		fileConfirm(e) {
			this.params[e.name] = e.fileList.toString(',');
		},
		// 规格文件选择
		specFileConfirm(e) {
			this.params.spec[e.name].spec_image = e.fileList.toString(',');
			console.log(this.params.spec)
		},
		/**
		 * @desc 关闭弹框
		 */
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				console.log(this.params)
				var form = edit(this.params);
				form.then((res) => {
					this.$successMsg(res.msg);
					this.dialogShow = false;
					// 触发父组件列表方法
					this.$emit('saveComplete')
				});
			})
		},
		onCreated(editor) {
			this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
		},
	}
}
