import service from "@/utils/service.js";

// 活动列表
export function list(params) {
	return service({
		method: 'get',
		url: 'activity/index',
		params: params
	})
}

// 活动列表
export function selectPage(params) {
	return service({
		method: 'get',
		url: 'activity/selectPage',
		params: params
	})
}

// 活动新增
export function add(data) {
	return service({
		method: 'post',
		url: 'activity/add',
		data
	})
}

// 活动详情
export function detail(params) {
	return service({
		method: 'get',
		url: 'activity/edit',
		params: params
	})
}

// 活动修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'activity/edit',
		data
	})
}

// 活动状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'activity/multiChange',
		data
	})
}

// 活动删除
export function del(data) {
	return service({
		method: 'post',
		url: 'activity/del',
		data
	})
}
