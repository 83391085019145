<template>
	<div>
<!-- 		<div class="number-main u-flex">
			<div class="number-li">
				<div class="title">用户增长量</div>
			</div>
			<div class="number-li"></div>
			<div class="number-li"></div>
			<div class="number-li"></div>
		</div> -->
		<el-card class="user-card">
			<div ref="user" class="user"></div>
		</el-card>
	</div>
</template>

<script>
	import {
		getMonthUserStatistics,
	} from "@/api/index.js";
	export default {
		name: 'welcome',
		data() {
			return {
				menuList: {},
			}
		},
		created() {},
		// 此时页面上的元素，一斤被渲染完成了
		mounted() {
			this.getMonthUserStatistics();
		},
		methods: {
			getMonthUserStatistics() {
				getMonthUserStatistics().then((res) => {
					var user = this.$refs.user
					// 基于准备好的dom，初始化echarts实例
					var myChart = this.$echarts.init(user);
					// 指定图表的配置项和数据
					var option = {
						title: {
							text: '用户增长量'
						},
						gradientColor: ['#f6efa6'],
						// 提示框
						tooltip: {},
						// 图例
						legend: {
							data: [res.data.date]
						},
						// 表示x轴坐标
						xAxis: {
							data: res.data.dayList
						},
						// 表示y轴坐标
						yAxis: {},
						// 
						series: [{
							name: res.data.date,
							type: 'line',
							data: res.data.dataList
						}]
					};
					myChart.setOption(option);
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.container {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}

	// .number-main {
	// 	.number-li {
	// 		width: 200px;
	// 		height: 120px;
	// 		background-color: #FFFFFF;
	// 		margin: 0 20px 20px 0;

	// 		.title {
	// 			buser-bottom: 1px solid #e8eaec;
	// 			padding: 14px 16px;
	// 			line-height: 1;
	// 			height: 20px;
	// 			line-height: 20px;
	// 			font-size: 14px;
	// 			color: #17233d;
	// 			font-weight: bold;
	// 		}
	// 	}
	// }

	.user {
		width: 100%;
		height: 350px;
	}
</style>
