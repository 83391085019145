import service from "@/utils/service.js";

// 产品专区列表
export function list(params) {
	return service({
		method: 'get',
		url: 'productRegion/index',
		params: params
	})
}

// 产品专区选择分页
export function selectPage(params = {}) {
	return service({
		method: 'get',
		url: 'productRegion/selectPage',
		params: params
	})
}


// 产品专区新增
export function add(data) {
	return service({
		method: 'post',
		url: 'productRegion/add',
		data
	})
}

// 产品专区详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'productRegion/detail',
		params: params
	})
}

// 产品专区修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'productRegion/edit',
		data
	})
}

// 产品专区状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'productRegion/multiChange',
		data
	})
}

// 产品专区删除
export function del(data) {
	return service({
		method: 'post',
		url: 'productRegion/del',
		data
	})
}
