import dateTimeProps from "@/components/list/search/date-time-props.js";
export default {
	search: true, // 搜索按钮显示
	reset: true, // 重置按钮显示
	formList: [{
		label: '上传时间',
		type: 'date-picker', // 表单类型
		inputType: 'text', // input类型
		paramsName: "create_time", // 参数名
		timeProps: dateTimeProps, // 时间选择器配置信息
	}],
}
