<template>
	<div class="product-table">
		<div class="table">
			<div class="tb-header u-flex">
				<div class="li li-50">产品</div>
				<div class="li li-25">热度</div>
				<div class="li li-25">操作</div>
			</div>
			<div class="td" v-if="resList.length > 0" v-for="(item,index) in resList">
				<div class="product-data u-flex">
					<div class="li li-50">
						<el-select v-model="item.product_id" size="small" filterable clearable placeholder="请选择产品"
							style="width:100%" @change="productSelect">
							<el-option v-for="v in productList" :value="v.id" :label="v.name">
							</el-option>
						</el-select>
					</div>
					<div class="li li-25">
						<el-input v-model="item.number" type="number" step="0.01" min="0.01" size="small"
							oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
							placeholder="限数字" @input="numberInput">
						</el-input>
					</div>
					<div class="li li-25">
						<el-button type="danger" icon="el-icon-delete" size="mini" @click="delProduct(index)">
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<el-button type="success" size="small" class="addLine" @click="addProduct()">添加产品</el-button>
	</div>
</template>

<script>
	import {
		selectPage as productSelectPage,
	} from "@/api/product.js";
	export default {
		name: 'product',
		data() {
			return {
				productList: [],
				resList: [],
			}
		},
		// 定义传入的参数
		props: {},
		created() {},
		methods: {
			initData(e = []) {
				this.resList = e
				this.getProudctList();
			},
			/**
			 * @desc 获取产品列表
			 */
			getProudctList() {
				productSelectPage().then(res => {
					this.productList = res.data.list;
				})
			},
			// 添加榜单
			addProduct() {
				this.resList.push({
					product_id: '',
					number: '',
				})
				this.peration();
			},
			// 删除榜单
			delProduct(index) {
				// 从提交的数据中删除
				this.resList.splice(index, 1)
				this.peration();
			},
			// 清除数据
			clear() {
				this.resList = [];
				this.peration();
			},
			// 产品选择
			productSelect(e) {
				this.peration();
			},
			// 热度输入
			numberInput(e) {
				this.peration();
			},
			// 操作
			peration() {
				this.$emit('operation', this.resList);
			},
		}
	}
</script>
<style lang="scss">
	.product-table {
		.table {
			width: 70%;
			border: 1px solid #f0f0f0;

			.tb-header {
				width: 100%;
				height: 40px;
				font-weight: bold;
				font-size: 12px;

				.li {
					text-align: center;
					height: 100%;
				}
			}

			.td {
				width: 100%;
				font-size: 12px;
				padding: 20px 0;
				border-bottom: 1px solid #f6f6f6;

				.li {
					box-sizing: border-box;
					line-height: 60px;
					text-align: center;
					height: 100%;
				}
			}

			.td:last-child {
				border: none;
			}

			.li-50 {
				padding: 0 10px;
				width: 50%;
			}

			.li-25 {
				padding: 0 10px;
				width: 25%;
			}
		}
	}
</style>
