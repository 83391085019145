import service from "@/utils/service.js";

// 订单产品列表
export function list(params) {
	return service({
		method: 'get',
		url: 'orderProduct/index',
		params: params
	})
}
