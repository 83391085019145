// 自定义组件全局引入
import uploadFile from './common/uploadFile.vue';
import selectAttachmentBtn from './common/selectAttachmentBtn.vue';
import selectAttachment from './common/selectAttachment.vue';
import imagePreview from './common/imagePreview.vue';
import pagination from './common/pagination.vue';
import selectPage from './form/select-page.vue';
import listSearch from './list/search/search.vue';
import listTools from './list/tools/tools.vue';
let componentUI = {
	install: (vue) => {
		// 单文件上传组件
		vue.component('selectAttachmentBtn', selectAttachmentBtn)
		// 单文件上传组件
		vue.component('selectAttachment', selectAttachment)
		// 单文件上传组件
		vue.component('uploadFile', uploadFile)
		// 单文件上传组件
		vue.component('imagePreview', imagePreview)
		// 列表分页组件
		vue.component('pagination', pagination)
		// 列表分页组件
		vue.component('select-page', selectPage)
		// 列表分页组件
		vue.component('list-search', listSearch)
		// 列表分页组件
		vue.component('list-tools', listTools)
	}
}

export default componentUI
