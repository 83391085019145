import service from "@/utils/service.js";

// 产品列表
export function list(params) {
	return service({
		method: 'get',
		url: 'product/index',
		params: params
	})
}

// 产品列表
export function selectPage(params) {
	return service({
		method: 'get',
		url: 'product/selectPage',
		params: params
	})
}

// 角色列表
export function roleList(params) {
	return service({
		method: 'get',
		url: 'product/roleList',
		params: params
	})
}

// 产品新增
export function add(data) {
	return service({
		method: 'post',
		url: 'product/add',
		data
	})
}

// 产品详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'product/detail',
		params: params
	})
}

// 产品修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'product/edit',
		data
	})
}

// 产品详情
export function getReduction(params) {
	return service({
		method: 'get',
		url: 'product/getReduction',
		params: params
	})
}

// 产品修改
export function reduction(data) {
	return service({
		method: 'post',
		url: 'product/reduction',
		data
	})
}

// 产品状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'product/multiChange',
		data
	})
}

// 产品删除
export function del(data) {
	return service({
		method: 'post',
		url: 'product/del',
		data
	})
}
