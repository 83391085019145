import {
	list as productList,
} from "@/api/product.js";
// import {
// 	list as integralProductList,
// } from "@/api/integralProduct.js";
// import {
// 	list as activityList,
// } from "@/api/activity.js";
// import {
// 	list as activitytProductList,
// } from "@/api/product.js";
// import {
// 	list as productList,
// } from "@/api/product.js";
import {
	add,
	detail,
	edit,
} from "@/api/banner.js";
export default {
	name: 'save-data',
	data() {
		return {
			// 显示弹框
			dialogShow: false,
			// 提交参数
			params: {
				name: '',
				url_type: '',
				product_id: '',
				sort: '',
				status: '1',
			},
			displayTypeList: [{
				value: 1,
				name: '主页',
			}, {
				value: 2,
				name: '活动主页',
			}, {
				value: 3,
				name: '积分商城主页',
			}, {
				value: 4,
				name: '消息中心主页',
			}, ],
			urlTypeList: [{
				value: 1,
				name: '产品详情',
			}, {
				value: 2,
				name: '产品列表',
			}, {
				value: 3,
				name: '积分商城主页',
			}, {
				value: 4,
				name: '积分商品详情',
			}, {
				value: 5,
				name: '活动主页',
			}, {
				value: 6,
				name: '活动产品列表',
			}, {
				value: 7,
				name: '活动产品详情',
			}, {
				value: 8,
				name: '近效区产品列表',
			}, {
				value: 9,
				name: '近效区产品详情',
			}, {
				value: 10,
				name: '企业认证',
			}, {
				value: 11,
				name: '实名认证',
			}, ],
			productList: [],
			integralProductList: [],
			activityList: [],
			activityProductList: [],
			nearEffectAreaList: [],
			nearEffectAreaProductList: [],
			// 类型 0-添加 1-编辑
			dialogType: 0,
			// 数据验证
			formRules: {
				name: [{
						required: true,
						message: '请输入品牌名称',
						trigger: 'blur'
					},
					{
						max: 30,
						message: "长度请勿超过30个字符",
						trigger: "blur",
					},
				],
				status: [{
					required: true,
					message: '请选择状态',
					trigger: 'blur'
				}, ],
			},
			// 弹框信息
			dialog: [{
				title: '添加品牌', // 标题
			}, {
				title: '编辑品牌', // 标题
			}],
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {},
	methods: {
		/**
		 * @desc 开启弹框
		 * @param Number type 类型 0-添加 1-编辑
		 * @param Number id 当前厂商id
		 */
		open(type, id = 0) {
			this.dialogType = type;
			this.getProductList();
			if (type == 0) { // 添加
				// 显示弹框
				this.dialogShow = true;
			} else { // 编辑
				// 获取当前品牌信息
				this.getDetail(id);
			}
		},
		/**
		 * @desc 获取产品
		 */
		getProductList() {
			productList().then((res) => {
				this.productList = res.data.list
				console.log(this.productList)
			});
		},
		/**
		 * @desc 获取详情数据
		 */
		getDetail(id) {
			// 查询详情
			detail({
				id: id,
			}).then((res) => {
				this.params = res.data.row
				// 显示弹框
				this.dialogShow = true;
			});
		},
		/**
		 * @desc 关闭弹框
		 */
		close() {
			// 提交信息清空
			this.params = {
				name: '',
				status: '1',
			}
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false;
		},
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				if (this.dialogType == 0) {
					var form = add(this.params);
				} else {
					var form = edit(this.params);
				}
				form.then((res) => {
					this.$successMsg(res.msg);
					this.dialogShow = false;
					// 触发父组件列表方法
					this.$emit('saveComplete')
				});
			})
		},
	}
}
