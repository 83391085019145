import {
	add,
	detail,
	edit,
} from "@/api/enterpriseType.js";
export default {
	name: 'save-data',
	data() {
		return {
			// 显示弹框
			dialogShow: false,
			// 提交参数
			params: {
				name: '',
				sort: '',
				status: '1',
			},
			// 类型 0-添加 1-编辑
			dialogType: 0,
			height: '',
			// 数据验证
			formRules: {
				name: [{
						required: true,
						message: '请输入分类名称',
						trigger: 'blur'
					},
					{
						max: 16,
						message: "长度请勿超过16个字符",
						trigger: "blur",
					},
				],
				status: [{
					required: true,
					message: '请选择状态',
					trigger: 'blur'
				}, ],
			},
			// 弹框信息
			dialog: [{
				title: '添加分类', // 标题
			}, {
				title: '编辑分类', // 标题
			}],
			isSubmit: true, // 是否提交 true-未提交 false-已提交
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {
		this.height = window.innerHeight - 240;
	},
	methods: {
		/**
		 * @desc 开启弹框
		 * @param Number type 类型 0-添加 1-编辑
		 * @param Number id 当前账户id
		 */
		open(type, e) {
			this.dialogType = type;
			// 显示弹框
			this.dialogShow = true;
			if (type == 1) { // 编辑
				this.params = e.row
			}
		},
		close() {
			// 提交信息清空
			this.params = {
				name: '',
				sort: '',
				status: '1',
			}
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false
		},
		/**
		 * @desc 关闭弹框
		 */
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				if (!that.isSubmit) {
					return false;
				}
				that.isSubmit = false;
				if (that.dialogType == 0) {
					var form = add(that.params);
				} else {
					var form = edit(that.params);
				}
				form.then((res) => {
					that.isSubmit = true;
					that.$successMsg(res.msg);
					that.dialogShow = false;
					that.$emit('submit')
				}).catch(() => {
					that.isSubmit = true;
				});
			})
		},
	}
}
