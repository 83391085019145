import service from "@/utils/service.js";

// 权限列表
export function list(params) {
	return service({
		method: 'get',
		url: 'power/index',
		params: params
	})
}

// 选择列表
export function selectPage(params) {
	return service({
		method: 'get',
		url: 'power/selectPage',
		params: params
	})
}

// 权限新增
export function add(data) {
	return service({
		method: 'post',
		url: 'power/add',
		data
	})
}

// 权限详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'power/detail',
		params: params
	})
}

// 权限修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'power/edit',
		data
	})
}

// 权限状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'power/multiChange',
		data
	})
}

// 权限删除
export function del(data) {
	return service({
		method: 'post',
		url: 'power/del',
		data
	})
}
