<template>
	<div class="attachment-main">
		<div class="image-li" v-for="(item,index) in baseFileList" :key="index">
			<el-image class="detail-image" :src="item" v-if="fileType == 'image'"></el-image>
			<el-image class="detail-image" :src="$getSystemConfig('upload_doc_img')" v-if="fileType == 'application'">
			</el-image>
			<div class="image-operation">
				<i class="el-icon-zoom-in image-operation-icon" v-if="fileType == 'image'"
					@click="$refs.imagePreview.show(index,baseFileList)"></i>
				<i class="el-icon-delete image-operation-icon" @click="delFile(index)"></i>
			</div>
		</div>
		<div class="upload-btn-main" @click="$refs.selectAttachment.show()">
			<i :class="['btn-icon',fileType == 'image'?'el-icon-picture-outline':'el-icon-folder-opened']"></i>
		</div>
		<selectAttachment ref="selectAttachment" :number="number" :fileType="fileType"
			:confirmFileList="{fileList:fileList,baseFileList:baseFileList}" @confirm="confirmAttachment">
		</selectAttachment>
		<imagePreview ref="imagePreview"></imagePreview>
	</div>
</template>
<script>
	import selectAttachment from "./selectAttachment.vue";
	export default {
		components: {
			selectAttachment,
		},
		data() {
			return {
				fileList: [],
				baseFileList: [],
				previewImg: '',
			};
		},
		props: {
			// 参数名
			paramName: {
				type: String,
				default: '',
			},
			// 文件类型
			fileType: {
				type: String,
				default: 'image',
			},
			// 可选数量
			number: {
				type: Number,
				default: 1,
			},
		},
		created() {},
		methods: {
			// 初始化数据
			initData(e) {
				console.log(this.paramName)
				console.log(e)
				this.fileList = e.fileList
				this.baseFileList = e.baseFileList
			},
			// 确定选择图片
			confirmAttachment(e) {
				console.log(e)
				this.fileList = e.fileList
				this.baseFileList = e.baseFileList
				this.confirm();
			},
			// 删除文件
			delFile(index) {
				this.fileList.splice(index, 1)
				this.baseFileList.splice(index, 1)
				this.confirm();
			},
			// 完成提交
			confirm() {
				this.$emit('confirm', {
					name: this.paramName,
					fileList: this.fileList,
					baseFileList: this.baseFileList,
				});
			},
			clear() {
				// this.paramName = '';
				this.fileList = []
				this.baseFileList = []
			}
		},
	};
</script>
<style lang="scss" scoped>
	.attachment-main {
		display: flex;

		.image-li {
			width: 60px;
			height: 60px;
			margin-right: 10px;
			cursor: pointer;
			position: relative;

			.image-operation {
				display: none;
				justify-content: space-around;
				align-items: center;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 10;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.2);

				.image-operation-icon {
					color: #FFFFFF;
					display: block;
				}
			}
		}

		.image-li:hover .image-operation {
			display: flex;
		}

		.upload-btn-main {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fbfdff;
			border: 1px dashed #c0ccda;
			border-radius: 6px;
			box-sizing: border-box;
			width: 60px;
			height: 60px;
			cursor: pointer;
			line-height: 60px;

			.btn-icon {
				font-size: 26px;
				color: #868686;
			}
		}
	}
</style>
