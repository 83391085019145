<template>
	<div class="save-data">
		<el-dialog :title="dialog[dialogType].title" :visible.sync="dialogShow"
			:fullscreen="$isMobilePhone()?true:false" :width="$isMobilePhone()?'100%':'70%'"
			:top="$isMobilePhone()?'':'2vh'" @close="close" :close-on-click-modal="false" class="data-dialog">
			<el-form ref="formRef" :model="params" :rules="formRules" :label-width="$isMobilePhone()?'':'120px'">
				<div class="form-main" :style="'height:'+height+'px'">
					<div class="form-content">
						<el-form-item label="名称" prop="name">
							<el-input v-model="params.name" size="small" maxlength="30" placeholder="请输入产品名称">
							</el-input>
							<div class="form-tip">提示：请勿超过30位</div>
						</el-form-item>
						<el-form-item label="封面图" prop="cover_image">
							<!-- 选择文件 -->
							<selectAttachmentBtn ref="coverImageSelectAttachmentBtn" fileType="image"
								paramName="cover_image" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：图片大小请勿超过300KB</div>
						</el-form-item>
						<el-form-item label="产品规格" prop="spec">
							<spec ref="spec" @specOperation="specOperation"></spec>
						</el-form-item>
						<el-form-item label="详情图" prop="detail_images">
							<!-- 选择文件 -->
							<selectAttachmentBtn ref="detailImagesSelectAttachmentBtn" fileType="image" :number="5"
								paramName="detail_images" @confirm="fileConfirm">
							</selectAttachmentBtn>
							<div class="form-tip">提示：请勿超过5张，每张图片大小请勿超过300KB</div>
						</el-form-item>
						<el-form-item label="详情内容" prop="detail_content">
							<div style="border: 1px solid #ccc;">
								<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
									:defaultConfig="toolbarConfig" :mode="editorMode" />
								<Editor :style="'height:'+height+'px; overflow-y: hidden;'"
									v-model="params.detail_content" :defaultConfig="editorConfig" :mode="editorMode"
									@onCreated="onCreated" />
							</div>
						</el-form-item>
						<el-form-item label="排序">
							<el-input v-model="params.sort" type="number" min="0" size="small" placeholder="请输入排序">
							</el-input>
							<div class="form-tip">提示：从大到小排序</div>
						</el-form-item>
						<el-form-item label="状态" prop="status">
							<el-radio v-model="params.status" label="1">上架</el-radio>
							<el-radio v-model="params.status" label="0">下架</el-radio>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="close">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">保 存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		add,
		getDetail,
		edit,
	} from "@/api/integralProduct.js";
	import formRules from "./formRules.js";
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import spec from '../spec/spec.vue';
	export default {
		name: 'save',
		components: {
			Editor,
			Toolbar,
			spec
		},
		data() {
			return {
				activeName: 'basics',
				dialogShow: false, // 显示弹框
				height: '',
				// 弹框标题
				title: '',
				// 提交参数
				params: {
					name: '',
					cover_image: '',
					detail_images: '',
					detail_content: '',
					sort: '0',
					status: '1',
					spec: []
				},
				// 类型 0-添加 1-编辑
				dialogType: 0,
				// 数据验证
				formRules: {},
				// 弹框信息
				dialog: [{
					title: '添加', // 标题
				}, {
					title: '编辑', // 标题
				}],
				productLabelList: [],
				editor: null,
				editorMode: '',
				editorConfig: {},
				toolbarConfig: {},
				isSubmit: true, // 是否提交 true-未提交 false-已提交
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {
			var that = this;
			this.formRules = formRules;
			this.height = window.innerHeight - 240;
			this.editorMode = this.$wangEditorConfig().mode;
			this.editorConfig = this.$wangEditorConfig().editorConfig;
			this.toolbarConfig = this.$wangEditorConfig().toolbarConfig;
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		},
		methods: {
			onCreated(editor) {
				// 一定要用 Object.seal() ，否则会报错
				this.editor = Object.seal(editor)
			},
			/**
			 * @desc 开启弹框
			 * @param Number type 类型 0-添加 1-编辑
			 * @param Number id 当前账户id
			 */
			open(type, e) {
				this.dialogType = type;
				// 显示弹框
				this.dialogShow = true;
				if (type == 0) {
					setTimeout(() => {
						this.$refs.spec.getData([]);
					}, 0)
				} else { // 编辑
					this.params = e.row
					setTimeout(() => {
						this.$refs.coverImageSelectAttachmentBtn.initData({
							fileList: [this.params.cover_image],
							baseFileList: [this.params.base_cover_image],
						})
						this.$refs.detailImagesSelectAttachmentBtn.initData({
							fileList: this.params.detail_images,
							baseFileList: this.params.base_detail_images,
						});
						this.$refs.spec.getData(this.params.spec);
					}, 0)
				}
			},
			// 规格操作
			specOperation(e) {
				this.params.spec = e;
			},
			/**
			 * @desc 关闭弹框
			 */
			close() {
				// 提交信息清空
				this.params = {
					name: '',
					cover_image: '',
					detail_images: '',
					detail_content: '',
					sort: '0',
					status: '1',
					spec: []
				}
				this.$refs.coverImageSelectAttachmentBtn.initData({
					fileList: [],
					baseFileList: [],
				})
				this.$refs.detailImagesSelectAttachmentBtn.initData({
					fileList: [],
					baseFileList: [],
				});
				this.$refs.spec.clear();
				// 去除表单验证、重置数据
				this.$refs.formRef.resetFields()
				// 关闭弹框
				this.dialogShow = false;
			},
			// 文件选择
			fileConfirm(e) {
				this.params[e.name] = e.fileList.toString(',');
			},
			/**
			 * @desc 提交信息
			 */
			submit() {
				var that = this;
				// 验证表单
				that.$refs.formRef.validate(valid => {
					if (!valid) {
						return false;
					}
					if (!that.isSubmit) {
						return false;
					}
					that.isSubmit = false;
					if (that.dialogType == 0) {
						var form = add(that.params);
					} else {
						var form = edit(that.params);
					}
					form.then((res) => {
						that.isSubmit = true;
						that.$successMsg(res.msg);
						that.dialogShow = false;
						that.$emit('submit')
					}).catch(() => {
						that.isSubmit = true;
					});
				})
			},
		}
	}
</script>
<style lang="scss">
	.form-main {
		position: relative;
		overflow: hidden;

		.form-content {
			position: absolute;
			left: 0;
			top: 0;
			right: -17px;
			bottom: 0;
			overflow-x: hidden;
			overflow-y: scroll;
		}
	}
</style>
