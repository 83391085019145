var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoadingMenu),expression:"isLoadingMenu"}],staticClass:"home-container",attrs:{"id":"con_lf_top_div","element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(255, 255, 255, 0.9)"}},[_c('el-aside',{class:_vm.mMenuState ? 'hidden-xs-only' : '',attrs:{"width":_vm.pcMenuState ? '64px' : _vm.isMobile ? '50%' : '200px'}},[_c('div',{staticClass:"menu-top flex-center col-center"},[_c('el-avatar',{directives:[{name:"show",rawName:"v-show",value:(_vm.pcMenuState),expression:"pcMenuState"}],staticClass:"website-logo",attrs:{"size":30,"src":"https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pcMenuState),expression:"!pcMenuState"}],staticClass:"website-name font-15"},[_vm._v("医大药械网")])],1),_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"background-color":"#333744","text-color":"#fff","active-text-color":"#409eff","unique-opened":true,"collapse":_vm.pcMenuState,"router":true,"default-active":_vm.navActivePath}},_vm._l((_vm.menuList),function(item){return _c('el-submenu',{key:item.name,attrs:{"index":item.name + ''}},[_c('template',{slot:"title"},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.name))])]),_vm._l((item.children),function(value){return _c('el-menu-item',{key:value.id,attrs:{"index":value.path},on:{"click":function($event){return _vm.selectMenu(value.path, value.name)}}},[_vm._v(" "+_vm._s(value.name)+" ")])})],2)}),1)],1),_c('el-container',[_c('el-header',{attrs:{"height":"100px"}},[_c('div',{staticClass:"header-top flex-between col-center"},[_c('div',{staticClass:"flex col-center"},[_c('div',{class:[
                'header-li text-center font-20',
                _vm.pcMenuState || (_vm.isMobile && _vm.mMenuState)
                  ? 'el-icon-s-unfold'
                  : 'el-icon-s-fold',
              ],on:{"click":_vm.toggleMenu}})]),_c('div',{class:[
              'flex-end col-center flex-1',
              _vm.isMobile ? 'pr-10' : 'pr-15',
            ]},[_c('div',{staticClass:"header-li text-center font-20 el-icon-full-screen hidden-xs-only",on:{"click":_vm.screen}}),_c('el-dropdown',{staticClass:"text-center",attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('div',{staticClass:"flex col-center ml-20"},[_c('div',{staticClass:"pr-10 hidden-xs-only font-13"},[_vm._v("管理员")]),_c('el-avatar',{attrs:{"size":30,"src":"https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"}})],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"divided":"","command":"logout"}},[_vm._v("注销")])],1)],1)],1)]),_c('div',{staticClass:"tabs-switch-page flex col-center"},_vm._l((_vm.tabList),function(tab,index){return _c('el-tag',{key:tab.name,class:index == 0 ? 'el-icon-s-home' : '',attrs:{"size":"medium","closable":tab.name !== '/',"effect":_vm.activePath === tab.name ? 'dark' : 'plain'},on:{"close":function($event){return _vm.handleClose(tab, index)},"click":function($event){return _vm.changeMenu(tab)}}},[_vm._v(" "+_vm._s(index > 0 ? tab.label : "")+" ")])}),1)]),_c('el-main',[_c('div',{staticClass:"main-content"},[_c('transition',[_c('keep-alive',{attrs:{"include":_vm.catch_components}},[_c('router-view')],1)],1),_c('div',{staticClass:"support"},[_vm._v("技术支持：成都医大实业有限公司")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }