<template>
	<div class="table-list">
		<!-- 列表信息 -->
		<el-table :data="list" stripe class="el-table-elem" v-loading="loading" row-key="name"
			:tree-props="{ children: 'children' }" :default-expand-all="true" element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)"
			tooltip-effect="dark" @selection-change="selectionChange">
			<el-table-column prop="name" label="名称" min-width="300" align="center">
			</el-table-column>
			<el-table-column label="状态" min-width="120" align="center">
				<template slot-scope="scope">
					<el-switch :active-value="'1'" :inactive-value="'0'" v-model="scope.row.status"
						@change="multiChange('status',scope.row)">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column prop="sort" label="排序" min-width="120" sortable align="center">
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间" min-width="180" align="center">
			</el-table-column>
			<el-table-column label="操作" min-width="140">
				<template slot-scope="scope">
					<el-button type="primary" icon="el-icon-edit" size="mini" @click="$emit('edit',scope.row)">
					</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="$emit('delete',scope.row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	import {
		multiChange,
	} from "@/api/role.js";
	export default {
		name: "list",
		data() {
			return {
				selectIds: [], // 已选择的id
			}
		},
		// 定义传入的参数
		props: {
			// 加载状态  true-加载中 false-加载结束
			loading: {
				required: true,
				type: Boolean,
				default: false,
			},
			// 数据列表
			list: {
				required: true,
				type: Array,
			},
			// 列表高度
			tableHeight: {
				required: true,
				type: Number,
			}
		},
		mounted() {},
		methods: {
			/**
			 * @desc 列表选择
			 */
			selectionChange(e) {
				this.selectIds = [];
				if (e.length != 0) {
					// 获取选中的id
					e.forEach((item, index) => {
						this.selectIds.push(item.id)
					})
				}
				this.$emit('selectionChange', this.selectIds)
			},
			/**
			 * @desc 状态切换
			 */
			multiChange(name, rows) {
				var params = {
					id: rows.id,
					[name]: rows[name]
				}
				multiChange(params).then((res) => {
					this.$successMsg(res.msg);
				}).catch((ex) => {
					// 切换失败，重置信息
					rows.status = rows.status == 1 ? "0" : "1";
				});
			},
			/**
			 * @desc 分类
			 */
			pagination(e) {
				this.$emit('pagination', e)
			},
			/**
			 * @desc 重置刷新列表分页数据
			 */
			refreshPagination() {
				this.$refs.pagination.currentPage = 1;
			},
		}
	}
</script>

<style lang="scss">
	.table-tag {
		margin: 5px 5px 0 0;
	}
</style>
