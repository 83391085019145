import {
	selectPage as regionSelectPage,
} from "@/api/productRegion.js";
import {
	list as categoryList,
} from "@/api/productCategory.js";
import {
	selectPage as manufacturerSelectPage,
} from "@/api/productManufacturer.js";
import {
	selectPage as brandSelectPage,
} from "@/api/productBrand.js";
import {
	getSystemConfig,
} from "@/api/systemConfig.js";
import {
	add,
	detail,
	edit,
} from "@/api/activityProduct.js";
import rule from "./rule.js";
import {
	Editor,
	Toolbar
} from '@wangeditor/editor-for-vue'
import spec from '../spec/spec.vue';
export default {
	name: 'save',
	components: {
		Editor,
		Toolbar,
		spec
	},
	data() {
		return {
			activeName: 'basics',
			dialogShow: false, // 显示弹框
			regionList: [], // 专区列表
			categoryList: [], // 分类列表
			manufacturerList: [], // 厂商列表
			brandList: [], // 品牌
			height: '',
			// 弹框标题
			title: '',
			// 提交参数
			params: {
				region_id: '',
				category_id: '',
				category_path: [],
				name: '',
				cover_image: '',
				label: '',
				is_enterprise: '0',
				integral_rate: '0.00',
				be_careful: '',
				manufacturer_id: '',
				fictitious_score: '5.0',
				qualifications_image: '',
				detail_images: '',
				detail_content: '',
				is_recommend: '0',
				sort: '0',
				status: '1',
				spec: []
			},
			category_path: [],
			// 类型 0-添加 1-编辑
			dialogType: 0,
			// 数据验证
			formRules: {},
			// 弹框信息
			dialog: [{
				title: '添加', // 标题
			}, {
				title: '编辑', // 标题
			}],
			productLabelList: [],
			is_submit: false,
			editor: null,
			toolbarConfig: {},
			editorConfig: {
				placeholder: '请输入内容...'
			},
			mode: 'default', // or 'simple'
		}
	},
	// 定义传入的参数
	props: {},
	mounted() {
		this.formRules = rule;
		this.height = window.innerHeight - 240;
	},
	beforeDestroy() {
		const editor = this.editor
		if (editor == null) return
		editor.destroy() // 组件销毁时，及时销毁编辑器
	},
	methods: {
		/**
		 * @desc 开启弹框
		 * @param Number type 类型 0-添加 1-编辑
		 * @param Number id 当前账户id
		 */
		open(type, id = 0) {
			this.dialogType = type;
			// 获取专区列表
			this.getRegionList();
			this.getCategoryList();
			this.getManufacturerList();
			this.getProductLabel();
			this.getBrandList();
			if (type == 0) { // 添加
				// 显示弹框
				this.dialogShow = true;
				setTimeout(() => {
					this.$refs.spec.getData(this.params.spec);
				}, 0)
			} else { // 编辑
				// 获取当前账户信息
				this.getDetail(id);
			}
		},
		/**
		 * @desc 获取专区列表
		 */
		getRegionList() {
			regionSelectPage().then(res => {
				this.regionList = res.data.list;
			})
		},
		/**
		 * @desc 获取分类列表
		 */
		getCategoryList() {
			categoryList().then(res => {
				this.categoryList = res.data.list;
			})
		},
		/**
		 * @desc 获取分类列表
		 */
		getManufacturerList() {
			manufacturerSelectPage().then(res => {
				this.manufacturerList = res.data.list;
			})
		},
		/**
		 * @desc 获取品牌列表
		 */
		getBrandList() {
			console.log('测试西悉尼')
			brandSelectPage().then(res => {
				this.brandList = res.data.list;
			})
		},
		/**
		 * @desc 获取产品标签列表
		 */
		getProductLabel() {
			getSystemConfig({
				name: 'product_label'
			}).then(res => {
				this.productLabelList = res.data.data;
			})
		},
		// 上级分类选择
		categorytChange(e) {
			// 获取上级分类
			this.params.category_id = this.category_path[this.category_path.length - 1];
			// 层级路径格式化
			this.params.category_path = this.category_path.toString(',');
		},
		// 规格操作
		specOperation(e) {
			this.params.spec = e;
		},
		/**
		 * @desc 获取详情数据
		 */
		getDetail(id) {
			this.$showLoading();
			// 查询详情
			detail({
				id: id,
			}).then((res) => {
				this.$hideLoading();
				this.params = res.data.row
				this.category_path = this.params.category_path
				this.category_path.forEach((item, index) => {
					this.category_path[index] = parseInt(item);
				})
				// 显示弹框
				this.dialogShow = true;
				setTimeout(() => {
					this.$refs.coverImageSelectAttachmentBtn.initData({
						fileList: [this.params.cover_image],
						baseFileList: [this.params.base_cover_image],
					})
					this.$refs.qualificationsImagesSelectAttachmentBtn.initData({
						fileList: [this.params.qualifications_image],
						baseFileList: [this.params.base_qualifications_image],
					});
					this.$refs.detailImagesSelectAttachmentBtn.initData({
						fileList: this.params.detail_images,
						baseFileList: this.params.base_detail_images,
					});
					this.$refs.spec.getData(this.params.spec);
				}, 0)
			}).catch(() => {
				this.$hideLoading();
			});;
		},
		/**
		 * @desc 关闭弹框
		 */
		close() {
			// 提交信息清空
			this.params = {
				region_id: '',
				category_id: '',
				category_path: [],
				name: '',
				cover_image: '',
				label: '',
				is_enterprise: '0',
				integral_rate: '0.00',
				be_careful: '',
				manufacturer_id: '',
				fictitious_score: '5.0',
				qualifications_image: '',
				detail_images: '',
				detail_content: '',
				is_recommend: '0',
				sort: '0',
				status: '1',
				spec: []
			}
			this.$refs.coverImageSelectAttachmentBtn.initData({
				fileList: [],
				baseFileList: [],
			})
			this.$refs.qualificationsImagesSelectAttachmentBtn.initData({
				fileList: [],
				baseFileList: [],
			});
			this.$refs.detailImagesSelectAttachmentBtn.initData({
				fileList: [],
				baseFileList: [],
			});
			this.category_path = [];
			this.$refs.spec.clear();
			// 去除表单验证、重置数据
			this.$refs.formRef.resetFields()
			// 关闭弹框
			this.dialogShow = false;
		},
		// 文件选择
		fileConfirm(e) {
			this.params[e.name] = e.fileList.toString(',');
		},
		/**
		 * @desc 提交信息
		 */
		submit() {
			var that = this;
			// 验证表单
			that.$refs.formRef.validate(valid => {
				if (!valid) {
					return false;
				}
				if (this.dialogType == 0) {
					var form = add(this.params);
				} else {
					var form = edit(this.params);
				}
				this.is_submit = true
				form.then((res) => {
					this.$successMsg(res.msg);
					this.is_submit = false
					this.dialogShow = false;
					// 触发父组件列表方法
					this.$emit('saveComplete')
				}).catch(() => {
					this.is_submit = false
				});
			})
		},
		onCreated(editor) {
			this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
		},
	}
}
