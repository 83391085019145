import service from "@/utils/service.js";

// 轮播图列表
export function list(params) {
	return service({
		method: 'get',
		url: 'banner/index',
		params: params
	})
}

// 角色列表
export function roleList(params) {
	return service({
		method: 'get',
		url: 'banner/roleList',
		params: params
	})
}

// 轮播图新增
export function add(data) {
	return service({
		method: 'post',
		url: 'banner/add',
		data
	})
}

// 轮播图详情
export function detail(params) {
	return service({
		method: 'get',
		url: 'banner/edit',
		params: params
	})
}

// 轮播图修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'banner/edit',
		data
	})
}

// 轮播图修改
export function reduction(data) {
	return service({
		method: 'post',
		url: 'banner/reduction',
		data
	})
}

// 轮播图状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'banner/multiChange',
		data
	})
}

// 轮播图删除
export function del(data) {
	return service({
		method: 'post',
		url: 'banner/del',
		data
	})
}
