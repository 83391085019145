import service from "@/utils/service.js";

// 用户列表
export function list(params) {
	return service({
		method: 'get',
		url: 'orderInvoice/index',
		params: params
	})
}

// 用户详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'orderInvoice/detail',
		params: params
	})
}

// 用户修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'orderInvoice/edit',
		data
	})
}

// 用户状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'orderInvoice/multiChange',
		data
	})
}

// 用户删除
export function del(data) {
	return service({
		method: 'post',
		url: 'orderInvoice/del',
		data
	})
}
