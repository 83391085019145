import service from "@/utils/service.js";

// 企业类型列表
export function list(params) {
	return service({
		method: 'get',
		url: 'enterpriseType/index',
		params: params
	})
}


// 企业类型新增
export function add(data) {
	return service({
		method: 'post',
		url: 'enterpriseType/add',
		data
	})
}

// 企业类型详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'enterpriseType/detail',
		params: params
	})
}

// 企业类型修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'enterpriseType/edit',
		data
	})
}

// 企业类型状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'enterpriseType/multiChange',
		data
	})
}

// 企业类型删除
export function del(data) {
	return service({
		method: 'post',
		url: 'enterpriseType/del',
		data
	})
}
