<template>
	<div class="save-data">
		<el-dialog title="审核" :visible.sync="dialogShow" :fullscreen="$isMobilePhone()?true:false"
			:width="$isMobilePhone()?'100%':'50%'" :top="$isMobilePhone()?'':'2vh'" @close="close" class="data-dialog">
			<el-form ref="formRef" :model="params" :rules="formRules" :label-width="$isMobilePhone()?'':'120px'">
				<div class="detail-main">
					<div class="detail-content">
						<div class="detail-title">收货信息</div>
						<div class="detail-li u-flex u-flex-wrap">
							<div class="li-item u-flex">
								<div class="li-label">需退货：</div>
								<div class="li-data">
									<el-tag size="mini">{{row.is_return_goods_text}}</el-tag>
								</div>
							</div>
							<div class="li-item u-flex">
								<div class="li-label">订单类型：</div>
								<div class="li-data">￥{{row.order_type_text}}</div>
							</div>
							<div class="li-item u-flex">
								<div class="li-label">订单支付类型：</div>
								<div class="li-data">
									<el-tag size="mini">{{row.order_pay_type_text}}</el-tag>
								</div>
							</div>
							<div class="li-item u-flex" v-if="row.order_pay_type < 6">
								<div class="li-label">需退金额：</div>
								<div class="li-data">￥{{row.order_pay_money}}</div>
							</div>
							<div class="li-item u-flex" v-else>
								<div class="li-label">需退积分：</div>
								<div class="li-data">{{row.order_pay_integral}}</div>
							</div>
						</div>
					</div>
				</div>
				<el-form-item label="审核结果" prop="status">
					<el-radio v-model="params.status" label="1">通过</el-radio>
					<el-radio v-model="params.status" label="2">不通过</el-radio>
				</el-form-item>
				<el-form-item label="收件人姓名" prop="receiver_name" v-if="row.is_return_goods == 1 && params.status == 1">
					<el-input v-model="params.receiver_name" size="small" placeholder="请输入收件人姓名"></el-input>
					<!-- <div class="form-tip">提示：长度5~16位</div> -->
				</el-form-item>
				<el-form-item label="收件人手机号" prop="receiver_mobile"
					v-if="row.is_return_goods == 1 && params.status == 1">
					<el-input v-model="params.receiver_mobile" type="number" size="small" :min="0" minlength="11"
						maxlength="11" placeholder="请输入收件人手机号1"></el-input>
					<!-- <div class="form-tip">提示：长度5~16位</div> -->
				</el-form-item>
				<el-form-item label="退货省份" prop="receiver_province"
					v-if="row.is_return_goods == 1 && params.status == 1">
					<el-input v-model="params.receiver_province" size="small" placeholder="请输入退货省份">
					</el-input>
					<!-- <div class="form-tip">提示：长度5~16位</div> -->
				</el-form-item>
				<el-form-item label="退货城市" prop="receiver_city" v-if="row.is_return_goods == 1 && params.status == 1">
					<el-input v-model="params.receiver_city" size="small" placeholder="请输入退货城市">
					</el-input>
					<!-- <div class="form-tip">提示：长度5~16位</div> -->
				</el-form-item>
				<el-form-item label="退货区县" prop="receiver_area" v-if="row.is_return_goods == 1 && params.status == 1">
					<el-input v-model="params.receiver_area" size="small" placeholder="请输入退货区县"></el-input>
					<!-- <div class="form-tip">提示：长度5~16位</div> -->
				</el-form-item>
				<el-form-item label="详细地址" prop="receiver_address"
					v-if="row.is_return_goods == 1 && params.status == 1">
					<el-input v-model="params.receiver_address" size="small" placeholder="请输入详细地址"></el-input>
					<!-- <div class="form-tip">提示：长度5~16位</div> -->
				</el-form-item>
				<el-form-item label="反馈信息" :prop="params.status == 2?'examine_result':''">
					<el-input v-model="params.examine_result" type="textarea" size="small" maxlength="200"
						placeholder="请输入反馈信息">
					</el-input>
					<!-- <div class="form-tip">提示：长度5~16位</div> -->
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="close">取 消</el-button>
				<el-button type="primary" size="small" :loading="is_submit" @click="submit">提 交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		examine,
	} from "@/api/orderRefund.js";
	export default {
		name: 'save-data',
		data() {
			return {
				// 显示弹框
				dialogShow: false,
				// 提交参数
				params: {
					id: 0,
					status: '1',
					receiver_name: '',
					receiver_mobile: '',
					receiver_province: '',
					receiver_city: '',
					receiver_area: '',
					receiver_address: '',
					examine_result: '',
				},
				row: {},
				// 类型 0-添加 1-编辑
				dialogType: 0,
				is_submit: false,
				// 数据验证
				formRules: {
					status: [{
						required: true,
						message: '请选择审核结果',
						trigger: 'blur'
					}, ],
					receiver_name: [{
						required: true,
						message: '请输入收货人姓名',
						trigger: 'blur'
					}, ],
					receiver_mobile: [{
						required: true,
						message: '请输入收货人手机号',
						trigger: 'blur'
					}, ],
					receiver_province: [{
						required: true,
						message: '请输入收货省份',
						trigger: 'blur'
					}, ],
					receiver_city: [{
						required: true,
						message: '请输入收货城市',
						trigger: 'blur'
					}, ],
					receiver_area: [{
						required: true,
						message: '请输入收货区县',
						trigger: 'blur'
					}, ],
					receiver_address: [{
						required: true,
						message: '请输入收货详细地址',
						trigger: 'blur'
					}, ],
					examine_result: [{
						required: true,
						message: '请输入反馈信息',
						trigger: 'blur'
					}, ],
				},
			}
		},
		// 定义传入的参数
		props: {},
		mounted() {},
		methods: {
			/**
			 * @desc 开启弹框
			 * @param Number id 当前订单id
			 */
			open(e) {
				console.log(e)
				this.row = e.row;
				this.params.id = e.row.id;
				// 显示弹框
				this.dialogShow = true;
			},
			/**
			 * @desc 关闭弹框
			 */
			close() {
				// 提交信息清空
				this.params = {
					id: 0,
					status: '1',
					receiver_name: '',
					receiver_mobile: '',
					receiver_province: '',
					receiver_city: '',
					receiver_area: '',
					receiver_address: '',
					examine_result: '',
				}
				// 去除表单验证、重置数据
				this.$refs.formRef.resetFields()
				// 关闭弹框
				this.dialogShow = false;
			},
			/**
			 * @desc 提交信息
			 */
			submit() {
				var that = this;
				// 验证表单
				that.$refs.formRef.validate(valid => {
					if (!valid) {
						return false;
					}
					this.is_submit = true;
					examine(this.params).then((res) => {
						this.$successMsg(res.msg);
						this.is_submit = false;
						this.dialogShow = false;
						// 触发父组件列表方法
						this.$emit('complete')
					}).catch(() => {
						this.is_submit = false;
					});
				})
			},
		}
	}
</script>
