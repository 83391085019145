<template>
	<el-container class="home-container" id="con_lf_top_div" v-loading="isLoadingMenu" element-loading-text="拼命加载中"
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)">
		<el-aside :width="pcMenuState ? '64px' : isMobile ? '50%' : '200px'"
			:class="mMenuState ? 'hidden-xs-only' : ''">
			<!-- 侧边栏 -->
			<div class="menu-top flex-center col-center">
				<el-avatar class="website-logo" :size="30"
					src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" v-show="pcMenuState">
				</el-avatar>
				<div class="website-name font-15" v-show="!pcMenuState">医大药械网</div>
			</div>
			<!-- 侧边栏菜单区域 -->
			<el-menu background-color="#333744" class="el-menu-vertical-demo" text-color="#fff"
				active-text-color="#409eff" :unique-opened="true" :collapse="pcMenuState" :router="true"
				:default-active="navActivePath">
				<!-- 一级菜单 -->
				<el-submenu :index="item.name + ''" v-for="item in menuList" :key="item.name">
					<!-- 一级菜单模板区域 -->
					<template slot="title">
						<i :class="item.icon"></i>
						<span>{{ item.name }}</span>
					</template>
					<!-- 二级菜单 -->
					<el-menu-item :index="value.path" v-for="value in item.children" :key="value.id"
						@click="selectMenu(value.path, value.name)">
						{{ value.name }}
					</el-menu-item>
				</el-submenu>
			</el-menu>
		</el-aside>
		<el-container>
			<!-- 头部 -->
			<el-header height="100px">
				<div class="header-top flex-between col-center">
					<div class="flex col-center">
						<div :class="[
                'header-li text-center font-20',
                pcMenuState || (isMobile && mMenuState)
                  ? 'el-icon-s-unfold'
                  : 'el-icon-s-fold',
              ]" @click="toggleMenu"></div>
						<!-- <li class="header-li el-icon-refresh-right"></li> -->
					</div>
					<div :class="[
              'flex-end col-center flex-1',
              isMobile ? 'pr-10' : 'pr-15',
            ]">
						<div class="
                header-li
                text-center
                font-20
                el-icon-full-screen
                hidden-xs-only
              " @click="screen"></div>
						<el-dropdown @command="handleCommand" trigger="click" class="text-center">
							<div class="flex col-center ml-20">
								<div class="pr-10 hidden-xs-only font-13">管理员</div>
								<el-avatar :size="30"
									src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png">
								</el-avatar>
							</div>
							<el-dropdown-menu slot="dropdown">
								<!-- <el-dropdown-item command="infoEdit">修改信息</el-dropdown-item> -->
								<el-dropdown-item divided command="logout">注销</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<!-- tab标签页区域 - 用于标签页切换 -->
				<div class="tabs-switch-page flex col-center">
					<el-tag size="medium" v-for="(tab, index) in tabList" :key="tab.name"
						@close="handleClose(tab, index)" :class="index == 0 ? 'el-icon-s-home' : ''"
						@click="changeMenu(tab)" :closable="tab.name !== '/'"
						:effect="activePath === tab.name ? 'dark' : 'plain'">
						{{ index > 0 ? tab.label : "" }}
					</el-tag>
				</div>
			</el-header>
			<!-- 主体 -->
			<el-main>
				<div class="main-content">
					<transition>
						<!-- <transition name="el-zoom-in-top"> -->
						<keep-alive :include="catch_components">
							<router-view></router-view>
						</keep-alive>
					</transition>
					<div class="support">技术支持：成都医大实业有限公司</div>
				</div>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {
		getMenuList
	} from "@/api/index.js";
	export default {
		name: "index",
		data() {
			return {
				isLoadingMenu: true,
				menuList: {},
				pcMenuState: false,
				navActivePath: "",
				fullscreen: false,
				mMenuState: true,
				isMobile: false,
			};
		},
		//   watch: {
		//     $route() {
		//       this.getMenu();
		//     },
		//   },
		created() {
			this.getMenu();
			this.isMobile = this.$isMobilePhone();
		},
		computed: {
			...mapState({
				// 从 state 中的到的计算属性
				activePath: (state) => state.activePath, // 已选中菜单
				tabList: (state) => state.tabList, // tags菜单列表
				catch_components: (state) => state.catch_components, // keepalive缓存
			}),
		},
		methods: {
			// 获取侧边栏
			getMenu() {
				var that = this;
				getMenuList().then((res) => {
					that.isLoadingMenu = false;
					that.$setStorage("systemConfig", JSON.stringify(res.data.systemConfig));
					that.menuList = res.data.menuList;
					that.menuList.forEach(function(item, index) {
						item.children.forEach(function(val, key) {
							if (val.path == that.navActivePath) {
								that.$store.commit(
									"selectMenu",
									(that.$store.state, {
										path: that.navActivePath,
										label: val.name,
										name: that.navActivePath,
									})
								);
							}
						});
					});
				});
			},
			// 侧边栏收缩
			toggleMenu() {
				if (this.isMobile) {
					// 是手机端，直接可展开栏目
					this.mMenuState = !this.mMenuState;
				} else {
					this.pcMenuState = !this.pcMenuState;
				}
			},
			// 右上角选择
			handleCommand(command) {
				var that = this;
				switch (command) {
					case "infoEdit":
						break;
					case "logout":
						that.$massageConfirm("是否注销登录?", function() {
							// window.sessionStorage.clear()
							that.$removeStorage("token");
							// 重置vuex中的数据
							that.$store.state.activePath = "/";
							that.$store.state.tabList = [{
								path: "/",
								label: "首页",
								name: "/",
							}, ];
							that.$successMsg("欢迎再次登录~");
							setTimeout(function() {
								// 跳转到登录页
								that.$router.push("login");
							}, 1500);
						});
						break;
				}
			},
			// 全屏
			screen() {
				// let element = document.documentElement;//设置后就是我们平时的整个页面全屏效果
				let element = document.getElementById("con_lf_top_div"); //设置后就是   id==con_lf_top_div 的容器全屏
				if (this.fullscreen) {
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					} else if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if (document.msExitFullscreen) {
						document.msExitFullscreen();
					}
				} else {
					if (element.requestFullscreen) {
						element.requestFullscreen();
					} else if (element.webkitRequestFullScreen) {
						element.webkitRequestFullScreen();
					} else if (element.mozRequestFullScreen) {
						element.mozRequestFullScreen();
					} else if (element.msRequestFullscreen) {
						// IE11
						element.msRequestFullscreen();
					}
				}
				this.fullscreen = !this.fullscreen;
			},
			// 侧边栏选择
			selectMenu(navActivePath, tabName) {
				var that = this;
				window.sessionStorage.setItem("navActivePath", navActivePath);
				// 加入keepalive缓存
				that.$store.commit("addKeepAliveCache", navActivePath);
				// 历史已选中菜单列表
				var oldTabList = that.$store.state.tabList;
				that.$store.commit(
					"selectMenu",
					(that.$store.state, {
						path: navActivePath,
						label: tabName,
						name: navActivePath,
					})
				);
			},
			// 关闭tab标签
			handleClose(tab, index) {
				// 历史选中菜单
				var oldActivePath = this.$store.state.activePath;
				// 历史已选中菜单列表
				var oldTabList = this.$store.state.tabList;

				// 计算标签个数
				let length = oldTabList.length - 1;

				// 删除tabList中的该对象
				for (let i = 0; i < oldTabList.length; i++) {
					let item = oldTabList[i];
					if (item.name === tab.name) {
						oldTabList.splice(i, 1);
					}
				}

				// 删除keepAlive缓存
				this.$store.commit("removeKeepAliveCache", tab.name);

				// 如果关闭的标签不是当前路由的话，就不跳转
				if (tab.name !== oldActivePath) {
					return;
				}

				// 如果length为1，必然只剩下首页标签，此时关闭后，更新到首页
				if (length === 1) {
					// 同时存储菜单
					this.$store.commit("closeTab", {
						activePath: "/",
						tabList: oldTabList,
					});
					// tab页向左跳转
					this.$router.push(oldTabList[index - 1].name);
					// 不再向下执行
					return;
				}
				// 关闭的标签是最右边的话，往左边跳转一个
				if (index === length) {
					// 同时更新路径
					oldActivePath = oldTabList[index - 1].name;
					// 同时存储菜单
					this.$store.commit("closeTab", {
						activePath: oldActivePath,
						tabList: oldTabList,
					});
					// tab页向左跳转
					this.$router.push(oldTabList[index - 1].name);
				} else {
					// 同时更新路径
					oldActivePath = oldTabList[index].name;
					// 同时存储菜单
					this.$store.commit("closeTab", {
						activePath: oldActivePath,
						tabList: oldTabList,
					});
					this.$router.push(oldTabList[index].name);
				}
			},
			// 点击标签跳转路由
			changeMenu(item) {
				// 历史选中菜单
				var oldActivePath = this.$store.state.activePath;
				// 历史已选中菜单列表
				var oldTabList = this.$store.state.tabList;

				// 首先判断点击的是否是自己，如果是自己则return
				if (oldActivePath === item.name) {
					return;
				}
				// 不是自己，存储菜单
				this.$store.commit("changeMenu", item.name);
				// 页面跳转
				this.$router.push(item.name);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
	}

	.home-container {
		height: 100vh;

		.menu-top {
			height: 70px;
			border-bottom: 1px solid #64666a;

			.website-name {
				color: white;
			}
		}
	}

	.el-header {
		padding: 0 15px;
		background-color: #ffffff;

		.header-top {
			height: 50px;

			.header-li {
				cursor: pointer;
			}

			.el-dropdown {
				.el-dropdown-link {
					color: #333333;
				}
			}
		}
	}

	.el-aside {
		height: 100%;
		background-color: #333744;

		.el-menu-item {
			padding-left: 60px !important;
		}

		.el-menu {
			border: none;
		}
	}

	.el-main {
		background-color: #eaedf1;
		padding: 0;

		.main-content {
			padding: 15px;

			.support {
				text-align: center;
				height: 30px;
				padding-top: 10px;
				line-height: 30px;
				font-size: 13px;
				color: #6c6c6c;
			}
		}
	}

	i {
		margin-right: 20px !important;
		font-size: 20px !important;
	}

	span {
		font-size: 13px;
	}

	.el-menu-item {
		font-size: 13px;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #409eff;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	// tab页
	.tabs-switch-page {
		width: 100%;
		height: 50px;
		background-color: #ffffff;
		overflow-x: auto;
		/*适应苹果*/
		-webkit-overflow-scrolling: touch;
	}

	.el-tag {
		min-width: 30px;
		height: 30px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
		border-radius: 2px;
		font-size: 13px;
		color: #1890ff;
		border-color: #1890ff;
	}

	.el-tag--dark {
		color: #fff;
		background-color: #1890ff;
	}
</style>
