<template>
	<el-row :gutter="24" class="el-row-c">
		<el-form ref="formRef" :model="params" :inline="true" @keyup.enter.native="search">
			<el-form-item v-for="(item, index) in searchProps.formList" :key="index" :label="item.label">
				<!-- 输入框 -->
				<el-input v-if="item.type == 'input'" :type="item.inputType || ''" size="mini" clearable
					v-model="params[item.paramsName]" :placeholder="item.placeholder || ''" @clear="change"
					@blur="change">
				</el-input>
				<!-- 时间选择 -->
				<el-date-picker v-if="item.type == 'date-picker'" size="mini" v-model="params[item.paramsName]"
					clearable :type="item.timeProps.timeType" :picker-options="item.timeProps.pickerOptions"
					range-separator="至" :start-placeholder="item.timeProps.startPlaceholder || ''"
					:end-placeholder="item.timeProps.endPlaceholder || ''"
					:value-format="item.timeProps.valueFormat || 'yyyy-MM-dd hh:mm:ss'" @change="change">
				</el-date-picker>
				<!-- 下拉框 -->
				<el-select v-if="item.type == 'select'" size="mini" clearable v-model="params[item.paramsName]"
					:placeholder="item.placeholder || ''" @change="change">
					<el-option v-for="(selectValue, selectIndex) in item.selectList" :key="selectIndex"
						:label="selectValue.label" :value="selectValue.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button v-if="searchProps.search" type="primary" size="mini" icon="el-icon-search" @click="search">
				</el-button>
				<el-button v-if="searchProps.reset" size="mini" @click="reset">重置</el-button>
			</el-form-item>
		</el-form>
	</el-row>
</template>
<script>
	export default {
		data() {
			return {
				// 参数
				params: {},
			};
		},
		props: {
			// 搜索配置信息
			searchProps: {
				type: [Object, Array],
				default: {},
			}
		},
		created() {
			this.init();
		},
		methods: {
			// 初始化搜索配置信息
			init() {
				let form = {};
				this.searchProps.formList.forEach((item) => {
					// 是否有默认值
					if (item.default !== undefined) {
						// 有默认值
						form[item.paramsName] = item.default;
					} else {
						// 无默认值
						form[item.paramsName] = "";
					}
				});
				this.params = form;
			},
			// 搜索
			search() {
				this.$emit('search')
			},
			// 值改变
			change(e) {
				this.$emit('change', this.params)
			},
			// 重置
			reset() {
				// 初始化数据
				this.init();
				this.$emit("change", this.params);
				this.$emit('search')
			},
		},
	};
</script>

<style lang="scss" scoped>
	.el-row-c {
		margin-bottom: 10px !important;
	}

	.el-form-item {
		margin-bottom: 0 !important;
	}
</style>
