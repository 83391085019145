import {
	list,
	add,
	detail,
	edit,
	del,
} from "@/api/activity.js";
// 保存数据组件
import tableBreadcrumb from './components/table/breadcrumb/breadcrumb.vue';
import tableSearch from './components/table/search/search.vue';
import tableTool from './components/table/tool/tool.vue';
import tableList from './components/table/list/list.vue';
import save from './components/form/save/save.vue';
export default {
	components: {
		tableBreadcrumb,
		tableSearch,
		tableTool,
		tableList,
		save,
	},
	data() {
		return {
			loading: true,
			selectIds: [], // 已选择的id
			mainList: [], // 主体列表数据
			params: { // 列表请求数据
				name: '',
				create_time: '',
				status: '',
				page: 1, // 默认起始页
				pageSize: 10, // 默认每页数量
			},
			total: 0, // 总条目数
			tableHeight: 0,
		}
	},
	created() {
		this.getTableHeight();
		this.getList();
	},
	methods: {
		/**
		 * @desc 获取表格高度
		 */
		getTableHeight() {
			setTimeout(() => {
				var headerHeight = 100;
				var tableToolHeight = this.$refs.tableTool.offsetHeight;
				var breadcrumbHeight = 30 + 14;
				var mainContentPadding = 40;
				var supportHeight = 40;
				var paginationHeight = 20 + 52;
				this.tableHeight = window.innerHeight - (headerHeight + tableToolHeight + breadcrumbHeight +
					mainContentPadding + supportHeight + paginationHeight)
			}, 0)
		},
		/**
		 * @desc 获取账户列表
		 */
		getList() {
			this.loading = true;
			list(this.params).then((res) => {
				this.loading = false;
				this.mainList = res.data.list;
				this.total = res.data.total;
			});
		},
		/**
		 * @desc 搜索
		 */
		search(e) {
			let {
				name,
				status,
				create_time
			} = e;
			this.params.name = name;
			this.params.create_time = create_time;
			this.params.status = status;
			this.refreshList();
		},
		/**
		 * @desc 刷新数据
		 */
		refreshList() {
			this.params.page = 1;
			// 重置刷新列表分页数据
			this.$refs.tableList.refreshPagination();
			this.getList();
		},
		/**
		 * @desc 列表选择
		 */
		selectionChange(e) {
			this.selectIds = e;
		},
		/**
		 * @desc 删除
		 */
		delData(type = 1, id = 0) {
			let that = this;
			if (type == 1) { // 单删除
				var ids = [id];
				var msg = '是否删除当前项？';
			} else { // 批量删除
				if (that.selectIds.length == 0) {
					that.$errorMsg('请选择您要删除的数据');
					return false;
				}
				var ids = that.selectIds;
				var msg = "确认删除已选中的" + that.selectIds.length + "项？";
			}
			that.$massageConfirm(msg, function() {
				del({
					ids: ids
				}).then((res) => {
					that.$successMsg(res.msg);
					that.refreshList();
				});
			});
		},
		/**
		 * @desc 分页查询
		 */
		pagination(e) {
			this.params.page = e.page;
			this.params.pageSize = e.pageSize;
			this.getList();
		},
	}
}
