<template>
	<div class="spec-table">
		<div class="table">
			<div class="tb-header u-flex">
				<div class="li li-60">产品</div>
				<div class="li li-25">首页展示</div>
				<div class="li li-15">操作</div>
			</div>
			<div class="td" v-if="activityProductList.length > 0" v-for="(item,index) in activityProductList"
				:key="index">
				<div class="spec-data u-flex">
					<div class="li li-60">
						<el-select v-model="item.product_id" size="small" filterable clearable placeholder="请选择活动产品"
							style="width:100%">
							<el-option v-for="v in productList" :key="v.id" :value="v.id" :label="v.name">
							</el-option>
						</el-select>
					</div>
					<div class="li li-25">
						<el-switch :active-value="'1'" :inactive-value="'0'" v-model="item.is_index">
						</el-switch>
					</div>
					<div class="li li-15">
						<el-button type="danger" icon="el-icon-delete" size="mini" @click="delProduct(index)">
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<el-button type="success" size="small" class="addLine" @click="addProduct()">添加</el-button>
	</div>
</template>

<script>
	import {
		selectPage as productSelectPage,
	} from "@/api/activityProduct.js";
	export default {
		name: 'productSelect',
		data() {
			return {
				activityProductList: [],
				productList: [],
			}
		},
		// 定义传入的参数
		props: {},
		created() {},
		methods: {
			getData(e = []) {
				this.activityProductList = e
				this.getProudctList();
			},
			/**
			 * @desc 获取产品列表
			 */
			getProudctList() {
				productSelectPage().then(res => {
					this.productList = res.data.list;
				})
			},
			// 添加产品
			addProduct() {
				this.activityProductList.push({
					product_id: '',
					is_index: '',
				})
				this.peration();
			},
			// 删除产品
			delProduct(index) {
				// 从提交的数据中删除
				this.activityProductList.splice(index, 1)
				this.peration();
			},
			// 清除数据
			clear() {
				this.activityProductList = [];
				this.peration();
			},
			// 操作
			peration() {
				this.$emit('productSelectOperation', this.activityProductList);
			},
		}
	}
</script>
<style lang="scss">
	.spec-table {
		.table {
			width: 100%;
			border: 1px solid #f0f0f0;

			.tb-header {
				width: 100%;
				height: 40px;
				font-weight: bold;
				font-size: 12px;

				.li {
					text-align: center;
					height: 100%;
				}
			}

			.td {
				width: 100%;
				font-size: 12px;
				padding: 20px 0;
				border-bottom: 1px solid #f6f6f6;

				.li {
					box-sizing: border-box;
					line-height: 60px;
					text-align: center;
					height: 100%;
				}
			}

			.td:last-child {
				border: none;
			}

			.li-60 {
				padding: 0 10px;
				width: 60%;
			}

			.li-25 {
				padding: 0 20px;
				width: 25%;
			}

			.li-15 {
				padding: 0 20px;
				width: 15%;
			}
		}
	}
</style>
