import service from "@/utils/service.js";

// 产品品牌列表
export function list(params) {
	return service({
		method: 'get',
		url: 'productBrand/index',
		params: params
	})
}


// 产品品牌新增
export function add(data) {
	return service({
		method: 'post',
		url: 'productBrand/add',
		data
	})
}

// 产品品牌详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'productBrand/detail',
		params: params
	})
}

// 产品品牌选择列表
export function selectPage(params) {
	return service({
		method: 'get',
		url: 'productBrand/selectPage',
		params: params
	})
}

// 产品品牌修改
export function edit(data) {
	return service({
		method: 'post',
		url: 'productBrand/edit',
		data
	})
}

// 产品品牌状态切换
export function multiChange(data) {
	return service({
		method: 'post',
		url: 'productBrand/multiChange',
		data
	})
}

// 产品品牌删除
export function del(data) {
	return service({
		method: 'post',
		url: 'productBrand/del',
		data
	})
}
