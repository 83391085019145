import service from "@/utils/service.js";

// 提交登录
export function login(data) {
	return service({
		method: 'post',
		url: 'login/login',
		data
	})
}
