<template>
	<div class="table-list">
		<!-- 列表信息 -->
		<el-table :data="list" stripe class="el-table-elem" v-loading="loading" element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)"
			tooltip-effect="dark">
			<el-table-column prop="id" label="ID" min-width="80" sortable align="center">
			</el-table-column>
			<el-table-column prop="nickname" label="用户昵称" align="center" min-width="120">
				<template slot-scope="scope">
					<div class="list-main">
						<div class="list-li">{{scope.row.nickname}}</div>
						<div class="list-li label-red" v-if="scope.row.is_enterprise == 1 && scope.row.enterprise">
							{{scope.row.enterprise.enterprise_name}}
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="mobile" label="手机号" min-width="150" align="center">
			</el-table-column>
			<el-table-column prop="base_avatar" label="头像" min-width="120" align="center">
				<template slot-scope="scope">
					<el-image v-show="scope.row.base_avatar" :src="scope.row.base_avatar" fit="cover"
						class="table-image">
					</el-image>
				</template>
			</el-table-column>
			<el-table-column prop="is_real_auth" label="实名认证" min-width="120" align="center">
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.is_real_auth==1?'success':'danger'">
						{{scope.row.is_real_auth_text}}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="is_enterprise" label="企业认证" min-width="120" align="center">
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.is_enterprise==1?'success':'danger'">
						{{scope.row.is_enterprise_text}}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="is_debt_payment" label="欠款权限" min-width="120" align="center">
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.is_debt_payment==1?'success':'danger'">
						{{scope.row.is_debt_payment_text}}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="状态" min-width="120" align="center">
				<template slot-scope="scope">
					<el-switch :active-value="'1'" :inactive-value="'0'" v-model="scope.row.status"
						@change="multiChange('status', scope.row)">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column prop="create_time" label="注册时间" min-width="180" align="center">
			</el-table-column>
			<el-table-column label="操作" min-width="150" align="center" fixed="right">
				<template slot-scope="scope">
					<el-button type="primary" icon="el-icon-edit" size="mini" @click="$emit('edit', scope.row)">
					</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="$emit('delete', scope.row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<pagination ref="pagination" :page="page.page" :pageSize="page.pageSize" :total="total"
			@pagination="pagination" />
	</div>
</template>

<script>
	import {
		multiChange
	} from "@/api/user.js";
	export default {
		name: "table-list",
		data() {
			return {
				selectIds: [], // 已选择的id
				page: {
					page: 1,
					pageSize: 10,
				},
			};
		},
		// 定义传入的参数
		props: {
			loading: {
				required: true,
				type: Boolean,
				default: false,
			},
			list: {
				required: true,
				type: Array,
			},
			total: {
				required: true,
				type: Number,
			},
			tableHeight: {
				required: true,
				type: Number,
			},
		},
		mounted() {},
		methods: {
			/**
			 * @desc 列表选择
			 */
			selectionChange(e) {
				this.selectIds = [];
				if (e.length != 0) {
					// 获取选中的id
					e.forEach((item, index) => {
						this.selectIds.push(item.id);
					});
				}
				this.$emit("selectionChange", this.selectIds);
			},
			/**
			 * @desc 状态切换
			 */
			multiChange(name, rows) {
				var params = {
					id: rows.id,
					[name]: rows[name],
				};
				multiChange(params)
					.then((res) => {
						this.$successMsg(res.msg);
					})
					.catch((ex) => {
						// 切换失败，重置信息
						rows.status = rows.status == 1 ? "0" : "1";
					});
			},
			/**
			 * @desc 分页
			 */
			pagination(e) {
				this.params = e;
				this.$emit("pagination", e);
			},
			/**
			 * @desc 重置刷新列表分页数据
			 */
			refreshPagination() {
				this.$refs.pagination.currentPage = 1;
			},
		},
	};
</script>

<style lang="scss">
	.table-tag {
		margin: 5px 5px 0 0;
	}
</style>
