export default { // 时间选择器配置信息
	startPlaceholder: '请选择开始时间', // 开始时间背景字
	endPlaceholder: '请选择结束时间', // 结束时间背景字
	valueFormat: "yyyy-MM-dd HH:mm:ss", // 时间格式化
	timeType: 'datetimerange', // 时间选择类型
	pickerOptions: {
		shortcuts: [{
			text: '最近一周',
			onClick(picker) {
				const end = new Date();
				const start = new Date();
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				picker.$emit('pick', [start, end]);
			}
		}, {
			text: '最近一个月',
			onClick(picker) {
				const end = new Date();
				const start = new Date();
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				picker.$emit('pick', [start, end]);
			}
		}, {
			text: '最近三个月',
			onClick(picker) {
				const end = new Date();
				const start = new Date();
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				picker.$emit('pick', [start, end]);
			}
		}]
	},
}
