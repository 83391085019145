import service from "@/utils/service.js";

// 售后订单列表
export function list(params) {
	return service({
		method: 'get',
		url: 'orderRefund/index',
		params: params
	})
}

// 角色列表
export function logisticsCompanyList(params) {
	return service({
		method: 'get',
		url: 'orderRefund/logisticsCompanyList',
		params: params
	})
}

// 售后订单详情
export function getExamine(params) {
	return service({
		method: 'get',
		url: 'orderRefund/examine',
		params: params
	})
}


// 售后订单删除
export function examine(data) {
	return service({
		method: 'post',
		url: 'orderRefund/examine',
		data
	})
}


// 售后订单删除
export function receipt(data) {
	return service({
		method: 'post',
		url: 'orderRefund/receipt',
		data
	})
}


// 售后订单删除
export function refund(data) {
	return service({
		method: 'post',
		url: 'orderRefund/refund',
		data
	})
}

// 售后订单详情
export function getDetail(params) {
	return service({
		method: 'get',
		url: 'orderRefund/detail',
		params: params
	})
}


// 售后订单删除
export function del(data) {
	return service({
		method: 'post',
		url: 'orderRefund/del',
		data
	})
}
